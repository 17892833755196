import moment from "moment";
import { AuthenticationService } from "../../services/AuthenticationService";
import { UserService } from "../../services/UserService";
import ApsModels from "../models";
import { Departments } from "../../data/Department";
import { RouteComponentProps } from "react-router";

export type SystemTypes =
  | "System.String"
  | "System.Decimal"
  | "System.Int32"
  | "System.DateTime"
  | "System.Boolean";

class CommonService {
  devRef = "DevRef P23172-155";

  private _currentDepartment: number | null = null;
  constructor() {
    UserService.currentDepartment.subscribe((id) => {
      this._currentDepartment = id;
    });
  }

  validations = {
    numberOnly: (val: string) => {
      if (val) {
        return /^\d+$/.test(`${val}`);
      }
      return true;
    },
    twoDecimalsOnly: (val: string) => {
      if (val) {
        return /^\d+\.?\d{0,2}$/.test(`${val}`);
      }
      return true;
    },
    oneDecimalsOnly: (val: string) => {
      if (val) {
        return /^\d+\.?\d{0,1}$/.test(`${val}`);
      }
      return true;
    },
    lettersDashAndSpace: (val: string) => {
      if (val) {
        return /^[a-zA-Z\s\-]*$/.test(`${val}`);
      }
      return true;
    },
    lettersOnly: (val: string) => {
      if (val) {
        return /^[a-zA-Z]*$/.test(`${val}`);
      }
      return true;
    },
    acceptedYearRange: (val: any) => {
      if (val) {
        return Number(val) >= 1900 && Number(val) <= 2200
          ? null
          : "Invalid Year";
      }
      return true;
    },
  };

  validateEmailCSV = (val: string) => {
    const emails = val.split(",");
    const validEmails: string[] = [];
    let emailErr = "";
    emails.forEach((email) => {
      if (emailErr) return;
      const x = email.trim().toLowerCase();
      if (
        !commonService.isNullOrWhitespace(x) &&
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(x) === false
      ) {
        emailErr = `Invalid Email Format: ${x}`;
      } else {
        if (validEmails.includes(x)) {
          emailErr = `Duplicate Email: ${x}`;
        } else {
          validEmails.push(x);
        }
      }
    });
    return emailErr || true;
  };

  validateNumbersOnlyCSV = (val: string) => {
    const numbers = val.split(",");
    const validNumbers: string[] = [];
    let emailErr = "";
    numbers.forEach((email) => {
      if (emailErr) return;
      const x = email.trim().toLowerCase();
      if (!commonService.isNullOrWhitespace(x) && isNaN(x as any)) {
        emailErr = `Invalid Number: ${x}`;
      } else {
        if (validNumbers.includes(x)) {
          emailErr = `Duplicate Number: ${x}`;
        } else {
          validNumbers.push(x);
        }
      }
    });
    return emailErr || true;
  };

  forms = {
    datePicker: {
      displayName: "Date",
      validation: {
        validate: {
          date: (val: boolean) => !val,
        },
      },
    },
  };

  // getNumericValue = (val: any) => {
  //   if (val === null || val === undefined) return undefined;
  //   if (`${val || ""}`.trim() === "") return undefined;
  //   return isNaN(val) ? undefined : (Number(val) as any);
  // };

  sortByName = (a: { name: string }, b: { name: string }) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  };

  sortByStringProperty = (propertyName: string) => {
    return (a: any, b: any) => {
      return a[propertyName]
        .toLowerCase()
        .localeCompare(b[propertyName].toLowerCase());
    };
  };

  sortByNumericProperty = (propertyName: string) => {
    return (a: any, b: any) => {
      return a[propertyName] - b[propertyName];
    };
  };

  parseErrorMessage(data: ApsModels.IHttpErrorOutputDto) {
    if (typeof data === "string") {
      return data;
    }
    let msg = "";
    if (data?.errors?.length > 0) {
      msg = data.errors.map((e) => e.message).join("<br/>");
    } else if ((data as any)?.Errors?.length > 0) {
      msg = (data as any).Errors.map((e: any) => e.Message)
        .filter((m: string) => (m || "").trim() !== "")
        .join("<br/>");
    } else {
      msg = JSON.stringify(data);
    }
    return msg;
  }

  parse413Error = (data: ApsModels.IHttpErrorOutputDto) => {
    let rtn = {
      email: "",
      limit: 0,
    };
    if (data.errors) {
      data.errors?.forEach((d) => {
        if (d.fieldName === "NoOfRecords") {
          rtn.limit = Number(d.message);
        } else if (d.fieldName === "Email") {
          rtn.email = d.message;
        }
      });
    } else {
      ((data as any).Errors || [])?.forEach((d: any) => {
        if (d.FieldName === "NoOfRecords") {
          rtn.limit = Number(d.Message);
        } else if (d.FieldName === "Email") {
          rtn.email = d.Message;
        }
      });
    }
    return rtn;
  };

  getErrorStatusCode = (data: ApsModels.IHttpErrorOutputDto) => {
    if (data.statusCode) {
      return data.statusCode;
    }
    return Number((data as any)["StatusCode"]);
  };

  getConcurrencyTitle(errorCode?: number) {
    return errorCode === 404 ? "Record Not Found" : "Update Conflict";
  }

  getConcurrencyMessage(errorCode?: number, forDelete = false) {
    if (errorCode === 404) {
      return `The data you're trying to ${forDelete ? "delete" : "update"} 
      has been deleted by another person.`;
    }
    return `The data you're trying to ${forDelete ? "delete" : "update"} 
    has been updated by another person. The changes made will now be displayed. <br/>
    Please review and make your changes again if appropriate.`;
  }

  get userInfo() {
    const tmpUser = AuthenticationService?.isAuthenticatedObservable?.get();
    const user = {
      ...tmpUser,
      ...(tmpUser as any as ApsModels.IUserSecurityOutputDto),
    };
    return user;
  }

  get currentDepartment() {
    return this._currentDepartment || UserService?.currentDepartment?.get();
  }

  get friendlyClaims() {
    let cur = {
      //APS Admin can do everything and will override claims
      //LOG DRILLS Claims
      isAPSAdmin: this.userInfo?.isAdmin || false,
      canSubmitOwn: this.userInfo?.isAdmin || false,
      canSubmitAll: this.userInfo?.isAdmin || false,
      canReadOwn: this.userInfo?.isAdmin || false,
      canReadAll: this.userInfo?.isAdmin || false,
      canEditOwn: this.userInfo?.isAdmin || false,
      canEditAll: this.userInfo?.isAdmin || false,
      hasMissingTokenCliam: false,
      canExportAll: this.userInfo?.isAdmin || false,
      canExportOwn: this.userInfo?.isAdmin || false,
      canDeleteOwn: this.userInfo?.isAdmin || false,
      canDeleteAll: this.userInfo?.isAdmin || false,
      //NFIRS Claims
      nfirs: {
        canUnlockAll: this.userInfo?.isAdmin || false,
        canSubmitWithMissingRequired: this.userInfo?.isAdmin || false,
        canChangeAllComments: this.userInfo?.isAdmin || false,
        canSearch: this.userInfo?.isAdmin || false,
        canDelete: this.userInfo?.isAdmin || false,
        allowSelect1kRows: this.userInfo?.isAdmin || false,
      },
      course: {
        canFilter: this.userInfo?.isAdmin || false,
        canAddAll: this.userInfo?.isAdmin || false,
        canViewAll: this.userInfo?.isAdmin || false,
        canAccess: this.userInfo?.isAdmin || false,
      },
      credentials: {
        canFilter: this.userInfo?.isAdmin || false,
        canAddAll: this.userInfo?.isAdmin || false,
        canViewAll: this.userInfo?.isAdmin || false,
        canAccess: this.userInfo?.isAdmin || false,
        canEditOwn: this.userInfo?.isAdmin || false,
      },
      dashboard: {
        canAccess: this.userInfo?.isAdmin || false,
        canViewOthers: this.userInfo?.isAdmin || false,
      },
      esriDashboard: {
        canView: this.userInfo?.isAdmin || false,
      },
      templates: {
        canAccess:
          this.userInfo?.isAdmin || this.userInfo?.isAccountAdmin || false,
      },
    };

    if (cur.isAPSAdmin) {
      return cur;
    }

    if (this.userInfo?.userClaims?.length > 0) {
      this.userInfo.userClaims.filter((c) => {
        //Check if InToken
        if (!cur.hasMissingTokenCliam && !(c as any).inToken) {
          cur.hasMissingTokenCliam = true;
        }

        if (c.claimValue === ApsModels.UserClaimValueEnum.EsriDashboardView) {
          cur.esriDashboard.canView = true;
        }

        //TEMPLATES
        if (c.claimValue === ApsModels.UserClaimValueEnum.CredTemplateAdmin) {
          cur.templates.canAccess = true;
          return;
        }

        //NFIRS
        if (c.claimValue === ApsModels.UserClaimValueEnum.NfirsAdmin) {
          cur.nfirs.canUnlockAll = true;
          cur.nfirs.canSubmitWithMissingRequired = true;
          cur.nfirs.canChangeAllComments = true;
          cur.nfirs.canSearch = true;
          cur.nfirs.canDelete = true;
          cur.nfirs.allowSelect1kRows = true;
          return;
        }

        //COURSE
        if (
          c.claimValue === ApsModels.UserClaimValueEnum.CredCourseRecordsAdmin
        ) {
          cur.course.canFilter = true;
          cur.course.canAddAll = true;
          cur.course.canViewAll = true;
          cur.course.canAccess = true;
          return;
        }
        if (
          c.claimValue === ApsModels.UserClaimValueEnum.CredCourseRecordsManager
        ) {
          cur.course.canFilter = true;
          cur.course.canViewAll = true;
          cur.course.canAccess = true;
          return;
        }
        if (
          c.claimValue === ApsModels.UserClaimValueEnum.CredCourseRecordsOwn
        ) {
          cur.course.canAccess = true;
          return;
        }
        //CREDENTIALS
        if (
          c.claimValue === ApsModels.UserClaimValueEnum.CredCredentialsAdmin
        ) {
          cur.credentials.canFilter = true;
          cur.credentials.canAddAll = true;
          cur.credentials.canViewAll = true;
          cur.credentials.canAccess = true;
          cur.credentials.canEditOwn = true;
          return;
        }
        if (
          c.claimValue === ApsModels.UserClaimValueEnum.CredCredentialsManager
        ) {
          cur.credentials.canFilter = true;
          cur.credentials.canViewAll = true;
          cur.credentials.canAccess = true;
          cur.credentials.canEditOwn = true;
          return;
        }
        if (c.claimValue === ApsModels.UserClaimValueEnum.CredCredentialsOwn) {
          cur.credentials.canAccess = true;
          cur.credentials.canEditOwn = true;
          return;
        }
        if (
          c.claimValue === ApsModels.UserClaimValueEnum.CredCredentialsViewOwn
        ) {
          cur.credentials.canAccess = true;
          return;
        }
        //DASHBOARD
        if (
          c.claimValue === ApsModels.UserClaimValueEnum.CredDashboardManager
        ) {
          cur.dashboard.canAccess = true;
          cur.dashboard.canViewOthers = true;
          return;
        }
        if (c.claimValue === ApsModels.UserClaimValueEnum.CredDashboardOwn) {
          cur.dashboard.canAccess = true;
          return;
        }

        //SUBMIT
        if (
          c.claimValue === ApsModels.UserClaimValueEnum.Staff1 ||
          c.claimValue === ApsModels.UserClaimValueEnum.LogDrillsManager ||
          c.claimValue === ApsModels.UserClaimValueEnum.Reporter1 ||
          c.claimValue === ApsModels.UserClaimValueEnum.Reporter2
        ) {
          cur.canSubmitOwn = true;
          cur.canSubmitAll = true;
        }
        if (
          c.claimValue === ApsModels.UserClaimValueEnum.Self1 ||
          c.claimValue === ApsModels.UserClaimValueEnum.Self2
        ) {
          cur.canSubmitOwn = true;
        }
        //EDIT
        if (c.claimValue === ApsModels.UserClaimValueEnum.Staff1) {
          cur.canEditOwn = true;
          cur.canEditAll = true;
        }
        if (
          c.claimValue === ApsModels.UserClaimValueEnum.LogDrillsManager ||
          c.claimValue === ApsModels.UserClaimValueEnum.Reporter1 ||
          c.claimValue === ApsModels.UserClaimValueEnum.Reporter2 ||
          c.claimValue === ApsModels.UserClaimValueEnum.Self1 ||
          c.claimValue === ApsModels.UserClaimValueEnum.Self2
        ) {
          cur.canEditOwn = true;
        }
        //READ
        if (
          c.claimValue === ApsModels.UserClaimValueEnum.Staff1 ||
          c.claimValue === ApsModels.UserClaimValueEnum.Staff2 ||
          c.claimValue === ApsModels.UserClaimValueEnum.LogDrillsManager ||
          c.claimValue === ApsModels.UserClaimValueEnum.Reporter1 ||
          c.claimValue === ApsModels.UserClaimValueEnum.Self1
        ) {
          cur.canReadOwn = true;
          cur.canReadAll = true;
        }
        if (
          c.claimValue === ApsModels.UserClaimValueEnum.Reporter2 ||
          c.claimValue === ApsModels.UserClaimValueEnum.Self2 ||
          c.claimValue === ApsModels.UserClaimValueEnum.Self3
        ) {
          cur.canReadOwn = true;
        }
        //EXPORT
        if (
          c.claimValue === ApsModels.UserClaimValueEnum.Staff1 ||
          c.claimValue === ApsModels.UserClaimValueEnum.Staff2 ||
          c.claimValue === ApsModels.UserClaimValueEnum.LogDrillsManager
        ) {
          cur.canExportAll = true;
          cur.canExportOwn = true;
        }
        if (
          c.claimValue === ApsModels.UserClaimValueEnum.Reporter1 ||
          c.claimValue === ApsModels.UserClaimValueEnum.Reporter2 ||
          c.claimValue === ApsModels.UserClaimValueEnum.Self1 ||
          c.claimValue === ApsModels.UserClaimValueEnum.Self2 ||
          c.claimValue === ApsModels.UserClaimValueEnum.Self3
        ) {
          cur.canExportOwn = true;
        }
        //DELETE
        if (
          c.claimValue === ApsModels.UserClaimValueEnum.Staff1 ||
          c.claimValue === ApsModels.UserClaimValueEnum.LogDrillsManager
        ) {
          cur.canDeleteAll = true;
          cur.canDeleteOwn = true;
        }
        if (
          c.claimValue === ApsModels.UserClaimValueEnum.Reporter1 ||
          c.claimValue === ApsModels.UserClaimValueEnum.Reporter2 ||
          c.claimValue === ApsModels.UserClaimValueEnum.Self1 ||
          c.claimValue === ApsModels.UserClaimValueEnum.Self2
        ) {
          cur.canDeleteOwn = true;
        }
      });
    }
    return cur;
  }

  b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  deepCloneJsonObject = <T>(jsonObject: T) => {
    return { ...JSON.parse(JSON.stringify(jsonObject)) } as T;
  };

  deepCloneJsonArray = <T>(jsonObject: T) => {
    return JSON.parse(JSON.stringify(jsonObject)) as T;
  };

  replaceEmptyString = (someObj: any, replaceValue: any) => {
    const replacer = (key: any, value: any) =>
      value === "" ? replaceValue : value;
    return JSON.parse(JSON.stringify(someObj, replacer));
  };

  canAccessDashboard() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      return (
        this.userInfo.userClaims.filter(
          (c) =>
            c.claimType === ApsModels.UserClaimTypeEnum.Dashboard &&
            c.claimValue !== ApsModels.UserClaimValueEnum.Remove
        ).length > 0
      );
    }
    return false;
  }

  canViewOwnCETrackers() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      const userClaims = this.userInfo.userClaims.filter(
        (c) =>
          c.claimType === ApsModels.UserClaimTypeEnum.Dashboard &&
          c.claimValue !== ApsModels.UserClaimValueEnum.Remove
      );
      return (
        userClaims.length > 0 &&
        userClaims[0].userClaimOptions.findIndex(
          (s) =>
            s.userClaimValueOptionId ==
              ApsModels.UserClaimValueOptionEnum
                .CETrackersCredDashboardManager ||
            s.userClaimValueOptionId ==
              ApsModels.UserClaimValueOptionEnum.CETrackersCredDashboardOwn
        ) != -1
      );
    }
    return false;
  }

  canViewAllCETrackers() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      const userClaims = this.userInfo.userClaims.filter(
        (c) =>
          c.claimType === ApsModels.UserClaimTypeEnum.Dashboard &&
          c.claimValue !== ApsModels.UserClaimValueEnum.Remove
      );
      return (
        userClaims.length > 0 &&
        userClaims[0].userClaimOptions.findIndex(
          (s) =>
            s.userClaimValueOptionId ==
            ApsModels.UserClaimValueOptionEnum.CETrackersCredDashboardManager
        ) != -1
      );
    }
    return false;
  }

  canViewOwnISOTrackers() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      const userClaims = this.userInfo.userClaims.filter(
        (c) =>
          c.claimType === ApsModels.UserClaimTypeEnum.Dashboard &&
          c.claimValue !== ApsModels.UserClaimValueEnum.Remove
      );
      return (
        userClaims.length > 0 &&
        userClaims[0].userClaimOptions.findIndex(
          (s) =>
            s.userClaimValueOptionId ==
              ApsModels.UserClaimValueOptionEnum
                .ISOTrackerCredDashboardManager ||
            s.userClaimValueOptionId ==
              ApsModels.UserClaimValueOptionEnum.ISOTrackerCredDashboardOwn
        ) != -1
      );
    }
    return false;
  }

  canViewAllISOTrackers() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      const userClaims = this.userInfo.userClaims.filter(
        (c) =>
          c.claimType === ApsModels.UserClaimTypeEnum.Dashboard &&
          c.claimValue !== ApsModels.UserClaimValueEnum.Remove
      );
      return (
        userClaims.length > 0 &&
        userClaims[0].userClaimOptions.findIndex(
          (s) =>
            s.userClaimValueOptionId ==
            ApsModels.UserClaimValueOptionEnum.ISOTrackerCredDashboardManager
        ) != -1
      );
    }
    return false;
  }

  canAccessLogDrills() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      return (
        this.userInfo.userClaims.filter(
          (c) =>
            c.claimType === ApsModels.UserClaimTypeEnum.LogDrills &&
            c.claimValue !== ApsModels.UserClaimValueEnum.Remove
        ).length > 0
      );
    }
    return false;
  }

  canAccessCourseRecords() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      return (
        this.userInfo.userClaims.filter(
          (c) =>
            c.claimType === ApsModels.UserClaimTypeEnum.CourseRecords &&
            c.claimValue !== ApsModels.UserClaimValueEnum.Remove
        ).length > 0
      );
    }
    return false;
  }

  canAccessCredentials() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      return (
        this.userInfo.userClaims.filter(
          (c) =>
            c.claimType === ApsModels.UserClaimTypeEnum.Credentials &&
            c.claimValue !== ApsModels.UserClaimValueEnum.Remove
        ).length > 0
      );
    }
    return false;
  }

  canAccessNfirs() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      return (
        this.userInfo.userClaims.filter(
          (c) =>
            c.claimType === ApsModels.UserClaimTypeEnum.Nfirs &&
            c.claimValue !== ApsModels.UserClaimValueEnum.Remove
        ).length > 0
      );
    }
    return false;
  }

  canAccessTrainingPortal() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      return (
        this.userInfo.userClaims.filter(
          (c) =>
            c.claimType === ApsModels.UserClaimTypeEnum.TrainingPortal &&
            c.claimValue !== ApsModels.UserClaimValueEnum.Remove
        ).length > 0
      );
    }
    return false;
  }

  canAccessPersonnel() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      return (
        this.userInfo.userClaims.filter(
          (c) =>
            c.claimType === ApsModels.UserClaimTypeEnum.Personnel &&
            c.claimValue !== ApsModels.UserClaimValueEnum.Remove
        ).length > 0
      );
    }
    return false;
  }

  canAccessGroups() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      return (
        this.userInfo.userClaims.filter(
          (c) =>
            c.claimType === ApsModels.UserClaimTypeEnum.Groups &&
            c.claimValue === ApsModels.UserClaimValueEnum.GroupAdmin
        ).length > 0
      );
    }
    return false;
  }

  canAccessTemplates() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      return (
        this.userInfo.userClaims.filter(
          (c) =>
            c.claimType === ApsModels.UserClaimTypeEnum.CredTemplates &&
            c.claimValue === ApsModels.UserClaimValueEnum.CredTemplateAdmin
        ).length > 0
      );
    }
    return false;
  }

  canAccessDataVisualization() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      return (
        this.userInfo.userClaims.filter(
          (c) =>
            c.claimType === ApsModels.UserClaimTypeEnum.DataVisualization &&
            c.claimValue !== ApsModels.UserClaimValueEnum.Remove
        ).length > 0
      );
    }
    return false;
  }

  canAccessIsoTraining() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      return (
        this.userInfo.userClaims.filter(
          (c) =>
            c.claimType === ApsModels.UserClaimTypeEnum.IsoTraining &&
            c.claimValue !== ApsModels.UserClaimValueEnum.Remove
        ).length > 0
      );
    }
    return false;
  }

  canViewISODetailReport() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      const userClaims = this.userInfo.userClaims.filter(
        (c) =>
          c.claimType === ApsModels.UserClaimTypeEnum.IsoTraining &&
          c.claimValue !== ApsModels.UserClaimValueEnum.Remove
      );
      return (
        userClaims.length > 0 &&
        userClaims[0].userClaimOptions.findIndex(
          (s) =>
            s.userClaimValueOptionId ==
            ApsModels.UserClaimValueOptionEnum.DetailReportIsoTrainingView
        ) != -1
      );
    }
    return false;
  }

  canViewISOOverviewReport() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      const userClaims = this.userInfo.userClaims.filter(
        (c) =>
          c.claimType === ApsModels.UserClaimTypeEnum.IsoTraining &&
          c.claimValue !== ApsModels.UserClaimValueEnum.Remove
      );
      return (
        userClaims.length > 0 &&
        userClaims[0].userClaimOptions.findIndex(
          (s) =>
            s.userClaimValueOptionId ==
            ApsModels.UserClaimValueOptionEnum.OverwiewReportIsoTrainingView
        ) != -1
      );
    }
    return false;
  }

  canViewNfirsDyanamicReport() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      const userClaims = this.userInfo.userClaims.filter(
        (c) =>
          c.claimType === ApsModels.UserClaimTypeEnum.IsoTraining &&
          c.claimValue !== ApsModels.UserClaimValueEnum.Remove
      );
      return (
        userClaims.length > 0 &&
        userClaims[0].userClaimOptions.findIndex(
          (s) =>
            s.userClaimValueOptionId ==
            ApsModels.UserClaimValueOptionEnum.DisplayNfirsReport
        ) != -1
      );
    }
    return false;
  }

  canAccessCustomIFrame() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      return (
        this.userInfo.userClaims.filter(
          (c) =>
            c.claimType === ApsModels.UserClaimTypeEnum.CustomIFrame &&
            c.claimValue !== ApsModels.UserClaimValueEnum.Remove
        ).length > 0
      );
    }
    return false;
  }

  canAccessAssignments() {
    if (this.userInfo?.isAdmin) {
      return true;
    }
    if (this.userInfo?.userClaims?.length > 0) {
      return (
        this.userInfo.userClaims.filter(
          (c) =>
            c.claimType === ApsModels.UserClaimTypeEnum.Assignments &&
            c.claimValue !== ApsModels.UserClaimValueEnum.Remove
        ).length > 0
      );
    }
    return false;
  }

  isEqual(model1: any, model2: any, considerNullEmptyEqual = false) {
    let cm1 = this.deepCloneJsonObject(model1);
    let cm2 = this.deepCloneJsonObject(model2);
    if (considerNullEmptyEqual) {
      for (let p in cm1) {
        if (this.isNullOrEmpty(cm1[p])) {
          delete cm1[p];
        }
      }
      for (let p in cm2) {
        if (this.isNullOrEmpty(cm2[p])) {
          delete cm2[p];
        }
      }
    }
    const mdl = JSON.stringify(cm1);
    const oMdl = JSON.stringify(cm2);
    return mdl === oMdl;
  }

  isNullOrEmpty(val: string) {
    return val === null || val === undefined || val === "";
  }

  isNullOrWhitespace(val: string) {
    return val === null || val === undefined || `${val || ""}`.trim() === "";
  }

  getUniqueId = () => {
    return (
      moment().format("YYYYMMDD-HH-mm-ss-ms-") +
      `${(Math.random() * 123456).toFixed(0)}`
    );
  };

  getValidBoolValue(val: string) {
    if (
      `${val}`.trim().toLowerCase() === "yes" ||
      `${val}`.trim().toLowerCase() === "true"
    ) {
      return "true";
    }
    if (
      `${val}`.trim().toLowerCase() === "no" ||
      `${val}`.trim().toLowerCase() === "false"
    ) {
      return "false";
    }
    return "";
  }

  isValidValue(type: SystemTypes, val: any) {
    if (
      val === null ||
      val === undefined ||
      (typeof val === "string" && val.trim() === "")
    ) {
      return true;
    }
    if (type === "System.Decimal" || type === "System.Int32") {
      return !isNaN(val);
    }
    if (type === "System.Boolean") {
      return this.getValidBoolValue(val) !== "";
    }
    if (type === "System.DateTime") {
      return moment(`${val || ""}`.trim(), "MM/DD/YYYY", true).isValid();
    }
    return true;
  }

  getNumber(val: any) {
    if (val === null || val === undefined || `${val}`.trim() === "") {
      return null;
    }
    return Number(val) === 0 ? 0 : Number(val) || (null as any);
  }

  toMoney(val: any, currency?: string) {
    return (
      `${currency || "$"}` +
      Number(Number(val || 0).toFixed(2)).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }

  toNumberWithComma(val: any, decimalPlaces?: number) {
    return Number(Number(val || 0).toFixed(2)).toLocaleString(undefined, {
      maximumFractionDigits: decimalPlaces === undefined ? 2 : decimalPlaces,
      ...(decimalPlaces
        ? {
            minimumFractionDigits: decimalPlaces,
          }
        : {}),
    });
  }

  checkDateValue(date: any) {
    if (!date) return null;
    if (`${date || ""}`.trim().toLowerCase() === "invalid date") return null;
    return date;
  }

  /**
   * Returns Date String with timezone, format as YYYY-MM-DDT:00:00:00Z
   * @param date
   * @returns
   */
  toStartOfDayDateString(date: any) {
    return moment(date).startOf("day").format();
  }

  getUserShortName(fullname: string) {
    if (!fullname) return "";
    var components = fullname.split(" ");
    var firstName = components[0];
    var lastName = components.length > 1 ? components.slice(1).join(" ") : "";
    return `${lastName}, ${firstName.substring(0, 1)}.`;
  }

  CSVToArray(strData: string, strDelimiter?: string) {
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    strDelimiter = strDelimiter || ",";

    // Create a regular expression to parse the CSV values.
    var objPattern = new RegExp(
      // Delimiters.
      "(\\" +
        strDelimiter +
        "|\\r?\\n|\\r|^)" +
        // Quoted fields.
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        // Standard fields.
        '([^"\\' +
        strDelimiter +
        "\\r\\n]*))",
      "gi"
    );

    // Create an array to hold our data. Give the array
    // a default empty first row.
    var arrData: any[] = [[]];

    // Create an array to hold our individual pattern
    // matching groups.
    var arrMatches = null;

    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while ((arrMatches = objPattern.exec(strData))) {
      // Get the delimiter that was found.
      var strMatchedDelimiter = arrMatches[1];

      // Check to see if the given delimiter has a length
      // (is not the start of string) and if it matches
      // field delimiter. If id does not, then we know
      // that this delimiter is a row delimiter.
      if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
        // Since we have reached a new row of data,
        // add an empty row to our data array.
        arrData.push([]);
      }

      var strMatchedValue: any;

      // Now that we have our delimiter out of the way,
      // let's check to see which kind of value we
      // captured (quoted or unquoted).
      if (arrMatches[2]) {
        // We found a quoted value. When we capture
        // this value, unescape any double quotes.
        strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
      } else {
        // We found a non-quoted value.
        strMatchedValue = arrMatches[3];
      }

      // Now that we have our value string, let's add
      // it to the data array.
      arrData[arrData.length - 1].push(strMatchedValue);
    }

    // Return the parsed data.
    return arrData;
  }

  camelize(str: string) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  toCamel(o: any) {
    const dis = this;
    var newO, origKey, newKey, value;
    if (o instanceof Array) {
      return o.map(function (value) {
        if (typeof value === "object") {
          value = dis.toCamel(value);
        }
        return value;
      });
    } else {
      newO = {} as any;
      for (origKey in o) {
        if (o.hasOwnProperty(origKey)) {
          newKey = dis.camelize(origKey);
          value = o[origKey];
          if (
            value instanceof Array ||
            (value !== null && value.constructor === Object)
          ) {
            value = dis.toCamel(value);
          }
          newO[newKey] = value;
        }
      }
    }
    return newO;
  }

  shouldUseNewDOC(depId: number) {
    if (
      [
        Departments.Glendale,
        Departments.Avalon,
        //Departments.Monrovia, //CSM-390
        Departments.MontereyPark,
        Departments.WestCovina,
      ].includes(depId)
    ) {
      return false;
    }
    return true;
  }

  routeGoBack(props: RouteComponentProps<any>, doneUrl?: string) {
    props.history.goBack();
    setTimeout(() => {
      props.history.replace(props.history.location.pathname + (doneUrl || ""));
    }, 100);
  }

  doneUrls = {
    created: "?created",
    deleted: "?deleted",
    assigned: "?assigned",
    submitted: "?submitted",
  };
}

const commonService = new CommonService();
export default commonService;
