import React, { useEffect, useState } from "react";
import { IDrillReportProps } from ".";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import systemStore from "../../../stores/SystemStore";
import CheckboxList from "../../Common/CheckboxList";
import FormError from "../../Common/FormError";
import { FgSelect, IChildCardProps } from "../../Common/FormGroups";
import { FgInput } from "../../Common/FormGroups/FgInput";
import "react-day-picker/lib/style.css";
import moment from "moment";
import DateTimePicker from "../../Common/DateTimePicker";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import commonService from "../../../services/CommonService";
import AdvancedDropdown from "../../Common/AdvancedDropdown";
import { useNfirsGetData } from "../../NFIRS/NFIRSHelper";

interface IOption {
  id: number;
  firstName: string;
  lastName: string;
  name: string;
}

function DrillDetail(props: IChildCardProps<IDrillReportProps>) {
  const template = (props?.data?.template ||
    {}) as ApsModels.IDrillTemplateOutputDto;

  let drills = template.drills || [];
  let locations = template.locations || [];
  let trainingTypes = template.trainingTypes || [];

  drills.sort(ApsServices.common.sortByName);
  locations.sort(ApsServices.common.sortByName);
  trainingTypes.sort(ApsServices.common.sortByName);

  const toggleTrainingTypes = () => {
    trainingTypes.forEach((trn) => {
      trn.isEnabled = false;
      props?.data?.report?.selectedTrainingTypeIds?.forEach((id) => {
        if (trn.id === id) {
          trn.isEnabled = true;
        }
      });
    });
  };
  toggleTrainingTypes();

  const setValue = (name: string, val: any) => {
    if (props.setValue) {
      (props.setValue as any)(name, val);
    }
  };

  const getExtraInfo = async (id?: number) => {
    let hasMatch = false;
    if (id) {
      template?.drills?.forEach((data) => {
        if (data.id === id) {
          hasMatch = true;
          setValue("isaCourseId", data.isaCourseId);
          setValue("calJacTrainingTypeId", data.calJacTrainingTypeId);
          setValue("logDrillAreaOfStudyId", data.logDrillAreaOfStudyId);
          setValue("logDrillSubjectId", data.logDrillSubjectId);
          systemStore.setExtraDrillInfo(data);
        }
      });
    }
    if (!hasMatch) {
      setValue("isaCourseId", null);
      setValue("calJacTrainingTypeId", null);
      setValue("logDrillAreaOfStudyId", null);
      setValue("logDrillSubjectId", null);
      systemStore.setExtraDrillInfo(null as any);
    }
  };

  const [startDateTime, setStartDateTime] = useState(
    props?.data?.report?.startTime || undefined
  );

  const [endDateTime, setEndDateTime] = useState(
    props?.data?.report?.endTime || undefined
  );

  const getTotalHours = () => {
    if (startDateTime && endDateTime) {
      return moment(endDateTime).diff(startDateTime) / 60 / 60 / 1000;
    }
    return 0;
  };

  useEffect(() => {
    setValue("duration", getTotalHours());
  }, [startDateTime, endDateTime]);

  const [showOtherDrillName, setShowOtherDrillName] = useState(false);
  const [showOtherLocation, setShowOtherLocation] = useState(false);
  const [showOtherTrainingType, setShowOtherTrainingType] = useState(false);

  const toggleOtherItems = () => {
    let hasMatch = false;
    //DRILL NAME
    drills.forEach((item) => {
      if (
        props.getValues &&
        !hasMatch &&
        Number(item.id) === Number(props.getValues("drillId")) &&
        item.name.toLowerCase().trim() === "other"
      ) {
        hasMatch = true;
      }
    });
    setShowOtherDrillName(hasMatch);
    setValue("_requireOtherDrillName", hasMatch);

    hasMatch = false;
    //LOCATION
    locations.forEach((item) => {
      if (
        props.getValues &&
        !hasMatch &&
        Number(item.id) === Number(props.getValues("locationId")) &&
        item.name.toLowerCase().trim() === "other"
      ) {
        hasMatch = true;
      }
    });
    setShowOtherLocation(hasMatch);
    setValue("_requireOtherLocation", hasMatch);

    hasMatch = false;
    //TRAINING TYPE
    trainingTypes.forEach((item) => {
      if (
        props.getValues &&
        !hasMatch &&
        item.name.toLowerCase().trim() === "other"
      ) {
        const types: any[] = props.getValues("selectedTrainingTypeIds") || [];

        (typeof types === "string" ? [Number(types)] : types).forEach((t) => {
          if (!hasMatch && Number(item.id) === Number(t)) {
            hasMatch = true;
          }
        });
      }
    });
    setShowOtherTrainingType(hasMatch);
    setValue("_requireOtherTrainingType", hasMatch);
  };

  useEffect(() => {
    toggleOtherItems();
  }, []);

  const [users, setUsers] = useState([] as IOption[]);
  const [isLoading, setIsLoading] = useState(false);
  const [instructors, setInstructors] = useState<{ id: any; name: string }[]>(
    `${props?.data?.report?.instructor || ""}`.trim() === ""
      ? []
      : [
          {
            id: props?.data?.report?.instructorUserId || 0,
            name: props?.data?.report?.instructor || "",
          },
        ]
  );
  const ref = React.createRef<AsyncTypeahead<any>>();
  const handleSearch = async (query: string) => {
    if ((query || "").trim() === "") {
      setUsers([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    await ApsServices.http.drillReport
      .typeAheadUser({ search: query, isAnd: false, recordCount: 10 })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.firstName} ${i.lastName}`,
          firstName: i.firstName,
          lastName: i.lastName,
        }));

        setUsers([
          ...options,
          ///// This allows non-employees
          // {
          //   id: 0,
          //   name: query,
          //   firstName: query,
          //   lastName: "",
          // },
        ]);
        setIsLoading(false);
      })
      .catch((err) => {
        setUsers([]);
        setIsLoading(false);
      });
  };

  const refDrillName = React.createRef<AsyncTypeahead<any>>();
  const [openDrillDropdown, setOpenDrillDropdown] = useState(false);
  const [selectedDrills, setSelectedDrills] = useState<
    {
      name: string;
      id: number;
    }[]
  >([]);

  useEffect(() => {
    if (template.drills?.length && props.data?.report?.drillId) {
      const id = props.data?.report?.drillId;
      setSelectedDrills(
        template.drills
          .filter((d) => d.id === id)
          .map((d) => {
            return {
              id: d.id,
              name: d.name,
            };
          })
      );
    }
  }, [template]);

  const drilLNames = useNfirsGetData(
    async () => ApsServices.http.drillFavorite.getDrillNames(false),
    "Drill Names"
  );

  useEffect(() => {
    drilLNames.getData();
  }, []);

  return (
    <div className="card">
      <div className="card-body">
        <strong>{props?.data?.sectionNumber}. Drill Details</strong>

        {props?.data?.template?.isDrillEnabled && (
          <>
            <div className="mt-4"></div>

            <div>
              <div>
                <span className="required-label">*</span>
                <label>Drill Name</label>
              </div>
              <AdvancedDropdown
                domId="drillDetails"
                value={null}
                searchPlaceholder="Search Drill Name"
                favoritesHandler={{
                  add: ApsServices.http.drillFavorite.addFavoriteDrillName,
                  remove:
                    ApsServices.http.drillFavorite.removeFavoriteDrillName,
                  afterUpdate: (list: any[]) => {
                    drilLNames.setData(list);
                  },
                }}
                disabledOptionValues={[]}
                options={(drills || [])
                  .sort(commonService.sortByStringProperty("name"))
                  .map((o) => {
                    return {
                      label: `${o.name}`,
                      value: o.id,
                      originalObject: o,
                      isFavorite: !!drilLNames.data?.find(
                        (d) => d.id === o.id && d.isFavorite
                      ),
                      groupId: 0,
                      subGroupId: 0,
                    };
                  })}
                onChange={(id) => {
                  const matches = drills.filter((d) => d.id === id);
                  if (matches.length > 0) {
                    setSelectedDrills([...matches]);
                    props?.setValue && props.setValue("drillId", parseInt(id));
                    getExtraInfo(parseInt(id));
                    toggleOtherItems();
                    (refDrillName.current as any)?.clear();
                    refDrillName.current?.blur();
                  }
                }}
              ></AdvancedDropdown>
            </div>

            <FormError
              field={props.form.drillId.name}
              formState={props.formState}
            ></FormError>
            {/* <div className="mt-3"></div> */}
            <div className="currentParticipants mb-2">
              {selectedDrills
                ?.sort(commonService.sortByStringProperty("name"))
                ?.map((p, i) => {
                  return (
                    <div key={i} className="mt-2">
                      <section>{`${p.name}`}</section>
                      <section
                        title="Remove"
                        onClick={() => {
                          const prts = [...selectedDrills];
                          prts.splice(i, 1);
                          setSelectedDrills(prts);

                          props?.setValue && props.setValue("drillId", null);

                          getExtraInfo(undefined);
                          toggleOtherItems();
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </section>
                    </div>
                  );
                })}
            </div>
          </>
        )}

        {showOtherDrillName && (
          <div>
            <div className="mt-3"></div>
            <FgInput
              label="Other Drill Name"
              id={props.form.otherDrillName.name}
              formState={props.formState}
              registeredField={props.form.otherDrillName}
              readOnly={!props.data.allowEdit}
            />
          </div>
        )}

        {props?.data?.template?.isLocationEnabled && (
          <>
            <div className="mt-3"></div>
            <FgSelect
              id={props.form.locationId.name}
              label="Location"
              showRequiredLabel={true}
              formState={props.formState}
              registeredField={props.form.locationId}
              options={locations?.map((c) => {
                return {
                  label: c.name,
                  value: c.id,
                };
              })}
              onChange={toggleOtherItems}
              disabled={!props.data.allowEdit}
            />
          </>
        )}

        {
          <div>
            <div className="mt-2"></div>
            {/* <FgInput
              label="Instructor"
              id={props.form.instructor.name}
              formState={props.formState}
              registeredField={props.form.instructor}
              readOnly={!props.data.allowEdit}
            /> */}
            <div>
              <label>Instructor</label>
            </div>
            {props.data.allowEdit && (
              <div>
                <AsyncTypeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  onSearch={handleSearch}
                  onChange={(data) => {
                    if (data && data.length > 0) {
                      if (props?.setValue) {
                        props.setValue("instructorUserId", data[0].id);
                        props.setValue(
                          "instructor",
                          `${data[0].firstName} ${data[0].lastName}`.trim()
                        );
                      }
                      setInstructors([
                        {
                          id: data[0].id,
                          name: `${data[0].firstName} ${data[0].lastName}`.trim(),
                        },
                      ]);
                      (ref.current as any)?.clear();
                    }
                  }}
                  searchText={"Searching..."}
                  isLoading={isLoading}
                  options={users}
                  placeholder="Search..."
                  minLength={1}
                  delay={500}
                  useCache={false}
                  ref={ref}
                />
              </div>
            )}

            {instructors?.length > 0 && <div className="pt-3"></div>}

            <div className="currentParticipants">
              {instructors?.map((p, i) => {
                return (
                  <div key={i}>
                    <section>{`${p.name}`}</section>
                    <section
                      className={
                        props.data.allowEdit && props.data.allowAddOthers
                          ? ""
                          : "display-none"
                      }
                      title="Remove"
                      onClick={() => {
                        setInstructors([]);
                        if (props?.setValue) {
                          props.setValue("instructor", null as any);
                          props.setValue("instructorUserId", null as any);
                        }
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </section>
                  </div>
                );
              })}
            </div>
          </div>
        }

        {showOtherLocation && (
          <div>
            <div className="mt-2"></div>
            <FgInput
              label="Other Location"
              id={props.form.otherLocation.name}
              formState={props.formState}
              registeredField={props.form.otherLocation}
              readOnly={!props.data.allowEdit}
            />
          </div>
        )}

        {props?.data?.template?.isTrainingTypeEnabled &&
          !props?.data?.template?.isTrainingTypeMultiSelectEnabled && (
            <>
              <div className="mt-4"></div>
              <FgSelect
                id={props.form.selectedTrainingTypeIds.name}
                label="Training Type (ISO Codes)"
                showRequiredLabel={true}
                formState={props.formState}
                registeredField={props.form.selectedTrainingTypeIds}
                options={trainingTypes?.map((c) => {
                  return {
                    label: c.name,
                    value: c.id,
                  };
                })}
                onChange={toggleOtherItems}
                disabled={!props.data.allowEdit}
              />
            </>
          )}

        {props?.data?.template?.isTrainingTypeEnabled &&
          props?.data?.template?.isTrainingTypeMultiSelectEnabled && (
            <>
              <div className="mt-4"></div>
              <label>Training Type (ISO Codes)</label>
              <CheckboxList
                id="trainingTypesList"
                data={trainingTypes?.map((c) => {
                  return {
                    label: c.name,
                    value: c.isEnabled,
                  };
                })}
                onChange={(data) => {
                  data.forEach((item, i) => {
                    trainingTypes[i].isEnabled = item.value;
                  });
                  if (props?.data?.report) {
                    props.data.report.selectedTrainingTypeIds = trainingTypes
                      .filter((t) => t.isEnabled)
                      .map((t) => t.id);

                    toggleTrainingTypes();
                    const ids = props.data.report.selectedTrainingTypeIds;
                    setValue(
                      "selectedTrainingTypeIds",
                      ids?.length > 0 ? ids : null
                    );
                  }
                  toggleOtherItems();
                }}
                disabled={!props.data.allowEdit}
              ></CheckboxList>

              <FormError
                field={props.form.selectedTrainingTypeIds.name}
                formState={props.formState}
              ></FormError>
            </>
          )}

        {showOtherTrainingType && (
          <div>
            <div className="mt-4"></div>
            <FgInput
              label="Other Training Type"
              id={props.form.otherTrainingType.name}
              formState={props.formState}
              registeredField={props.form.otherTrainingType}
              readOnly={!props.data.allowEdit}
            />
          </div>
        )}

        <div className="mt-4"></div>

        <span className="required-label">*</span>
        <label>Start Date/Time</label>
        <DateTimePicker
          disabled={!props.data.allowEdit}
          onChange={(data) => {
            setValue("startTime", data);
            setStartDateTime(data);
          }}
          data={startDateTime}
          dayPickerProps={{
            disabledDays: [
              {
                before: moment()
                  .add(((template.numDaysToSubmitDrill || 6) - 1) * -1, "day")
                  .toDate(),
                after: new Date(),
              },
            ],
          }}
        />
        <FormError
          field={props.form.startTime.name}
          formState={props.formState}
        ></FormError>

        <div className="mt-4"></div>

        <span className="required-label">*</span>
        <label>End Date/Time</label>
        <DateTimePicker
          disabled={!props.data.allowEdit}
          onChange={(data) => {
            setValue("endTime", data);
            setEndDateTime(data);
          }}
          data={endDateTime}
          dayPickerProps={{
            disabledDays: [
              startDateTime
                ? {
                    before: startDateTime,
                    after: moment(
                      moment(startDateTime).format("YYYY-MM-DDT23:59")
                    ).isAfter(new Date())
                      ? new Date()
                      : moment(startDateTime).add(1, "day").toDate(),
                  }
                : {
                    before: moment().add(-5, "day").toDate(),
                    after: new Date(),
                  },
            ],
          }}
        />
        <FormError
          field={props.form.endTime.name}
          formState={props.formState}
        ></FormError>

        <div className="mt-4"></div>
        <div className="txt-primary">
          <strong>
            TOTAL HOURS:
            <span className={getTotalHours() < 0 ? "txt-danger" : ""}>
              {" "}
              {getTotalHours()}{" "}
              {Math.abs(getTotalHours()) <= 1 ? "hour" : "hours"}
            </span>
          </strong>
        </div>
        {(getTotalHours() < 0.25 || getTotalHours() > 12) && (
          <div>
            <small className="txt-danger">
              Drill duration must be between 0.25 and 12 hours
            </small>
          </div>
        )}
        <div className="mt-4"></div>

        <FgInput
          label="Drill Description"
          showRequiredLabel={true}
          id={props.form.description.name}
          formState={props.formState}
          registeredField={props.form.description}
          rows={5}
          readOnly={!props.data.allowEdit}
        ></FgInput>
      </div>
    </div>
  );
}

export default DrillDetail;
