import ApsModels from "../../models";
import fetcher from "./Fetcher";

class CredTemplateService {
  getCourseTemplate = async (id: number) => {
    const url = `/api/CredTemplate/GetCourseTemplate/${id}`;
    return fetcher.get<ApsModels.ICredCourseTemplateOutputDto>(url);
  };

  getCredentialTemplate = async (id: number) => {
    const url = `/api/CredTemplate/GetCredentialTemplate/${id}`;
    return fetcher.get<ApsModels.ICredCredentialTemplateOutputDto>(url);
  };

  createCourseTemplate = async (formData: FormData) => {
    const url = "/api/CredTemplate/CreateCourseTemplate";
    return fetcher.postFormData<ApsModels.ICredCourseTemplateOutputDto>(
      url,
      formData
    );
  };

  createCredentialTemplate = async (
    data: ApsModels.ICredCredentialTemplateInsertDto
  ) => {
    const url = "/api/CredTemplate/CreateCredentialTemplate";
    return fetcher.post<ApsModels.ICredCredentialTemplateOutputDto>(url, data);
  };

  listTemplates = async (data: ApsModels.ICredTemplateListInputDto) => {
    const url = "/api/CredTemplate/ListTemplates";
    return fetcher.post<ApsModels.ICredTemplateGridDto>(url, data);
  };

  updateCourseTemplate = async (id: number, formData: FormData) => {
    const url = `/api/CredTemplate/UpdateCourseTemplate/${id}`;
    return fetcher.postFormData<ApsModels.ICredCourseTemplateOutputDto>(
      url,
      formData
    );
  };

  updateCredentialTemplate = async (
    id: number,
    data: ApsModels.ICredCredentialTemplateUpdateDto
  ) => {
    const url = `/api/CredTemplate/UpdateCredentialTemplate/${id}`;
    return fetcher.put<ApsModels.ICredCredentialTemplateOutputDto>(url, data);
  };

  deleteCourseTemplate = async (id: number) => {
    const url = `/api/CredTemplate/DeleteCourseTemplate/${id}`;
    return fetcher.delete<any>(url);
  };

  deleteCredentialTemplate = async (id: number) => {
    const url = `/api/CredTemplate/DeleteCredentialTemplate/${id}`;
    return fetcher.delete<any>(url);
  };

  downloadCourseFile = async (id: number, fileId: number) => {
    const url = `/api/CredTemplate/DownloadCourseFile/${id}/${fileId}`;
    return fetcher.get<any>(url, { responseType: "blob" });
  };

  listCourseTemplateFiles = async (id: number) => {
    const url = `/api/CredTemplate/ListCourseTemplateFiles/${id}`;
    return fetcher.get<ApsModels.ICredCourseFileOutputDto[]>(url);
  };

  copyCourseTemplate = async (data: ApsModels.ICredCourseTemplateCopyDto) => {
    const url = "/api/CredTemplate/CopyCourseTemplate";
    return fetcher.post<ApsModels.ICredCourseTemplateOutputDto>(url, data);
  };

  displayCredentialTemplatesFlag = async () => {
    const url = `/api/CredTemplate/DisplayCredentialTemplatesFlag`;
    return fetcher.post<boolean>(url);
  };

  typeAheadCourseCategory = async (
    data: ApsModels.ICourseCategoryTypeAheadDto
  ) => {
    const url = `/api/CredTemplate/TypeAheadCourseCategory`;
    return fetcher.post<ApsModels.ICredCourseCategoryOutputDto[]>(url, data);
  };

  copyCredentialTemplate = async (
    data: ApsModels.ICredCredentialTemplateCopyDto
  ) => {
    const url = "/api/CredTemplate/CopyCredentialTemplate";
    return fetcher.post<ApsModels.ICredCredentialTemplateOutputDto>(url, data);
  };

  displayNotificationSectionInTemplatesFlag = async () => {
    const url = `/api/CredTemplate/DisplayNotificationSectionInTemplatesFlag`;
    return fetcher.post<any>(url);
  };
}

const credTemplate = new CredTemplateService();
export default credTemplate;
