import ApsModels from "../../models";
import fetcher from "./Fetcher";

class CredCredentialService {
  typeAheadCredential = async (
    data: ApsModels.ICredCredentialTypeAheadInputDto
  ) => {
    const url = `/api/CredCredential/TypeAheadCredential`;
    return fetcher.post<ApsModels.ICredCredentialTypeAheadOutputDto[]>(
      url,
      data
    );
  };

  typeAheadCredentialForExisting = async (
    data: ApsModels.ICredCredentialTypeAheadInputDto
  ) => {
    const url = `/api/CredCredential/TypeAheadCredentialForExisting`;
    return fetcher.post<ApsModels.ICredCredentialTypeAheadOutputDto[]>(
      url,
      data
    );
  };

  typeAheadTakenCredential = async (
    data: ApsModels.ICredCredentialTypeAheadInputDto
  ) => {
    const url = `/api/CredCredential/TypeAheadTakenCredential`;
    return fetcher.post<ApsModels.ICredCredentialTypeAheadOutputDto[]>(
      url,
      data
    );
  };

  getCredentialCategories = async (type: ApsModels.CredLibraryTypeEnum) => {
    const url = `/api/CredCredential/GetCredentialCategories/${type}`;
    return fetcher.get<ApsModels.ICredCredentialCategoryOutputDto[]>(url);
  };

  getEmptyCredential = async (id: number) => {
    const url = `/api/CredCredential/GetEmptyCredential/${id}`;
    return fetcher.get<ApsModels.ICredEmptyCredentialOutputDto>(url);
  };

  getEmptyTemplateCredential = async (id: number) => {
    const url = `/api/CredCredential/GetEmptyTemplateCredential/${id}`;
    return fetcher.get<ApsModels.ICredEmptyCredentialOutputDto>(url);
  };

  getTakenCredential = async (id: number, update = false) => {
    const url = `/api/CredCredential/GetTakenCredential/${id}/${update}`;
    return fetcher.get<ApsModels.ICredTakenCredentialOutputDto>(url);
  };

  getTakenCredentialForEdit = async (id: number) => {
    const url = `/api/CredCredential/GetTakenCredentialForEdit/${id}`;
    return fetcher.get<ApsModels.ICredTakenCredentialOutputDto>(url);
  };

  saveOwnTakeCredential = async (formData: FormData) => {
    const url = `/api/CredCredential/SaveOwnTakeCredential`;
    return fetcher.postFormData<any>(url, formData);
  };

  adminAddTakenCredential = async (
    data: ApsModels.ICredTakenCredentialAdminAddDto
  ) => {
    const url = `/api/CredCredential/AdminAddTakenCredential`;
    return fetcher.post<any>(url, data);
  };

  adminUpdateTakenCredential = async (formData: FormData) => {
    const url = `/api/CredCredential/AdminUpdateTakenCredential`;
    return fetcher.postFormData<any>(url, formData);
  };

  listCredentialsByCredentialForAdminManager = async (
    page: number,
    pageSize: number,
    data: ApsModels.ICredCredentialByCredentialSearchInputDto
  ) => {
    const url = `/api/CredCredential/ListCredentialsByCredentialForAdminManager/${page}/${pageSize}`;
    return fetcher.post<ApsModels.ICredCredentialByCredentialGridOutputDto>(
      url,
      data
    );
  };

  listCredentialsByCredentialForUser = async (
    page: number,
    pageSize: number,
    data: ApsModels.ICredCredentialByCredentialSearchInputDto
  ) => {
    const url = `/api/CredCredential/ListCredentialsByCredentialForUser/${page}/${pageSize}`;
    return fetcher.post<ApsModels.ICredCredentialByCredentialGridOutputDto>(
      url,
      data
    );
  };

  listCredentialsByEmployee = async (
    page: number,
    pageSize: number,
    data: ApsModels.ICredCredentialByEmployeeSearchInputDto
  ) => {
    const url = `/api/CredCredential/ListCredentialsByEmployee/${page}/${pageSize}`;
    return fetcher.post<ApsModels.ICredCredentialByEmployeeGridOutputDto>(
      url,
      data
    );
  };

  listCredentialsForEmployee = async (
    page: number,
    pageSize: number,
    userId: number
  ) => {
    const url = `/api/CredCredential/ListCredentialsForEmployee/${page}/${pageSize}/${userId}`;
    return fetcher.get<ApsModels.ICredCredentialForEmployeeGridDto>(url);
  };

  listEmployeesInCredential = async (
    page: number,
    pageSize: number,
    id: number,
    data: ApsModels.ICredCredentialByCredentialSearchInputDto
  ) => {
    const url = `/api/CredCredential/ListEmployeesInCredential/${page}/${pageSize}/${id}`;
    return fetcher.post<ApsModels.ICredCredentialEmployeesInCredentialGridDto>(
      url,
      data
    );
  };

  importTakenCredentials = async (formData: FormData) => {
    const url = `/api/CredCredential/ImportTakenCredentials`;
    return fetcher.postFormData<any>(url, formData);
  };

  extractCredentialsByCredential = async (
    data: ApsModels.ICredTakenCredentialExtractFilterDto
  ) => {
    const url = `/api/CredCredential/ExtractCredentialsByCredential`;
    return fetcher.post<any>(url, data, { responseType: "blob" });
  };

  extractCredentialsByLastModified = async (
    data: ApsModels.ICredTakenCredentialExtractFilterDto
  ) => {
    const url = `/api/CredCredential/ExtractCredentialsByLastModified`;
    return fetcher.post<any>(url, data, { responseType: "blob" });
  };

  extractCredentialsByEmployee = async (
    data: ApsModels.ICredTakenCredentialExtractFilterDto
  ) => {
    const url = `/api/CredCredential/ExtractCredentialsByEmployee`;
    return fetcher.post<any>(url, data, { responseType: "blob" });
  };

  extractCredentialsForEmployee = async (
    userId: number,
    data: ApsModels.ICredTakenCredentialExtractFilterDto
  ) => {
    const url = `/api/CredCredential/ExtractCredentialsForEmployee/${userId}`;
    return fetcher.post<any>(url, data, { responseType: "blob" });
  };

  extractEmployeesInCredentials = async (
    id: number,
    data: ApsModels.ICredTakenCredentialExtractFilterDto
  ) => {
    const url = `/api/CredCredential/ExtractEmployeesInCredentials/${id}`;
    return fetcher.post<any>(url, data, { responseType: "blob" });
  };

  downloadTakenCredentialCertificate = async (id: number, fileId: number) => {
    const url = `/api/CredCredential/DownloadTakenCredentialCertificate/${id}/${fileId}`;
    return fetcher.get<any>(url, { responseType: "blob" });
  };

  downloadTakenCredentialRequirementCertificate = async (
    id: number,
    credentialId: number,
    fileId: number
  ) => {
    const url = `/api/CredCredential/DownloadTakenCredentialRequirementCertificate/${id}/${credentialId}/${fileId}`;
    return fetcher.get<any>(url, { responseType: "blob" });
  };

  adminUpdateIndividualRequirement = async (
    files: File[],
    jsonData: ApsModels.ICredIndividualRequirementUpdateDto
  ) => {
    const url = `/api/CredCredential/AdminUpdateIndividualRequirement`;
    return fetcher.postFormData<any>(
      url,
      fetcher.getFormData(files, jsonData, "files")
    );
  };

  updateIndividualRequirement = async (
    files: File[],
    jsonData: ApsModels.ICredIndividualRequirementUpdateDto
  ) => {
    const url = `/api/CredCredential/UpdateIndividualRequirement`;
    return fetcher.postFormData<any>(
      url,
      fetcher.getFormData(files, jsonData, "files")
    );
  };

  getIndividualTakenRequirement = async (id: number, credentialId: number) => {
    const url = `/api/CredCredential/GetIndividualTakenRequirement/${id}/${credentialId}`;
    return fetcher.get<ApsModels.ICredIndividualRequirementOutputDto>(url);
  };

  getExistingCredentialForUpdate = async (id: number) => {
    const url = `/api/CredCredential/GetExistingCredentialForUpdate/${id}`;
    return fetcher.get<ApsModels.ICredExistingCredentialOutputDto>(url);
  };

  adminAddExistingCredential = async (
    data: ApsModels.ICredExistingCredentialInsertDto
  ) => {
    const url = `/api/CredCredential/AdminAddExistingCredential`;
    return fetcher.post<ApsModels.ICredExistingCredentialOutputDto>(url, data);
  };

  adminUpdateExistingCredential = async (
    files: File[],
    jsonData: ApsModels.ICredExistingCredentialAdminUpdateDto
  ) => {
    const url = `/api/CredCredential/AdminUpdateExistingCredential`;
    return fetcher.postFormData<ApsModels.ICredExistingCredentialOutputDto>(
      url,
      fetcher.getFormData(files, jsonData, "files")
    );
  };

  addOwnExistingCredential = async (
    files: File[],
    jsonData: ApsModels.ICredExistingCredentialSelfInsertDto
  ) => {
    const url = `/api/CredCredential/AddOwnExistingCredential`;
    return fetcher.postFormData<ApsModels.ICredExistingCredentialOutputDto>(
      url,
      fetcher.getFormData(files, jsonData, "files")
    );
  };

  updateOwnExistingCredential = async (
    files: File[],
    jsonData: ApsModels.ICredExistingCredentialSelfUpdateDto
  ) => {
    const url = `/api/CredCredential/UpdateOwnExistingCredential`;
    return fetcher.postFormData<ApsModels.ICredExistingCredentialOutputDto>(
      url,
      fetcher.getFormData(files, jsonData, "files")
    );
  };

  listCredentialsByLastModified = async (
    page: number,
    pageSize: number,
    data: ApsModels.ICredCredentialByLastModifiedSearchInputDto
  ) => {
    const url = `/api/CredCredential/ListCredentialsByLastModified/${page}/${pageSize}`;
    return fetcher.post<ApsModels.ICredCredentialByLastModifiedGridOutputDto>(
      url,
      data
    );
  };

  adminAssignTakenCredential = async (
    data: ApsModels.ICredTakenCredentialAdminAddDto
  ) => {
    const url = `/api/CredCredential/AdminAssignTakenCredential`;
    return fetcher.post<any>(url, data);
  };

  assignOwnTakenCredential = async (
    data: ApsModels.ICredTakenCredentialUpdateDto
  ) => {
    const url = `/api/CredCredential/AssignOwnTakenCredential`;
    return fetcher.post<any>(url, data);
  };

  deletePersonFromCredential = async (
    personId: number,
    takenCredentialId: number
  ) => {
    const url = `/api/CredCredential/DeletePersonFromCredential/${personId}/${takenCredentialId}`;
    return fetcher.delete<any>(url, {});
  };

  addNewCategory = async (
    data: ApsModels.ICredCredentialCategoryEntryAddDto
  ) => {
    const url = "/api/CredCredential/AddNewCategory";
    return fetcher.post<ApsModels.ICredCredentialCategoryOutputDto>(url, data);
  };

  renewAdmin = async (data: ApsModels.ICredCredentialRenewInputDto) => {
    const url = "/api/CredCredential/RenewAdmin";
    return fetcher.post<any>(url, data);
  };

  renewOwn = async (data: ApsModels.ICredCredentialRenewInputDto) => {
    const url = "/api/CredCredential/RenewOwn";
    return fetcher.post<any>(url, data);
  };

  adminUploadTakenCredentialFiles = async (formData: FormData) => {
    const url = `/api/CredCredential/AdminUploadTakenCredentialFiles`;
    return fetcher.postFormData<any>(url, formData);
  };

  ownUploadTakenCredentialFiles = async (formData: FormData) => {
    const url = `/api/CredCredential/OwnUploadTakenCredentialFiles`;
    return fetcher.postFormData<any>(url, formData);
  };
}

const credCredential = new CredCredentialService();
export default credCredential;
