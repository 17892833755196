import React, { useEffect, useState } from "react";
import toastStore from "../../../aps2/stores/ToastStore";
import CredentialDepartmentSettingsService from "../../../services/CredentialDepartmentSettingsService";

type DepartmentSettings = {
  emsceProviderNumber: string;
  id: number;
  concurrencyToken: string;
  departmentId: number;
};

type State = {
  collapse: boolean;
  ready: boolean;
  departmentSettings?: DepartmentSettings;
};

const CredDepartmentSettings = (props: { departmentId: number }) => {
  const [state, setState] = useState<State>({ collapse: true, ready: false });
  const onCollapseChange = () =>
    setState({ ...state, collapse: !state.collapse });

  useEffect(() => {
    const init = async () => {
      const departmentSettings =
        await CredentialDepartmentSettingsService.getDepartmentSettings(
          props.departmentId
        );
      setState({
        ...state,
        ready: true,
        departmentSettings: {
          emsceProviderNumber: departmentSettings.emsceProviderNumber,
          id: departmentSettings.id,
          concurrencyToken: departmentSettings.concurrencyToken,
          departmentId: props.departmentId,
        },
      });
    };
    init();
  }, []);

  const onSaveClicked = async () => {
    CredentialDepartmentSettingsService.saveDepartmentSettings(
      state.departmentSettings as any
    ).then(settings => {
      setState({...state, departmentSettings: settings});
      toastStore.showToast("Credential Settings saved successfully", "success")
    }).catch((error)=> {
      toastStore.showError("Credential Settings were not able to be saved", error);
    });
  };

  const onProviderChanged = (provider: string) => {
    setState({
      ...state,
      departmentSettings: {
        ...state.departmentSettings!,
        emsceProviderNumber: provider,
      },
    });
  };

  return (
    <div className="flex flex-1 flex-col">
      <div className="container-fluid flex-card-container">
        <div className="flex-0">
          <div className="headerControls">
            <div>
              <span className="pointer" onClick={onCollapseChange}>
                <i
                  className={`fa fa-chevron-${
                    state.collapse ? "up" : "down"
                  } pl-2 pr-2`}
                ></i>
                <h4 className="pt-3 pl-2 m-0 inline-block">
                  Credentials
                </h4>
              </span>
            </div>
            <div></div>
          </div>
        </div>
        <div
          className={`doc-designer w-100 pb-4 ${
            state.collapse ? "display-none" : ""
          }`}
        >
          {state.ready ? (
            <>
              <div className="mb-4">
                EMS CE Provider Number
                <input
                  className="form-control"
                  type="text"
                  name="emsProvider"
                  id="emsProvider"
                  value={state.departmentSettings?.emsceProviderNumber}
                  onChange={(e) => onProviderChanged(e.target.value)}
                />
              </div>
              <div>
                <button
                  className="btn btn-primary float-right"
                  onClick={onSaveClicked}
                >
                  <span className="font-size-12">Save</span>
                </button>
              </div>
            </>
          ) : (
            "Loading..."
          )}
        </div>
      </div>
    </div>
  );
};

export default CredDepartmentSettings;
