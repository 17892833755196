import React, { useEffect, useState } from "react";
import { IDrillReportProps } from ".";
import { FgSelect, IChildCardProps } from "../../Common/FormGroups";
import { AsyncTypeahead } from "react-bootstrap-typeahead"; // ES2015
import ApsServices from "../../../services";
import FormError from "../../Common/FormError";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRS/NFIRSHelper";
import commonService from "../../../services/CommonService";
import toastStore from "../../../stores/ToastStore";
import ConfirmDialog from "../../Common/ConfirmDialog";
import PreviousDayIcon from "../../../../assets/images/PreviousDayIcon.png";

interface IOption {
  id: number;
  firstName: string;
  lastName: string;
  name: string;
}

function DrillParticipants(props: IChildCardProps<IDrillReportProps>) {
  const [users, setUsers] = useState([] as IOption[]);
  const [isLoading, setIsLoading] = useState(false);
  const [participants, setParticipants] = useState(
    props?.data?.report?.selectedParticipants || []
  );

  const [selectedGroup, setSelectedGroup] = useState<number>();
  const groups = useNfirsGetData(
    ApsServices.http.group.listAllGroups,
    "Groups"
  );
  const getEmployees = useNfirsGetData(
    async () => ApsServices.http.group.getAllUsers(selectedGroup),
    "Employees"
  );

  const ref = React.createRef<AsyncTypeahead<any>>();

  const [gettingList, setGettingList] = useState(false);
  const loadLastUsedList = async () => {
    setGettingList(true);
    await ApsServices.http.drillReport
      .listLastUsedUsers()
      .then((data) => {
        if (data && data.length > 0) {
          if (props?.setValue) {
            setParticipants(data);
            props.setValue("selectedParticipants", data);
          }
        }
        setGettingList(false);
      })
      .catch((err) => {
        console.log(err);
        setGettingList(false);
      });
  };

  useEffect(() => {
    setParticipants(props?.data?.report?.selectedParticipants || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data?.report?.selectedParticipants]);

  useEffect(() => {
    groups.getData();
    getEmployees.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(getEmployees.status !== NfirsFetchStatus.Complete);
    if (
      getEmployees.status === NfirsFetchStatus.Complete &&
      getEmployees.data
    ) {
      setUsers((list) => {
        return [
          ...(getEmployees.data || []).map((i) => {
            return {
              id: i.id,
              name: `${i.firstName} ${i.lastName}`,
              firstName: i.firstName,
              lastName: i.lastName,
            };
          }),
        ];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmployees.status]);

  useEffect(() => {
    setIsLoading(true);
    const tmo = setTimeout(() => {
      setIsLoading(false);
      if (groups.status === NfirsFetchStatus.Complete) {
        getEmployees.getData();
      }
    }, 500);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup, groups.status]);

  const [openEmpDropdown, setOpenEmpDropdown] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);

  return (
    <>
      <ConfirmDialog
        show={deleteAll}
        buttons="yesno"
        title="Confirmation"
        message="Do you reall want to delete all employees selected?"
        done={(rtn) => {
          if (rtn === "yes") {
            setParticipants([]);
            if (props?.setValue) {
              props.setValue("selectedParticipants", []);
            }
          }
          setDeleteAll(false);
        }}
      />
      <div className="card">
        <div className="card-body">
          <div className="flex flex-center">
            <div className="flex-1">
              <strong>{props?.data?.sectionNumber}. Participants</strong>
            </div>
            <div>
              <img
                src={PreviousDayIcon}
                alt="refresh button"
                className="refresh-button"
                onClick={(e) => {
                  if (!gettingList) {
                    loadLastUsedList();
                  }
                }}
              />
            </div>
          </div>
          <div className="mt-4"></div>

          {props.data.allowEdit && props.data.allowAddOthers && (
            <>
              <div>
                <span className="required-label">*</span>
                <label>Search for Participants</label>{" "}
              </div>
              <FgSelect
                id="_rank"
                label=""
                noPlaceholder={true}
                groups={[
                  { id: 0, label: "By Employee" },
                  { id: -1, label: "By Group" },
                ]}
                options={[
                  {
                    label: "Employees",
                    value: 0,
                    groupId: 0,
                  },
                  ...(groups.data || []).map((cat) => {
                    return {
                      label: cat.name,
                      value: cat.groupId,
                      groupId: -1,
                    };
                  }),
                ]}
                disabledOptionValues={[-1]}
                onChange={(data) => {
                  setSelectedGroup(commonService.getNumber(data));
                }}
              />

              <div className="text-primary mt-1 px-1 flex">
                <div className="flex-1">
                  <span
                    className="pointer"
                    onClick={(e) => {
                      const emps = users
                        .filter(
                          (emp) => !participants.find((i) => i.id === emp.id)
                        )
                        .map((i) => {
                          return {
                            id: i.id,
                            name: i.name,
                            firstName: i.firstName,
                            lastName: i.lastName,
                            departmentName: "", //TODO
                            drillReportEligibility: "",
                          };
                        });

                      const newList = [...participants, ...emps];
                      setParticipants((list) => {
                        return [...list, ...emps];
                      });

                      if (props?.setValue) {
                        props.setValue("selectedParticipants", newList);
                      }

                      if (emps.length > 0) {
                        toastStore.showToast(
                          emps.length === 1
                            ? "1 Employee Added"
                            : `${emps.length} Employees Added`,
                          "success"
                        );
                      } else {
                        toastStore.showToast("No Employee(s) Added", "warning");
                      }
                    }}
                  >
                    <small>
                      {selectedGroup
                        ? `Add All ${
                            (groups?.data || []).find(
                              (x) => x.groupId === selectedGroup
                            )?.name || ""
                          }`
                        : "Add All Employees"}
                    </small>
                  </span>
                </div>
                <div>
                  {participants.length > 0 && (
                    <small
                      className="text-danger pointer"
                      onClick={(e) => {
                        setDeleteAll(true);
                      }}
                    >
                      Delete All
                    </small>
                  )}
                </div>
              </div>

              <div>
                <AsyncTypeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  onSearch={(e) => {}} //handleSearch
                  open={openEmpDropdown}
                  onFocus={(e) => setOpenEmpDropdown(true)}
                  onBlur={(e) => setOpenEmpDropdown(false)}
                  onChange={(data) => {
                    if (data && data.length > 0) {
                      const prts = [...participants];
                      if (
                        prts.filter((p) => p.id === data[0].id).length === 0
                      ) {
                        prts.push({
                          id: data[0].id,
                          firstName: data[0].firstName,
                          lastName: data[0].lastName,
                          departmentName: "", //TODO
                          drillReportEligibility: "",
                        });
                        setParticipants(prts);
                      } else {
                        toastStore.showToast(
                          `${data[0].name} is already in the list.`,
                          "warning"
                        );
                      }

                      if (props?.setValue) {
                        props.setValue("selectedParticipants", prts);
                      }

                      //setUsers(data);
                      (ref.current as any)?.clear();
                      ref.current?.blur();
                    }
                  }}
                  searchText={"Searching..."}
                  isLoading={isLoading}
                  options={isLoading ? [] : users}
                  placeholder="Add or Search Employee"
                  promptText={isLoading ? "Loading..." : "No Records Found"}
                  minLength={1}
                  delay={500}
                  useCache={false}
                  ref={ref}
                />
              </div>

              <FormError
                field={props.form.selectedParticipants.name}
                formState={props.formState}
              ></FormError>

              <div className="mt-4"></div>

              <div className="currentSelectedParticipantsLabel">
                <label>Current Selected</label>
                <button
                  className="btn btn-sm btn-link"
                  type="button"
                  onClick={loadLastUsedList}
                  disabled={gettingList}
                >
                  {gettingList ? "Loading..." : "Load last used list"}
                </button>
              </div>
            </>
          )}

          <div className="currentParticipants">
            {participants?.map((p, i) => {
              return (
                <div key={i}>
                  <section>{`${p.firstName} ${p.lastName}`}</section>
                  <section
                    className={
                      props.data.allowEdit && props.data.allowAddOthers
                        ? ""
                        : "display-none"
                    }
                    title="Remove"
                    onClick={() => {
                      const prts = [...participants];
                      prts.splice(i, 1);
                      setParticipants(prts);
                      if (props?.setValue) {
                        props.setValue("selectedParticipants", prts);
                      }
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </section>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default DrillParticipants;
