import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import CheckboxList from "../../Common/CheckboxList";
import { FgCheckbox, FgSelect, IChildCardProps } from "../../Common/FormGroups";
import DTCoursesDialog from "./Dialogs/DTCoursesDialog";

function DTExternalPartners(
  props: IChildCardProps<ApsModels.IDrillTemplateOutputDto>
) {
  const [calJacs, setCalJacs] = useState(
    [] as ApsModels.ICalJacTrainingTypeOutputDto[]
  );
  const [subjects, setSubjects] = useState(
    [] as ApsModels.ILogDrillSubjectOutputDto[]
  );
  const [studies, setStudies] = useState(
    [] as ApsModels.ILogDrillAreaOfStudyOutputDto[]
  );

  const [showCourseDialog, setShowCourseDialog] = useState(false);
  const template = (props?.data || {}) as ApsModels.IDrillTemplateOutputDto;

  const [newCourseId, setNewCourseId] = useState(0);
  const [extraInfo, setExtraInfo] = useState({} as ApsModels.IDrillOutputDto);
  
  const [courses, setCourses] = useState((template.isaCourses || []).sort(ApsServices.common.sortByName));    
  const [isJacEnabled, setIsJacEnabled] = useState(template.isJacEnabled);
  const [isIsaEnabled, setIsIsaEnabled] = useState(template.isIsaEnabled);

  const getCalJacs = async () => {
    if (template.departmentId) {
      await ApsServices.http.calJacTrainingType
        .getAll(template.departmentId)
        .then((data) => {
          setCalJacs(data);
        })
        .catch((err) => {
          console.log(err);
          setCalJacs([]);
        });
    } else {
      setCalJacs([]);
    }
  };

  const getSubjects = async () => {
    if (template.departmentId) {
      await ApsServices.http.logDrillSubject
        .getAll(template.departmentId)
        .then((data) => {
          setSubjects(data);
        })
        .catch((err) => {
          console.log(err);
          setSubjects([]);
        });
    } else {
      setSubjects([]);
    }
  };

  const getStudies = async (subjectId: number | null) => {
    if (template.departmentId && subjectId) {
      await ApsServices.http.logDrillAreaOfStudy
        .getAll(subjectId, template.departmentId)
        .then((data) => {
          setStudies(data);
        })
        .catch((err) => {
          console.log(err);
          setStudies([]);
        });
    } else {
      setStudies([]);
    }
  };

  const getExtraInfo = async (id?: number) => {
    setExtraInfo({} as any);
    if (id) {
      template?.drills?.forEach((data) => {
        if (data.id === id) {
          setExtraInfo({ ...data });
          getStudies(data?.logDrillSubjectId);
        }
      });
    }
  };

  useEffect(() => {
    getCalJacs();
    getSubjects();
  }, []);

  useEffect(() => {
    setCourses((template.isaCourses || []).sort(ApsServices.common.sortByName));
    setIsJacEnabled(template.isJacEnabled);
    setIsIsaEnabled(template.isIsaEnabled);
  }, [props.data]);

  return (
    <div className="card">
      <div className="card-body">
        <strong>3. External Partners</strong>
        <div className="mt-4"></div>

        <div className="row">
          <div className="col-12 col-lg-12">
            <FgCheckbox
              id="enableCALJACDetails"
              label="CAL-JAC Details"
              secondLabel="Enable"
              onChange={(data) => {
                template.isJacEnabled = data;
                setIsJacEnabled(data);
                props?.onChange(template);
              }}
              data={template.isJacEnabled}
            />

            <div className="mt-4"></div>

            {(isJacEnabled || isIsaEnabled) && (
              <>
                <FgSelect
                  id="selectDrillNameId"
                  label="Select each 'Drill Name' to map corresponding default options for CAL-JAC details and/or ISA Details"
                  options={template?.drills
                    ?.filter((c) => c.isEnabled)
                    ?.map((c) => {
                      return {
                        label: c.name,
                        value: c.id,
                      };
                    })}
                  onChange={(data) => {
                    getExtraInfo(parseInt(data) || undefined);
                    props?.onChange(template);
                  }}
                />

                <div className="mt-4"></div>
              </>
            )}

            {extraInfo && extraInfo.id && (isJacEnabled || isIsaEnabled) && (
              <>
                {isJacEnabled && (
                  <>
                    <FgSelect
                      id="subjectsDropdown"
                      label="Primary Subject (JAC Code) Default"
                      options={subjects?.map((c) => {
                        return {
                          label: c.name,
                          value: c.id,
                        };
                      })}
                      value={extraInfo?.logDrillSubjectId?.toString() || ""}
                      onChange={(data) => {
                        const id = parseInt(data) || null;
                        setExtraInfo({
                          ...extraInfo,
                          logDrillSubjectId: id as any,
                        });
                        template?.drills?.forEach((d) => {
                          if (d.id === extraInfo.id) {
                            d.logDrillSubjectId = id as any;
                          }
                        });
                        getStudies(id);
                        props?.onChange(template);
                      }}
                    />
                    <div className="mt-4"></div>

                    <FgSelect
                      id="studiesDropdown"
                      label="Specific Area of Study (JAC Code) Default"
                      options={studies?.map((c) => {
                        return {
                          label: c.name,
                          value: c.id,
                        };
                      })}
                      value={extraInfo?.logDrillAreaOfStudyId?.toString() || ""}
                      onChange={(data) => {
                        const id = parseInt(data) || null;
                        setExtraInfo({
                          ...extraInfo,
                          logDrillAreaOfStudyId: id as any,
                        });
                        template?.drills?.forEach((d) => {
                          if (d.id === extraInfo.id) {
                            d.logDrillAreaOfStudyId = id as any;
                          }
                        });
                        props?.onChange(template);
                      }}
                    />

                    <div className="mt-4"></div>

                    <FgSelect
                      id="calJacsDropdown"
                      label="Type of Training (JAC Code) Default"
                      options={calJacs?.map((c) => {
                        return {
                          label: c.name,
                          value: c.id,
                        };
                      })}
                      value={extraInfo?.calJacTrainingTypeId?.toString() || ""}
                      onChange={(data) => {
                        const id = parseInt(data) || null;
                        setExtraInfo({
                          ...extraInfo,
                          calJacTrainingTypeId: id as any,
                        });
                        template?.drills?.forEach((d) => {
                          if (d.id === extraInfo.id) {
                            d.calJacTrainingTypeId = id as any;
                          }
                        });
                        props?.onChange(template);
                      }}
                    />
                  </>
                )}

                {isIsaEnabled && (
                  <>
                    <div className="mt-4"></div>
                    <FgSelect
                      id="coursesDropdown"
                      label="Course (ISA Code) Default"
                      options={template.isaCourses
                        ?.filter((c) => c.isEnabled)
                        ?.map((c) => {
                          return {
                            label: c.name,
                            value: c.id,
                          };
                        })}
                      value={extraInfo?.isaCourseId?.toString() || ""}
                      onChange={(data) => {
                        const id = parseInt(data) || null;
                        setExtraInfo({
                          ...extraInfo,
                          isaCourseId: id as any,
                        });
                        template?.drills?.forEach((d) => {
                          if (d.id === extraInfo.id) {
                            d.isaCourseId = id as any;
                          }
                        });
                        props?.onChange(template);
                      }}
                    />
                  </>
                )}
              </>
            )}

            <div className="mt-4"></div>
            <FgCheckbox
              id="enableISADetails"
              label="ISA Details"
              secondLabel="Enable"
              onChange={(data) => {
                template.isIsaEnabled = data;
                setIsIsaEnabled(data);
                props?.onChange(template);
              }}
              data={template.isIsaEnabled}
            />
            <div className="mt-4"></div>

            {isIsaEnabled && (
              <>
                <span>Course</span>
                <div className="mt-2"></div>
                <CheckboxList
                  id="courseList"
                  data={courses?.map((c) => {
                    return {
                      label: c.name,
                      value: c.isEnabled,
                      id: c.id,
                    };
                  })}
                  onChange={(data) => {
                    data.forEach((item, i) => {
                      courses[i].isEnabled = item.value;
                    });
                    setCourses([...courses]);
                    props?.onChange({
                      ...template,
                      isaCourses: courses,
                    });
                  }}
                ></CheckboxList>

                <Button
                  variant="link"
                  onClick={() => setShowCourseDialog(true)}
                >
                  + Add New Option
                </Button>
              </>
            )}

            {showCourseDialog && (
              <DTCoursesDialog
                onChange={(data) => {
                  const newData = {
                    ...data,
                    id: newCourseId - 1,
                    temporaryId: newCourseId - 1,
                  } as any;
                  courses.push(newData);
                  courses.sort(ApsServices.common.sortByName);
                  setNewCourseId(newData.id);
                  props?.onChange({
                    ...template,
                    isaCourses: courses,
                  });
                }}
                onClose={(saved) => {
                  setShowCourseDialog(false);
                }}
                existingItems={courses?.map((c) => c.name)}
              ></DTCoursesDialog>
            )}

            <div className="mt-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DTExternalPartners;
