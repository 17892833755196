import React, { Component } from 'react';
import { EditorState, convertToRaw, convertFromRaw, Modifier, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import cloneDeep from 'lodash/cloneDeep';
import {
    QuestionDto,
    AnswerDto,
    AnswerType,
    QuestionGroupDto,
    SaveTimeoutInMilliseconds
} from '../../../data/DailyOperational';
import { RichUtils } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import commonService from '../../../aps2/services/CommonService';

// /*This is necessary because in the types of react-wysiwyg it is not defined. https://github.com/DefinitelyTyped/DefinitelyTyped*/
// declare module "react-draft-wysiwyg" {
//     interface EditorProps {
//         handleReturn(e: KeyboardEvent): boolean
//     }
// }

interface Props {
    questionGroup?: QuestionGroupDto
    date: Date,
    setData: (value: QuestionGroupDto | undefined, order: number) => void,
    isEditable: boolean
    moduleOrder: number
    departmentId: number;
}

interface State {
    editorState: EditorState | null,
    typingTimeout: ReturnType<typeof setTimeout> | null
}

export class RichEditor extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
    }

    state: State = {
        editorState: null,
        typingTimeout: null
    }

    componentDidMount(){
        let value = EditorState.createEmpty();
        const question = RichEditor.getAnswerText(this.props.questionGroup?.questions);
        try {            
            if (question) {
                const content = convertFromRaw(JSON.parse(question))
                value = EditorState.createWithContent(content)                
            }
        } catch {
            //CSM-451: Only check if HTML if new DOC
            if(commonService.shouldUseNewDOC(this.props.departmentId)){
                const content = htmlToDraft(question || '');
                value = EditorState.createWithContent(ContentState.createFromBlockArray(content.contentBlocks, content.entityMap));
            }
        } finally {
            this.setState({ editorState: value })
        }
    }

    updateQuestionGroup(newQuestionValue: QuestionDto) {
        const questionGroup = cloneDeep(this.props.questionGroup)!;
        const questionIndex = questionGroup.questions.findIndex(q => q.order == newQuestionValue.order);
        questionGroup.questions[questionIndex] = newQuestionValue;
        this.props.setData(questionGroup, this.props.moduleOrder);
    }

    getLinesCountFromState(editorState: EditorState): number {
        if (editorState === null) return 0;
        return this.getLinesCount(editorState.getCurrentContent().getPlainText()) + 1;
    }

    getLinesCount(text: string): number {
        return (text.match(/\n/g) || '').length;
    }

    getQuestionLimit(): number | undefined {
        return this.props.questionGroup?.questions.find(q => q.order === 1)?.limit;
    }

    isNewLineAllowed(): boolean {
        const linesCount = this.getLinesCountFromState(this.state.editorState!);
        const questionLimit = this.getQuestionLimit();
        if (questionLimit === null || questionLimit === undefined) return true;
        return linesCount < questionLimit;
    }

    private _lastSavedValue = "";
    private updateAnswer = () => {
        if (this.state.editorState === null) return;
        
        /* ORIGINAL CODE THAT SAVES "RAW"
        const jsonEvent = convertToRaw(this.state.editorState.getCurrentContent())
        const newValue = JSON.stringify(jsonEvent);
         */
        
        let jsonEvent, newValue = '';       
        
        if(commonService.shouldUseNewDOC(this.props.departmentId)) {
            //CSM-451: Only Save as HTML on new DOC
            jsonEvent = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
            newValue = jsonEvent;
        } else {
            jsonEvent = convertToRaw(this.state.editorState.getCurrentContent())
            newValue = JSON.stringify(jsonEvent);
        }

        if(newValue === this._lastSavedValue) {            
            return; //do nothing if nothing changed.
        }
        this._lastSavedValue = newValue;

        const questionOrder = 1;
        const question: QuestionDto = cloneDeep(this.props.questionGroup?.questions?.find(q => q.order == questionOrder)!);
        const isAnswerExists = question?.answers.length > 0;
        const answerId: number = question!.answers[0] ? question!.answers[0].answerId! : 0;

        let answer: AnswerDto;

        if (isAnswerExists || answerId > 0) {
            answer = question.answers.find(a => a.answerId == answerId)!;
            answer.textAnswerValue = newValue;
            answer.date = this.props.date;
            answer.dateCreated = this.props.date;
            answer.dateUpdated = this.props.date;
        } else {
            answer = {
                answerId: answerId,
                date: this.props.date,
                dateCreated: this.props.date,
                dateUpdated: this.props.date,
                order: 0,
                answerType: AnswerType.Text,
                questionId: question.questionId ?? 0,
                textAnswerValue: newValue
            }
            question.answers.push(answer);
        }

        this.updateQuestionGroup(question);
    }

    private static getAnswerText(questions: QuestionDto[] | undefined) {
        const shiftActivityOrder: number = 1;
        return questions && questions.find(x => x.order === shiftActivityOrder)?.answers.find(y => y.order === 0)?.textAnswerValue
    }

    componentDidUpdate = (prevProps: Props) => {
        if (prevProps.date !== this.props.date) {
            this.setState({ editorState: null })
        }
    }

    handleReturn = (e: any) => {
        if (this.isNewLineAllowed() && this.state.editorState!.getSelection().isCollapsed()) {
            this.setState({ editorState: RichUtils.insertSoftNewline(this.state.editorState!) });
            return false;
        }

        return true;
    }

    handleChange = (event: EditorState) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            editorState: event,
            typingTimeout: setTimeout(() => {
                if(event.getLastChangeType()) { //prevents triggering change if null
                    this.updateAnswer();
                }                
            }, SaveTimeoutInMilliseconds)
        });
    }

    handlePastedText = (
        text: string,
        html: string,
        editorState: EditorState,
        onChange: (editorState: EditorState) => void,
    ): boolean => {
        const questionLimit = this.getQuestionLimit();
        if (questionLimit === null || questionLimit === undefined) return false;

        const linesCountInState = this.getLinesCountFromState(editorState!);
        const textLinesCount = this.getLinesCount(text);
        const totalLinesCount = linesCountInState + textLinesCount;

        if (totalLinesCount <= questionLimit) return false;

        const extraLinesCount = totalLinesCount - questionLimit;
        let result = text;
        for (let i = 0; i < extraLinesCount; i++) {
            const index = result.lastIndexOf("\n");
            result = result.substring(0, index);
        }

        const selection = editorState.getSelection();

        if (!selection.isCollapsed()) return true;

        const contentState = editorState.getCurrentContent();

        const nextContentState = Modifier.insertText(contentState, selection, result);
        this.handleChange(EditorState.push(
            editorState,
            nextContentState,
            'insert-characters'
        ));

        return true;
    }

    render() {
        let value = EditorState.createEmpty();
        try {
            const question = RichEditor.getAnswerText(this.props.questionGroup?.questions)
            if (question) {
                const content = convertFromRaw(JSON.parse(question))
                value = EditorState.createWithContent(content)
            }
        } catch {
        }

        return (
                <Editor
                    toolbar={{
                        options: ['inline', 'list', 'textAlign', 'colorPicker', 'history'],
                        inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['bold', 'italic', 'underline', 'strikethrough'],
                        }
                    }}
                    editorStyle={{ fontSize: '22px' }}
                    handleReturn={e => this.handleReturn(e)}
                    handlePastedText={this.handlePastedText}
                    readOnly={this.props.isEditable}
                    editorState={this.state.editorState ?? value ?? EditorState.createEmpty()}
                    wrapperClassName="rich-editor demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={this.handleChange}
                />
        );
    }
}

