import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ApsModels from "../../models";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import toastStore from "../../stores/ToastStore";
import CommonSpinner from "../Common/CommonSpinner";
import Pagination, { usePaging } from "../Common/Pagination";
import UserEligibilityDialog from "./Dialogs/UserEligibilityDialog";

function UserEligibility(props: {
  id: any
  hasEligibilityChanges: (hasChanges: boolean) => void;
}) {
  const [collapse, setCollapse] = useState(true);
  const [loading, setLoading] = useState(false);
  const [eligibilityDialog, setEligibilityDialog] = useState({
    show: false,
    id: 0,
  });

  const [list, setList] = useState<ApsModels.IUserLogDrillEligibilityGridDto>();
  const [gridApi, setGridApi] = useState(null as any);
  const [gridColumnApi, setGridColumnApi] = useState(null as any);
  const [jacId, setJacId] = useState<string>("");
  const [origJacId, setOrigJacId] = useState<string>("");

    const handleSubmit = async () => {
        await ApsServices.http.userEligibility.saveJacId(jacId, props.id)
            .then(() => {
                toastStore.showToast("User JAC ID saved successfully.", "success");
                setOrigJacId(jacId);
                props.hasEligibilityChanges(false);
            })
            .catch((err) => {
                toastStore.showError("Failed saving user JAC ID.", err);
            });
    }

  const reset = () => {
      setJacId(origJacId);
      props.hasEligibilityChanges(false);
  }

  const hasChanges = () => {
    return jacId !== origJacId;
  }

    const getJacId = async () => {
        await ApsServices.http.userEligibility.getJacId(props.id)
            .then((data) => {
                setJacId(data);
                setOrigJacId(data);
            })
            .catch((err) => {
                toastStore.showError("Failed getting user JAC ID.", err);
            });;
    }

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    toggleColSizes();
  };

  const toggleColSizes = () => {
    if (gridApi && gridColumnApi && collapse && list) {
      setTimeout(() => {
        gridApi.sizeColumnsToFit();
        let allColumnIds: any[] = [];
        gridColumnApi.getAllColumns()?.forEach(function (column: any) {
          if (column.colId !== "action") {
            allColumnIds.push(column.colId);
          }
        });
        gridColumnApi.autoSizeColumns(allColumnIds, false);
      }, 500);
    }
  };

  const EditButtonCellComponent = (rowProps: {
    data: ApsModels.IDrillReportGridOutputItemDto;
  }) => {
    const buttonClicked = () => {
      setEligibilityDialog({ show: true, id: rowProps.data.id });
    };

    return (
      <>
        <button
          type="button"
          className="btn btn-sm btn-link"
          onClick={() => buttonClicked()}
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </button>
      </>
    );
  };

  const [gridState, setGridState] = useState({
    columnDefs: [
      {
        field: "id",
        headerName: "ID",
      },
      {
        field: "eligibilityType",
        headerName: "Type",
        valueFormatter: (param: any) => {
          return param.data.eligibilityType === 1
            ? "JAC Eligible"
            : "ISA Eligible";
        },
      },
      {
        field: "startTime",
        headerName: "Start Date",
        valueFormatter: (param: any) => {
          if (!param.data.startTime) {
            return "";
          }
          return moment(param.data.startTime).format("MMM DD, YYYY");
        },
      },
      {
        field: "endTime",
        headerName: "End Date",
        valueFormatter: (param: any) => {
          if (!param.data.endTime) {
            return "";
          }
          return moment(param.data.endTime).format("MMM DD, YYYY");
        },
      },
      {
        field: "action",
        headerName: "Action",
        cellRenderer: "editButtonCellComponent",
        width: 100,
        suppressSizeToFit: true,
      },
    ],
    defaultColDef: {
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
    },
    autoGroupColumnDef: { minWidth: 200 },
    rowData: [] as any[],
    frameworkComponents: {
      editButtonCellComponent: EditButtonCellComponent,
    },
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Loading</span>',
    overlayNoRowsTemplate:
      '<span class="ag-overlay-loading-center">No Records Found</span>',
  });

  const [paging, setPaging] = usePaging(1, 20);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const getList = async () => {
    gridApi && gridApi.showLoadingOverlay();

    await ApsServices.http.userEligibility
      .list(paging.page, paging.pageSize, props.id)
      .then((data) => {
        setList(data);
        if (data.totalRecords === 0) {
          gridApi && gridApi.showNoRowsOverlay();
        } else {
          gridApi && gridApi.hideOverlay();
        }
      })
      .catch((err) => {
        toastStore.showError("Failed getting User Eligibility list", err);
        gridApi && gridApi.hideOverlay();
      });
  };

  useEffect(() => {
    setGridState({
      ...gridState,
      rowData: list?.userLogDrillEligibilityGridOutputItemDtos || [],
    });
    toggleColSizes();
  }, [list]);

  useEffect(() => {
    if (props?.id && !collapse && gridApi) {
      getList();
    }
  }, [paging]);

  useEffect(() => {
    if (props?.id && !collapse && gridApi && !list) {
      getList();
    }
  }, [collapse, gridApi]);

  useEffect(() => {
    getJacId();
  }, [props.id]);

  return (
      <div className="container-fluid flex-card-container">
        <div className="flex-0">
          <div className="headerControls">
            <div>
              <span className="pointer" onClick={() => setCollapse(!collapse)}>
                <i
                  className={`fa fa-chevron-${
                    collapse ? "up" : "down"
                  } pl-2 pr-2`}
                ></i>
                <h4 className="pt-3 pl-2 m-0 inline-block">
                  Drills / JAC & ISA Eligibility
                </h4>
              </span>
            </div>
            <div className="pt-2 pb-0">
              <button
                type="button"
                className={`btn btn-primary ${collapse ? "display-none" : ""}`}
                onClick={() => {
                  setEligibilityDialog({ show: true, id: 0 });
                }}
              >
                <span className="font-size-12">New Eligibility</span>
              </button>
            </div>
          </div>
        </div>

        {loading && (
          <div className="p-1 pb-3">
            <CommonSpinner />
          </div>
        )}

        <div
          className={`row flex-1 ${collapse || loading ? "display-none" : ""}`}
        >
          <div className="col-sm-12 flex pb-2 align-items-center">
            <span className="ml-4">JAC ID</span>
            <input type="text" placeholder="JAC ID" 
              maxLength={64}
              className="col-xl-4 ml-3 border rounded-10 h-8"
              data-lpignore="true"
              value={jacId}
              onChange={(value) => {
                  setJacId(value.target.value);
                  props.hasEligibilityChanges(hasChanges());
              }}
            />
          </div>

          {eligibilityDialog.show && (
            <UserEligibilityDialog
              onChange={() => {
                setEligibilityDialog({ show: false, id: 0 });
              }}
              onClose={(rtn) => {
                if (rtn) {
                  getList();
                }
                setEligibilityDialog({ show: false, id: 0 });
              }}
              data={{
                id: eligibilityDialog.id,
                userdId: props.id,
              }}
            ></UserEligibilityDialog>
          )}

          <div className="col-sm-12 flex pb-2">
            <div
              id="myGrid"
              style={{
                minHeight: "300px",
                maxHeight: "500px",
              }}
              className="ag-theme-alpine flex-1"
            >
              <AgGridReact
                columnDefs={gridState.columnDefs}
                defaultColDef={gridState.defaultColDef}
                autoGroupColumnDef={gridState.autoGroupColumnDef}
                enableRangeSelection={true}
                animateRows={true}
                onGridReady={onGridReady}
                rowSelection={"multiple"}
                rowMultiSelectWithClick={false}
                suppressRowDeselection={true}
                suppressRowClickSelection={true}
                frameworkComponents={gridState.frameworkComponents}
                rowData={gridState.rowData || []}
                onRowDoubleClicked={(event: any) => {
                  setEligibilityDialog({ show: true, id: event.data.id });
                }}
              />
            </div>
          </div>
        </div>

        <div className={`flex-0 ${collapse ? "display-none" : ""}`}>
          <div className="headerControls">
            <div>
              {
                <Pagination
                  length={list?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="Records"
                  disabled={loading}
                  sizes={[10, 20, 50, 100]}
                ></Pagination>
              }
            </div>
            {/* <div></div> */}
          </div>
        </div>


        <div className={`flex-0 ${collapse ? "display-none" : ""}`}>
            <div className="headerControls">
                <div></div>
                <div>
                    <button type="button" className="btn btn-danger"
                        disabled={!hasChanges()} onClick={() => reset()}>
                        Reset
                    </button>
                    <button onClick={handleSubmit} className="btn btn-primary">
                        <span className="font-size-12">Save Section</span>
                    </button>
                </div>
            </div>
        </div>
      </div>
  );
}

export default UserEligibility;
