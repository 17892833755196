import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { RouteComponentProps } from "react-router-dom";
import ApsModels from "../../models";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import {
  ListCredLibraryTypes,
  useDepartmentId,
} from "../../stores/SystemStore";
import toastStore from "../../stores/ToastStore";
import CommonSpinner from "../Common/CommonSpinner";
import ConfirmDialog from "../Common/ConfirmDialog";
import DateTimePickerV2 from "../Common/DateTimePickerV2";
import FormError from "../Common/FormError";
import { FgInput, FgSelect, FgUseForm } from "../Common/FormGroups";
import RouteChangedGuard from "../Common/RouteChangedGuard";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRS/NFIRSHelper";

const GetSubmittedFrom = () => {
  return document.getElementById("credform")?.getAttribute("submittedfrom");
};

function RecordCredential(props: RouteComponentProps<any>) {
  const id = props.match?.params?.credentialid;
  const [credId, setCredId] = useState(Number(props.match?.params?.id) || 0);
  const [isNew, setIsNew] = useState(!id || props.match?.params?.id === "new");
  const [loading, setLoading] = useState(true);
  const [libType, setLibType] = useState(ApsModels.CredLibraryTypeEnum.All);
  const [selectedGroup, setSelectedGroup] = useState<number>();
  const groups = useNfirsGetData(
    ApsServices.http.group.listAllGroups,
    "Groups"
  );

  const departmentId = useDepartmentId();
  const categories = useNfirsGetData(
    async () =>
      ApsServices.http.credCredential.getCredentialCategories(libType),
    "Categories"
  );

  useEffect(() => {
    categories.getData();
    groups.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEmployees = useNfirsGetData(
    async () => ApsServices.http.group.getAllUsers(selectedGroup),
    "Employees"
  );

  useEffect(() => {
    setIsLoading(true);
    const tmo = setTimeout(() => {
      setIsLoading(false);
      if (groups.status === NfirsFetchStatus.Complete) {
        getEmployees.getData();
      }
    }, 500);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup, departmentId, groups.status]);

  useEffect(() => {
    setIsLoading(getEmployees.status !== NfirsFetchStatus.Complete);
    if (
      getEmployees.status === NfirsFetchStatus.Complete &&
      getEmployees.data
    ) {
      setUsers((list) => {
        return [
          ...(getEmployees.data || []).map((i) => {
            return {
              id: i.id,
              name: `${i.firstName} ${i.lastName}`,
              firstName: i.firstName,
              lastName: i.lastName,
            };
          }),
        ];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmployees.status]);

  const {
    registry,
    handleSubmit,
    formState,
    setValuesFromModel,
    setValue,
    clearErrors,
  } = FgUseForm({
    //Detail
    name: {
      displayName: "Credential Name",
      validation: {
        required: true,
      },
    },
    identificationNumber: {
      displayName: "Credential Number",
      validation: {
        required: false,
        ////https://aps-software.atlassian.net/browse/APS110-60
        // validate: {
        //   required: (val: any) => {
        //     if (
        //       commonService.isNullOrEmpty(val) &&
        //       GetSubmittedFrom() === "submit"
        //     ) {
        //       return false;
        //     }
        //     return true;
        //   },
        // },
      },
    },
    code: {
      displayName: "Credential Code",
      validation: {
        required: true,
      },
    },
    hours: {
      displayName: "Credential Code",
      validation: {
        required: false,
      },
    },
    period: {
      displayName: "Credential Period",
      validation: {
        required: false,
      },
    },
    lookupStateId: {
      displayName: "State Issued",
      validation: {
        required: true,
      },
    },
    dueDate: {
      displayName: "Assignment Due Date",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (!val && GetSubmittedFrom() === "assign") {
              return "Assignment Due Date is required";
            }
            return null;
          },
        },
      },
    },
    dateIssued: {
      displayName: "Effective Date",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (!val && GetSubmittedFrom() === "submit") {
              return "Expiration Date is required";
            }
            return null;
          },
        },
      },
    },
    renewalDate: {
      displayName: "Expiration Date",
      validation: {
        required: false,
        validate: {
          required: (val: any) => {
            if (GetSubmittedFrom() !== "submit") {
              return null;
            }
            if (expiresInYears <= 0) {
              return null;
            }
            if (emptyCred.data?.isEms && !model.renewalDate) {
              return "Expiration Date is required";
            }
            if (
              !model.dateIssued &&
              !model.renewalDate &&
              model.dateIssued > model.renewalDate
            ) {
              return "Effective Date cannot be greater that Expiration Date";
            }
            return true;
          },
        },
      },
    },
    //Completion
    employeeName: {
      displayName: "Employee Name",
      validation: {
        required: false,
        validate: {
          employees: (val: any) => {
            if (isNew && claims.course.canAddAll && employees.length === 0) {
              return "Please Select Employee(s)";
            }
            return true;
          },
        },
      },
    },
  });

  const [model, setModel] = useState<ApsModels.ICredTakenCredentialAdminAddDto>(
    {} as any
  );
  const [orignalModel, setOriginalModel] = useState(model);
  const [saving, setSaving] = useState(false);
  const [assigning, setAssigning] = useState(false);
  const [errorCode, setErrorCode] = useState(0);
  const [renewing, setRenewing] = useState(false);

  //const [filesToDelete, setFilesToDelete] = useState<number[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (departmentId.current > 0) {
      setLoading(true);
      if (departmentId.changed && departmentId.previous > 0) {
        setFiles([]);
        setEmployees([]);
        if (isNew) {
          setModel({} as any);
          setOriginalModel({} as any);
        } else {
          setOriginalModel(model);
          setTimeout(() => {
            props.history.push("/credentials");
          }, 200);
          return; //exit
        }
      }
      states.getData();
      setIsNew(!id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  const [takenDates, setTakenDates] = useState<{
    dateIssued: Date;
    expirationDate: Date;
    dueDate: Date;
  }>();
  const currentData = useNfirsGetData(
    async () => ApsServices.http.credCredential.getTakenCredentialForEdit(id),
    "Existing Credential"
  );

  const emptyCred = useNfirsGetData(
    async () => ApsServices.http.credCredential.getEmptyCredential(credId),
    "Existing Credential"
  );

  const states = useNfirsGetData<ApsModels.ILookupStatesOutputDto[]>(
    async () => {
      return await ApsServices.http.genericLookupService.getStates(1);
    },
    "States"
  );

  useEffect(() => {
    if (
      currentData.status === NfirsFetchStatus.Failed &&
      currentData.errorCode === 404
    ) {
      props.history.push(`/credentials`);
      return;
    }
    if (currentData.status === NfirsFetchStatus.Complete && currentData.data) {
      const newData = {
        ...currentData.data,
        name: currentData.data?.credentialName as any,
        code: currentData.data?.credentialCode as any,
        hours: currentData.data?.hoursTaken as any,
        //period: currentData.data?.expiresInYears as any,
        lookupStateId: currentData.data?.lookupStateId as any,
        renewalDate: currentData.data.expirationDate,
        // dateExpirationString: currentData.data.expirationDate
        //   ? commonService.checkDateValue(currentData.data.expirationDate)
        //   : (null as any),
        // dateIssueString: currentData.data.dateIssued
        //   ? commonService.toStartOfDayDateString(currentData.data.dateIssued)
        //   : (null as any),
      };
      setModel((p) => {
        return {
          ...p,
          ...newData,
        };
      });
      setOriginalModel((p) => {
        return {
          ...p,
          ...newData,
        };
      });
      setValuesFromModel({
        ...currentData.data,
        ...newData,
        period: currentData.data?.expiresInYears || (null as any),
        hours: currentData.data?.totalHoursNeeded || (null as any),
      });

      emptyCred.setData({
        expiresInYears: currentData.data?.expiresInYears || (null as any),
        totalHoursNeeded: currentData.data?.totalHoursNeeded || (null as any),
      } as any);

      setLoading(false);

      setTakenDates({
        dateIssued: currentData.data.dateIssued,
        expirationDate: currentData.data.expirationDate,
        dueDate: currentData.data.dueDate,
      });
      updateDateKeys();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData.status]);

  useEffect(() => {
    if (states.status === NfirsFetchStatus.Complete && states.data) {
      if (id && !isNew) {
        currentData.getData();
      }

      if (credId) {
        emptyCred.getData();
      } else {
        setLoading(false);
        setTimeout(() => {
          (
            document.querySelectorAll(
              "#typeaheadSearchCredentialContainer .rbt-input-main"
            )[0] as any
          )?.focus();
        }, 500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states.status, credId]);

  useEffect(() => {
    if (
      emptyCred.status === NfirsFetchStatus.Failed &&
      emptyCred.errorCode === 404
    ) {
      props.history.push(`/credentials`);
      return;
    }
    if (emptyCred.status === NfirsFetchStatus.Complete && emptyCred.data) {
      const newData = {
        ...emptyCred.data,
        hours: emptyCred.data?.totalHoursNeeded as any,
        period: emptyCred.data?.expiresInYears as any,
        lookupStateId: emptyCred.data?.lookupStateId as any,
      };
      setModel((p) => {
        return {
          ...p,
          ...newData,
        };
      });
      setOriginalModel((p) => {
        return {
          ...p,
          ...newData,
        };
      });
      setValuesFromModel({
        ...emptyCred.data,
        ...newData,
      });
      setLoading(false);
      setEmployees((emps) => {
        return [
          ...(emptyCred?.data?.credPeopleInCredentialDtos?.map((e) => {
            return {
              id: e.userId,
              name: e.personName,
            };
          }) || []),
        ];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emptyCred.status]);

  const processNewData = (data: any) => {
    return {
      ...data,
      lookupStateId: data.lookupStateId || (null as any),
      // dateIssueString: commonService.checkDateValue(data.dateIssueString),
      // dateExpirationString: commonService.checkDateValue(
      //   data.dateExpirationString
      // ),
    };
  };

  const submit = async (
    data: ApsModels.ICredExistingCredentialOutputDto,
    preventCallAction = false
  ) => {
    if (preventCallAction) {
      return;
    }
    let action = Promise.resolve({});

    const userIds = isNew
      ? [...(employees.map((e) => e.id) || [])]
      : currentData.data?.userId
      ? [currentData.data?.userId]
      : [];

    action = ApsServices.http.credCredential.adminAddTakenCredential({
      credCredentialId: credId,
      ...processNewData({
        ...data,
        ...model,
        userIds: userIds,
        id: isNew ? (null as any) : (currentData?.data?.id as any),
        concurrencyToken: isNew
          ? (null as any)
          : (currentData?.data?.concurrencyToken as any),
        lookupStateId: model.lookupStateId as any,
        credCredentialId: isNew
          ? (credId as any)
          : (currentData?.data?.credCredentialId as any),
        identificationNumber: model.identificationNumber,

        // userIds: [...(employees.map((e) => e.id) || [])],
        // userIdsToRemove: [
        //   ...(emptyCred.data?.credPeopleInCredentialDtos
        //     ?.filter((e) => !employees.find((ee) => ee.id === e.userId))
        //     ?.map((e) => e.userId) || []),
        // ],
      }),
      dateIssued: takenDates?.dateIssued,
      renewalDate: takenDates?.expirationDate,
    });

    // if (isNew) {
    //   if (canAddEmployees()) {
    //     action = ApsServices.http.credCredential.adminAddTakenCredential({
    //       credCredentialId: credId,
    //       ...processNewData({
    //         ...data,
    //         ...model,
    //         userIds: [...(employees.map((e) => e.id) || [])],
    //         userIdsToRemove: [
    //           ...(emptyCred.data?.credPeopleInCredentialDtos
    //             ?.filter((e) => !employees.find((ee) => ee.id === e.userId))
    //             ?.map((e) => e.userId) || []),
    //         ],
    //       }),
    //       dateIssued: takenDates?.dateIssued,
    //       renewalDate: takenDates?.expirationDate,
    //     });
    //   } else {
    //     debugger;
    //     //TODO
    //     // action = ApsServices.http.credCredential.saveOwnTakeCredential(
    //     //   files,
    //     //   processNewData({
    //     //     ...data,
    //     //     ...model,
    //     //   })
    //     // );
    //   }
    // } else {
    //   if (canAddEmployees()) {
    //     action = ApsServices.http.credCredential.adminUpdateExistingCredential(
    //       files,
    //       processNewData({
    //         ...data,
    //         ...model,
    //         idOfFilesToDelete: filesToDelete,
    //         userId: Number((currentData.data?.userId as any) || 0),
    //       })
    //     );
    //   } else {
    //     action = ApsServices.http.credCredential.updateOwnExistingCredential(
    //       files,
    //       processNewData({
    //         ...data,
    //         ...model,
    //         idOfFilesToDelete: filesToDelete,
    //       })
    //     );
    //   }
    // }

    setSaving(true);
    await action
      .then((rtn) => {
        setSaving(false);
        setFiles([]);
        setEmployees([]);
        setOriginalModel({ ...model });
        toastStore.showToast("Credential Submission Successful", "success");
        navigateBack();
      })
      .catch((err) => {
        setSaving(false);
        const errCode = commonService.getErrorStatusCode(err);
        if (errCode === 409 || errCode === 404) {
          setErrorCode(errCode);
        } else {
          toastStore.showError("Failed Saving Credential.", err);
        }
      });
  };

  const [confirmUnsaved, setConfirmUnsaved] = useState(false);
  const hasChanges = () => {
    return (
      !commonService.isEqual(model, orignalModel) ||
      files.length > 0 ||
      employees.length > 0
    );
  };

  const [claims] = useState(commonService.friendlyClaims);
  const [users, setUsers] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const [employees, setEmployees] = useState<
    {
      name: string;
      id: number;
    }[]
  >([]);
  const ref = React.createRef<AsyncTypeahead<any>>();

  const [openEmpDropdown, setOpenEmpDropdown] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);
  // const handleSearch = async (query: string) => {
  //   if ((query || "").trim() === "") {
  //     setUsers([]);
  //     setIsLoading(false);
  //     return;
  //   }

  //   setIsLoading(true);
  //   await ApsServices.http.credCommon
  //     .credentialsTypeAheadUser({
  //       search: query,
  //       isAnd: false,
  //       recordCount: 10,
  //       shiftId: null as any,
  //       rankId: selectedRank || (null as any),
  //     })
  //     .then((items) => {
  //       const options = items.map((i) => ({
  //         id: i.id,
  //         name: `${i.firstName} ${i.lastName}`,
  //         firstName: i.firstName,
  //         lastName: i.lastName,
  //       }));

  //       setUsers(options);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       setUsers([]);
  //       setIsLoading(false);
  //     });
  // };

  const canAddEmployees = () => {
    return claims.credentials.canAddAll;
  };

  const [creds, setCreds] = useState([] as any[]);
  const [isLoadingCreds, setIsLoadingCreds] = useState(false);

  const refCreds = React.createRef<AsyncTypeahead<any>>();

  const handleSearchCreds = async (query: string) => {
    if ((query || "").trim() === "") {
      setCreds([]);
      setIsLoadingCreds(false);
      return;
    }

    setIsLoadingCreds(true);
    await ApsServices.http.credCredential
      .typeAheadCredential({
        search: query,
        isAnd: false,
        recordCount: 10,
        libraryTypeEnum: libType,
        categoryId: null as any,
        onlyRenewal: false,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          displayName: `${i.category} - ${i.name} (${i.code})`,
          name: `${i.name}`,
          code: `${i.code}`,
        }));

        setCreds(options);
        setIsLoadingCreds(false);
      })
      .catch((err) => {
        setCreds([]);
        setIsLoadingCreds(false);
      });
  };

  const navigateBack = () => {
    const doneUrl = isNew
      ? commonService.doneUrls.created
      : commonService.doneUrls.submitted;

    if (props.history.action != "POP") {
      commonService.routeGoBack(props, doneUrl);
    } else {
      if (claims.credentials.canViewAll && currentData.data?.userId) {
        props.history.push(
          `/credentials/employee/${currentData.data?.userId}` + doneUrl
        );
      } else {
        props.history.push("/credentials" + doneUrl);
      }
    }
  };

  const assignCred = async (data: any, preventCallAction = false) => {
    if (preventCallAction) {
      return;
    }
    const userIds = isNew
      ? [...(employees.map((e) => e.id) || [])]
      : currentData.data?.userId
      ? [currentData.data?.userId]
      : [];
    setAssigning(true);
    await ApsServices.http.credCredential
      .adminAssignTakenCredential({
        userIds: userIds,
        id: isNew ? (null as any) : (currentData?.data?.id as any),
        concurrencyToken: isNew
          ? (null as any)
          : (currentData?.data?.concurrencyToken as any),
        lookupStateId: model.lookupStateId as any,
        credCredentialId: isNew
          ? (credId as any)
          : (currentData?.data?.credCredentialId as any),
        identificationNumber: model.identificationNumber,
        dateIssued: model?.dateIssued as any,
        renewalDate: model?.renewalDate as any,
        dueDate: model?.dueDate as any,
      })
      .then((rtn) => {
        setAssigning(false);
        setFiles([]);
        setEmployees([]);
        setOriginalModel({ ...model });
        toastStore.showToast("Credential Assignment Successful", "success");
        navigateBack();
      })
      .catch((err) => {
        setAssigning(false);
        const errCode = commonService.getErrorStatusCode(err);
        if (errCode === 409 || errCode === 404) {
          setErrorCode(errCode);
        } else {
          toastStore.showError("Failed Assigning Credential.", err);
        }
      });
  };

  const [askDel, setAskDel] = useState(false);
  const deleteCredential = async () => {
    if (currentData.data) {
      await ApsServices.http.credCredential
        .deletePersonFromCredential(
          currentData.data.userId,
          currentData.data.id
        )
        .then((data) => {
          props.history.push(`/credentials` + commonService.doneUrls.deleted);
          toastStore.showToast("Credential Deleted", "success");
        })
        .catch((err) => {
          toastStore.showError("Failed Deleting Credential.", err);
        });
    }
  };

  const expiresInYears = useMemo(() => {
    return emptyCred.data?.expiresInYears || 0;
  }, [emptyCred.data]);

  const showRenew = () => {
    return (
      commonService.friendlyClaims.credentials.canAccess &&
      !!currentData.data &&
      currentData.data.status === ApsModels.CredStatusEnum.InProgress &&
      //currentData.data.percentageComplete >= 100 &&
      expiresInYears > 0
    );
  };

  const doRenew = async () => {
    if (!model.dateIssued && !model.renewalDate) {
      toastStore.showToast(
        `Credential Renewal Unsuccessful
        <br/><br/>Please enter an effective date and expiration date`,
        "warning"
      );
      return;
    }
    if (model.dateIssued && !model.renewalDate) {
      toastStore.showToast(
        `Credential Submission Unsuccessful
        <br/><br/>Please enter an expiration date`,
        "warning"
      );
      return;
    }
    if (!model.dateIssued && model.renewalDate) {
      toastStore.showToast("Effective Date is required", "warning");
      return;
    }

    let isAdmin = false;
    if (
      commonService.userInfo &&
      (commonService.userInfo.isAdmin ||
        commonService.friendlyClaims.credentials.canAddAll)
    ) {
      isAdmin = true;
    }

    setRenewing(true);
    await (isAdmin
      ? ApsServices.http.credCredential.renewAdmin({
          takenCredentialId: id,
          dateIssued: model.dateIssued,
          expirationDate: model.renewalDate,
        })
      : ApsServices.http.credCredential.renewOwn({
          takenCredentialId: id,
          dateIssued: model.dateIssued,
          expirationDate: model.renewalDate,
        })
    )
      .then((data) => {
        setAssigning(false);
        setFiles([]);
        setEmployees([]);
        setOriginalModel({ ...model });
        toastStore.showToast(
          `Credential Renewal Successful
          <br/><br/>
          An assignment has also been created with an assignment due date reflecting the upcoming expiration date. `,
          "success"
        );
        navigateBack();
      })
      .catch((err) => {
        toastStore.showError("Failed Renewing Credential", err);
      })
      .finally(() => {
        setRenewing(false);
      });
  };

  //This Keys will be used to force the datepickers to re-render
  //after setting new values
  const [dateKeys, setDateKeys] = useState({
    dueDate: commonService.getUniqueId(),
    dateIssued: commonService.getUniqueId(),
    expirationDate: commonService.getUniqueId(),
  });
  const updateDateKeys = (dates?: {
    dueDate?: string;
    dateIssued?: string;
    expirationDate?: string;
  }) => {
    if (dates) {
      setDateKeys((prev) => {
        return {
          ...prev,
          ...dates,
        };
      });
    } else {
      setDateKeys({
        dueDate: commonService.getUniqueId(),
        dateIssued: commonService.getUniqueId(),
        expirationDate: commonService.getUniqueId(),
      });
    }
  };

  return (
    <>
      <RouteChangedGuard
        when={true}
        navigate={(path) => props.history.push(path)}
        shouldBlockNavigation={(location) => {
          return hasChanges();
        }}
        message="You have unsaved changes, do you really want to leave the page and discard changes?"
      />
      <ConfirmDialog
        show={!!errorCode}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(errorCode)}
        message={commonService.getConcurrencyMessage(errorCode)}
        done={(rtn) => {
          if (errorCode === 409) {
            setErrorCode(0);
            setLoading(true);
            currentData.getData();
          } else {
            props.history.push(`/credentials`);
          }
        }}
      />
      <ConfirmDialog
        show={confirmUnsaved}
        buttons="yesno"
        title="Confirmation"
        message="You have unsaved changes, do you really want to leave the page and discard changes?"
        done={(rtn) => {
          setConfirmUnsaved(false);
          if (rtn === "yes") {
            props.history.push(`/credentials`);
          }
        }}
      />
      <ConfirmDialog
        show={deleteAll}
        buttons="yesno"
        title="Confirmation"
        message="Do you reall want to delete all employees selected?"
        done={(rtn) => {
          if (rtn === "yes") {
            setEmployees([]);
          }
          setDeleteAll(false);
        }}
      />
      <ConfirmDialog
        show={askDel}
        buttons="yesno"
        title="Confirmation"
        message="Do you really want to delete this Credential?"
        done={(rtn) => {
          setConfirmUnsaved(false);
          if (rtn === "yes") {
            deleteCredential();
          }
          setAskDel(false);
        }}
      />

      <form
        id="credform"
        className={`flex flex-1 flex-col ${loading ? "display-none" : ""}`}
        onSubmit={(e) => {
          clearErrors();

          let preventCallAction = false;
          if (GetSubmittedFrom() === "submit") {
            // if (model.dueDate) {
            //   toastStore.showToast(
            //     `Credential Assignment Unsuccessful
            //     <br/><br/>
            //     To proceed, please ensure you select the 'Assign' button instead of 'Submit'.`,
            //     "warning"
            //   );
            //   preventCallAction = true;
            // } else
            if (
              moment(model.dateIssued)
                .startOf("day")
                .isAfter(moment().startOf("day"))
            ) {
              //APS120-11 - #5
              // toastStore.showToast(
              //   `Unable to Submit Credential
              //   <br/><br/>
              //   Please choose a date on or before today as the Effective Date.`,
              //   "warning"
              // );

              //APS120-12 - #14
              // toastStore.showToast(
              //   `Credential Submission Unsuccessful
              //   <br/><br/>
              //   Please choose an Effective Date in the past unless you wish to enter an Assignment Due Date.`,
              //   "warning"
              // );
              // preventCallAction = true;

              // e.preventDefault();
              // e.stopPropagation();
              // return;
            }

            handleSubmit((data) => submit(data, preventCallAction))(e);
          } else {
            if (!model.dueDate) {
              toastStore.showToast(
                `Credential Submission Unsuccessful
                <br/><br/>
                To proceed, please ensure you select the 'Submit' button instead of 'Assign'.`,
                "warning"
              );
              preventCallAction = true;
              // e.preventDefault();
              // e.stopPropagation();
              // return;
            } else if (
              moment(model.dueDate)
                .startOf("day")
                .isBefore(moment().add(1, "day").startOf("day"))
            ) {
              toastStore.showToast(
                `Credential Assignment Unsuccessful
                <br/><br/> Please choose an Assignment Due Date in the future, as it must be after today.`,
                "warning"
              );
              preventCallAction = true;
              // e.preventDefault();
              // e.stopPropagation();
              // return;
            } else if (model.dateIssued) {
              if (
                moment(model.dateIssued)
                  .startOf("day")
                  .isBefore(moment().add(1, "day").startOf("day"))
              ) {
                toastStore.showToast(
                  `Credential Submission Unsuccessful
                  <br/><br/>
                  To proceed, please ensure you select the 'Submit' button instead of 'Assign'.`,
                  "warning"
                );
                preventCallAction = true;
              } else {
                // toastStore.showToast(
                //   `Credential Submission Unsuccessful
                //   <br/><br/>
                //   Please choose an Effective Date in the past unless you wish to enter an Assignment Due Date.`,
                //   "warning"
                // );
                // preventCallAction = true;
              }
              // e.preventDefault();
              // e.stopPropagation();
              // return;
            }

            handleSubmit((data) => assignCred(data, preventCallAction))(e);
          }
          //console.log(formState.errors);
        }}
      >
        {(loading || currentData.status === NfirsFetchStatus.InProgress) && (
          <CommonSpinner overlay={true}></CommonSpinner>
        )}

        <div className="container-fluid flex-card-container">
          <div className="flex-0">
            <div className="headerControls">
              <div>
                <span className="h4 mb-0 font-size-18 text-uppercase">
                  {isNew ? "Add" : "Edit"} Credential
                </span>
              </div>
              <div>
                {!isNew && (
                  <button
                    className="btn btn-outline-danger"
                    type="button"
                    disabled={saving || assigning || renewing}
                    onClick={(e) => {
                      setAskDel(true);
                    }}
                  >
                    Delete
                  </button>
                )}

                <button
                  className="btn btn-secondary"
                  type="button"
                  disabled={saving || assigning || renewing}
                  onClick={(e) => {
                    navigateBack();
                  }}
                >
                  Cancel and Close
                </button>

                {showRenew() && (
                  <button
                    name="submit"
                    className="btn btn-purple"
                    type="button"
                    disabled={saving || assigning || renewing}
                    onClick={(e) => {
                      doRenew();
                    }}
                  >
                    {renewing ? "Renewing..." : "Renew"}
                  </button>
                )}

                <button
                  name="submit"
                  className="btn btn-primary"
                  type="submit"
                  disabled={saving || assigning || renewing}
                  onClick={(e) => {
                    document
                      .getElementById("credform")
                      ?.setAttribute("submittedfrom", "submit");
                  }}
                  style={{
                    float: "right",
                  }}
                >
                  {saving ? "Saving..." : "Submit"}
                </button>

                <button
                  name="assign"
                  className="btn btn-success"
                  type="submit"
                  disabled={saving || assigning || renewing}
                  onClick={(e) => {
                    document
                      .getElementById("credform")
                      ?.setAttribute("submittedfrom", "assign");
                  }}
                >
                  {assigning ? "Assigning..." : "Assign"}
                </button>
              </div>
            </div>
          </div>
          <div className="row h-full flex-1">
            <div className="col-12 col-sm-12 col-lg-4 flex flex-col">
              <div className="card">
                <div className="card-body flex flex-col">
                  <div className="pb-3">
                    <strong>1. Credential Information</strong>
                  </div>
                  {isNew && (
                    <>
                      <div className="pt-2">
                        <label>Credential Library</label>
                        <select
                          className="form-control"
                          onChange={(e) => {
                            setLibType(Number(e.target.value) || 0);
                          }}
                        >
                          {ListCredLibraryTypes.map((i) => (
                            <option key={i.value} value={i.value}>
                              {i.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        className="pt-3 mb-2"
                        id="typeaheadSearchCredentialContainer"
                      >
                        <label>Search Existing Credential</label>
                        <AsyncTypeahead
                          id="typeaheadSearchCredential"
                          labelKey="displayName"
                          onSearch={handleSearchCreds}
                          onChange={(data) => {
                            if (data && data.length > 0) {
                              setValue("name", data[0].name);
                              setValue("identificationNumber", data[0].code);
                              (refCreds.current as any)?.clear();
                              setCredId(data[0].id);
                            }
                          }}
                          searchText={"Searching..."}
                          isLoading={isLoadingCreds}
                          options={creds}
                          placeholder="Search Existing Credential"
                          minLength={1}
                          delay={500}
                          useCache={false}
                          ref={refCreds}
                        />
                      </div>
                      <hr />
                    </>
                  )}
                  <div className="pt-2">
                    <label>Credential Category</label>
                    {isNew ? (
                      <select
                        className="form-control mb-3"
                        value={
                          emptyCred.data?.credCredentialCategoryOutputDto.id
                        }
                        onChange={(e) => {
                          //Do Nothing...
                        }}
                        disabled={true}
                      >
                        <option>Select Category</option>
                        {categories?.data?.map((i) => (
                          <option key={i.id} value={i.id}>
                            {i.name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        className="form-control mb-3"
                        value={currentData.data?.category || ""}
                        onChange={(e) => {}}
                        readOnly={true}
                        style={{ backgroundColor: "rgb(238, 238, 238)" }}
                      />
                    )}

                    <FgInput
                      id="name"
                      label="Credential Name"
                      placeHolder="Credential Name"
                      onChange={(data) => {
                        setModel((p) => {
                          return {
                            ...p,
                            name: data,
                          } as any;
                        });
                      }}
                      registeredField={registry.name}
                      formState={formState}
                      readOnly={true} //readOnly={!isNew}
                      //showRequiredLabel={isNew}
                    />

                    <FgInput
                      id="code"
                      label="Credential Code"
                      placeHolder="Credential Code"
                      registeredField={registry.code}
                      formState={formState}
                      onChange={(data) => {
                        setModel((p: any) => {
                          return {
                            ...p,
                            //TODO: SET VALUE
                          };
                        });
                      }}
                      readOnly={true} //readOnly={!isNew}
                      //showRequiredLabel={isNew}
                    ></FgInput>

                    <FgInput
                      id="hours"
                      label="Credential Hours"
                      placeHolder="Credential Hours"
                      registeredField={registry.hours}
                      formState={formState}
                      onChange={(data) => {
                        setModel((p: any) => {
                          return {
                            ...p,
                            hours: commonService.getNumber(data),
                          };
                        });
                      }}
                      readOnly={true} //readOnly={!isNew}
                      //showRequiredLabel={isNew}
                    ></FgInput>

                    <label className="mb-1">
                      Credential Period of Validity in Years
                    </label>
                    <FgInput
                      id="period"
                      label="(enter zero for credentials that do not expire)"
                      placeHolder="Credential Period of Validity"
                      registeredField={registry.period}
                      formState={formState}
                      onChange={(data) => {
                        setModel((p: any) => {
                          return {
                            ...p,
                            //TODO: SET VALUE
                          };
                        });
                      }}
                      readOnly={true} //readOnly={!isNew}
                      //showRequiredLabel={isNew}
                    ></FgInput>
                  </div>
                </div>
              </div>
            </div>

            {canAddEmployees() && (
              <div className="col-12 col-sm-12 col-lg-4 flex flex-col">
                <div className="card">
                  <div className="card-body flex flex-col">
                    <div className="pb-3">
                      <strong>2. Credential Assignment or Completion</strong>
                    </div>
                    <div className="pt-2">
                      {isNew && (
                        <>
                          <label className="required-label">
                            Search for Participants
                          </label>

                          <FgSelect
                            id="_rank"
                            noPlaceholder={true}
                            groups={[
                              { id: 0, label: "By Employee" },
                              { id: -1, label: "By Group" },
                            ]}
                            options={[
                              {
                                label: "Employees",
                                value: 0,
                                groupId: 0,
                              },
                              ...(groups.data || []).map((cat) => {
                                return {
                                  label: cat.name,
                                  value: cat.groupId,
                                  groupId: -1,
                                };
                              }),
                            ]}
                            disabledOptionValues={[-1]}
                            onChange={(data) => {
                              setSelectedGroup(commonService.getNumber(data));
                            }}
                            formState={formState}
                            disabled={!isNew}
                          />

                          <div className="text-primary mt-1 px-1 flex">
                            <div className="flex-1">
                              <span
                                className="pointer"
                                onClick={(e) => {
                                  const emps = users
                                    .filter(
                                      (emp) =>
                                        !employees.find((i) => i.id === emp.id)
                                    )
                                    .map((i) => {
                                      return {
                                        id: i.id,
                                        name: i.name,
                                      };
                                    });
                                  setEmployees((list) => {
                                    return [...list, ...emps];
                                  });
                                  if (emps.length > 0) {
                                    toastStore.showToast(
                                      emps.length === 1
                                        ? "1 Employee Added"
                                        : `${emps.length} Employees Added`,
                                      "success"
                                    );
                                  } else {
                                    toastStore.showToast(
                                      "No Employee(s) Added",
                                      "warning"
                                    );
                                  }
                                }}
                              >
                                {selectedGroup
                                  ? `Add All ${
                                      (groups?.data || []).find(
                                        (x) => x.groupId === selectedGroup
                                      )?.name || ""
                                    }`
                                  : "Add All Employees"}
                              </span>
                            </div>
                            <div>
                              {employees.length > 0 && (
                                <span
                                  className="text-danger pointer"
                                  onClick={(e) => {
                                    setDeleteAll(true);
                                  }}
                                >
                                  Delete All
                                </span>
                              )}
                            </div>
                          </div>

                          <AsyncTypeahead
                            id="typeaheadAssignEmployees"
                            labelKey="name"
                            onSearch={() => {}} //handleSearch
                            open={openEmpDropdown}
                            onFocus={(e) => setOpenEmpDropdown(true)}
                            onBlur={(e) => setOpenEmpDropdown(false)}
                            onChange={(data) => {
                              if (data && data.length > 0) {
                                if (
                                  employees.find((e) => e.id === data[0].id)
                                ) {
                                  toastStore.showToast(
                                    `${data[0].name} is already in the list.`,
                                    "warning"
                                  );
                                } else {
                                  const prts = [...employees];
                                  prts.push({
                                    id: data[0].id,
                                    name: data[0].name,
                                  });
                                  setEmployees(prts);
                                }

                                //setUsers(data);
                                (ref.current as any)?.clear();
                                ref.current?.blur();
                              }
                            }}
                            searchText={"Searching..."}
                            isLoading={isLoading}
                            options={isLoading ? [] : users}
                            placeholder="Add or Search Employee"
                            promptText={
                              isLoading ? "Loading..." : "No Records Found"
                            }
                            minLength={1}
                            delay={500}
                            useCache={false}
                            ref={ref}
                          />
                          <FormError
                            formState={formState}
                            field={registry.employeeName.name}
                          ></FormError>
                          <div className="currentParticipants mt-3 mb-3">
                            {employees
                              ?.sort(commonService.sortByStringProperty("name"))
                              ?.map((p, i) => {
                                return (
                                  <div key={i}>
                                    <section>{`${p.name}`}</section>
                                    <section
                                      title="Remove"
                                      onClick={() => {
                                        const prts = [...employees];
                                        prts.splice(i, 1);
                                        setEmployees(prts);
                                      }}
                                    >
                                      <i className="fa fa-times"></i>
                                    </section>
                                  </div>
                                );
                              })}
                          </div>
                        </>
                      )}

                      {!isNew && currentData.data && (
                        <div className="mb-3">
                          <label>Participant</label>
                          <input
                            className="form-control"
                            value={currentData.data.employeeName}
                            onChange={(e) => {}}
                            readOnly={true}
                            style={{ backgroundColor: "rgb(238, 238, 238)" }}
                          />
                        </div>
                      )}

                      <FgInput
                        id="identificationNumber"
                        label="Identification Number"
                        placeHolder="Credential Identification Number"
                        registeredField={registry.identificationNumber}
                        formState={formState}
                        onChange={(data) => {
                          setModel((p: any) => {
                            return {
                              ...p,
                              identificationNumber: data,
                            };
                          });
                        }}
                        //readOnly={!isNew}
                        //showRequiredLabel={isNew}
                      ></FgInput>

                      <FgSelect
                        id="lookupStateId"
                        label="State Issued"
                        showRequiredLabel={true}
                        selectMessage="- Select State -"
                        options={(states.data || []).map((cat) => {
                          return {
                            label: cat.description,
                            value: cat.id,
                          };
                        })}
                        onChange={(data) => {
                          setModel((p) => {
                            return {
                              ...p,
                              lookupStateId: commonService.getNumber(data),
                            } as any;
                          });
                        }}
                        registeredField={registry.lookupStateId}
                        formState={formState}
                        //disabled={!isNew}
                      />

                      {!loading && (
                        <>
                          <div className="mb-3">
                            <label className="required-label-x">
                              Assignment Due Date
                            </label>
                            <DateTimePickerV2
                              key={dateKeys.dueDate}
                              data={takenDates?.dueDate || null}
                              onChange={(data) => {
                                if (!data) {
                                  setValue("dueDate", null);
                                  setTakenDates((prev) => {
                                    return {
                                      ...(prev || ({} as any)),
                                      dueDate: null,
                                    };
                                  });
                                  setModel((p: any) => {
                                    return {
                                      ...p,
                                      dueDate: null,
                                      dueDateString: null,
                                    };
                                  });
                                  return;
                                }

                                setModel((p: any) => {
                                  return {
                                    ...p,
                                    dueDate:
                                      commonService.toStartOfDayDateString(
                                        data
                                      ),
                                    dueDateString:
                                      commonService.toStartOfDayDateString(
                                        data
                                      ),
                                  };
                                });
                                setValue(
                                  "dueDate",
                                  commonService.toStartOfDayDateString(data)
                                );
                              }}
                              dateOnly={true}
                              className="flex-1"
                            ></DateTimePickerV2>

                            <FormError
                              formState={formState}
                              field={registry.dueDate.name}
                            ></FormError>
                          </div>

                          <div className="mb-3">
                            <label>Effective Date</label>
                            <DateTimePickerV2
                              key={dateKeys.dateIssued}
                              data={takenDates?.dateIssued || null}
                              onChange={(data) => {
                                if (!data) {
                                  setValue("dateIssued", null);
                                  setTakenDates((prev) => {
                                    return {
                                      ...(prev || ({} as any)),
                                      dateIssued: null,
                                    };
                                  });
                                  setModel((p: any) => {
                                    return {
                                      ...p,
                                      dateIssued: null,
                                    };
                                  });
                                  return;
                                }

                                const setTheOtherData =
                                  !!expiresInYears && !model.renewalDate;

                                const adjustedDate = moment(data)
                                  .add(expiresInYears, "year")
                                  .format("YYYY-MM-DD");

                                setModel((p: any) => {
                                  return {
                                    ...p,
                                    dateIssued:
                                      commonService.toStartOfDayDateString(
                                        data
                                      ),
                                    ...(setTheOtherData
                                      ? {
                                          renewalDate:
                                            commonService.toStartOfDayDateString(
                                              adjustedDate
                                            ),
                                        }
                                      : {}),
                                  };
                                });
                                setValue(
                                  "dateIssued",
                                  commonService.toStartOfDayDateString(data)
                                );

                                if (setTheOtherData) {
                                  setValue(
                                    "renewalDate",
                                    commonService.toStartOfDayDateString(
                                      adjustedDate
                                    )
                                  );
                                  setTakenDates((prev) => {
                                    return {
                                      ...(prev || ({} as any)),
                                      dateIssued: data,
                                      expirationDate: adjustedDate,
                                    };
                                  });
                                  updateDateKeys({
                                    expirationDate: commonService.getUniqueId(),
                                  });
                                } else {
                                  setTakenDates((prev) => {
                                    return {
                                      ...(prev || ({} as any)),
                                      dateIssued: data,
                                    };
                                  });
                                }
                              }}
                              dateOnly={true}
                              className="flex-1"
                            ></DateTimePickerV2>

                            <FormError
                              formState={formState}
                              field={registry.dateIssued.name}
                            ></FormError>
                          </div>

                          {expiresInYears > 0 && (
                            <div className="mb-3">
                              <label>Expiration Date</label>
                              <DateTimePickerV2
                                key={dateKeys.expirationDate}
                                data={takenDates?.expirationDate || null}
                                onChange={(data) => {
                                  if (!data) {
                                    setValue("renewalDate", null);
                                    setTakenDates((prev) => {
                                      return {
                                        ...(prev || ({} as any)),
                                        expirationDate: null,
                                      };
                                    });
                                    setModel((p: any) => {
                                      return {
                                        ...p,
                                        renewalDate: null,
                                        //dateExpirationString: null,
                                      };
                                    });
                                    return;
                                  }

                                  const setTheOtherData =
                                    !!expiresInYears && !model.dateIssued;

                                  const adjustedDate = moment(data)
                                    .add(expiresInYears * -1, "year")
                                    .format("YYYY-MM-DD");
                                  setModel((p: any) => {
                                    return {
                                      ...p,
                                      ...(setTheOtherData
                                        ? {
                                            dateIssued:
                                              commonService.toStartOfDayDateString(
                                                adjustedDate
                                              ),
                                          }
                                        : {}),
                                      renewalDate:
                                        commonService.toStartOfDayDateString(
                                          data
                                        ),
                                    };
                                  });
                                  setValue(
                                    "renewalDate",
                                    commonService.toStartOfDayDateString(data)
                                  );

                                  if (setTheOtherData) {
                                    setValue(
                                      "dateIssued",
                                      commonService.toStartOfDayDateString(
                                        adjustedDate
                                      )
                                    );
                                    setTakenDates((prev) => {
                                      return {
                                        ...(prev || ({} as any)),
                                        dateIssued: adjustedDate,
                                        expirationDate: data,
                                      };
                                    });
                                    updateDateKeys({
                                      dateIssued: commonService.getUniqueId(),
                                    });
                                  } else {
                                    setTakenDates((prev) => {
                                      return {
                                        ...(prev || ({} as any)),
                                        expirationDate: data,
                                      };
                                    });
                                  }
                                }}
                                dateOnly={true}
                                className="flex-1"
                              ></DateTimePickerV2>

                              <FormError
                                formState={formState}
                                field={registry.renewalDate.name}
                              ></FormError>
                            </div>
                          )}
                        </>
                      )}

                      {/* <div className="pt-2">
                        <div className="mb-3">
                          <label>Attachment(s)</label>
                          <div>
                            <FileDragNDrop
                              label="Add File"
                              disabled={saving}
                              onChange={(fileList) => {
                                if (fileList.length > 0) {
                                  const list = [...files];
                                  let cnt = 0;
                                  while (cnt < fileList.length) {
                                    const file = fileList[cnt];
                                    list.push(file);
                                    cnt++;
                                  }
                                  setFiles(list);
                                }
                              }}
                            />
                          </div>
                          <div>
                            {currentData.data?.certificateFiles?.map((cf) => (
                              <span
                                key={cf.id}
                                className="alert alert-secondary m-0 mt-2 flex flex-center"
                              >
                                <i
                                  title="Download File"
                                  className="mr-2 fa fa-download text-primary pointer"
                                  onClick={() => {
                                    if (currentData.data?.id) {
                                      ApsServices.http.credCredential
                                        .downloadTakenCredentialCertificate(
                                          currentData.data.id,
                                          cf.id
                                        )
                                        .then((data) => {
                                          const file = commonService.b64toBlob(
                                            data.fileContents,
                                            "text/plain"
                                          );
                                          FileSaver.saveAs(
                                            file,
                                            data.fileDownloadName
                                          );
                                        })
                                        .catch((error) => {
                                          toastStore.showError(
                                            "Failed to Download File",
                                            error
                                          );
                                        });
                                    }
                                  }}
                                ></i>
                                <span className="flex-1 overflow-hidden">
                                  {filesToDelete.includes(cf.id) ? (
                                    <s className="text-danger">
                                      <i>{cf.fileName}</i>
                                    </s>
                                  ) : (
                                    <span>{cf.fileName}</span>
                                  )}
                                </span>
                                {filesToDelete.includes(cf.id) ? (
                                  <i
                                    title="Undo"
                                    className="ml-2 fa fa-undo text-primary pointer"
                                    onClick={() => {
                                      setFilesToDelete(
                                        filesToDelete.filter((f) => f !== cf.id)
                                      );
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    title="Delete File"
                                    className="ml-2 fa fa-trash text-danger pointer"
                                    onClick={() => {
                                      setFilesToDelete([
                                        ...filesToDelete,
                                        cf.id,
                                      ]);
                                    }}
                                  ></i>
                                )}
                              </span>
                            ))}
                            {files?.map((f, i) => (
                              <span
                                key={i}
                                className="alert alert-info p-2 px-3 m-0 mt-2 flex flex-center"
                              >
                                <span className="flex-1 overflow-hidden">
                                  {f.name}
                                </span>
                                {!saving && (
                                  <i
                                    title="Remove File"
                                    className="mr-1 fa fa-times pointer"
                                    onClick={() => {
                                      const list = [...files];
                                      list.splice(i, 1);
                                      setFiles(list);
                                    }}
                                  ></i>
                                )}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default RecordCredential;
