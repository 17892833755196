import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import CustomTabs from "../Common/CustomTabs";
import CredentialListByCredential from "./CredentialListByCredential";
import CredentialListByEmployee from "./CredentialListByEmployee";
import CredentialListByLastModified from "./CredentialListByLastModified";
import ApsModels from "../../models";
import { RouteComponentProps } from "react-router-dom";
import RecordCredential from "./RecordCredential";
import commonService from "../../services/CommonService";

export const GetCredStatusEnumStatusLabel = (
  status: ApsModels.CredStatusEnum
) => {
  if (status === ApsModels.CredStatusEnum.Active) return "Active";
  if (status === ApsModels.CredStatusEnum.InProgress) return "In Progress";
  if (status === ApsModels.CredStatusEnum.Expired) return "Expired";
  if (status === ApsModels.CredStatusEnum.Inactive) return "Inactive";
  return "";
};

export const CredentialsPagePicker = (props: RouteComponentProps) => {
  const id = (props.match?.params as any)?.id;
  if (id === "new" || Number(id)) {
    return <RecordCredential {...props} />;
  }
  if (commonService.friendlyClaims.credentials.canViewAll) {
    return <CredentialList {...props} />;
  }
  return <></>;
};

function CredentialList(props: RouteComponentProps<any>) {
  const tabParam = `${
    props.match?.params?.id || "bylastmodified"
  }`.toLowerCase();

  const [byEmployeeLoaded, setByEmloyeeLoaded] = useState(false);
  const [byCrendetialLoaded, setByCrendetialLoaded] = useState(false);
  const [byModifiedLoaded, setByModifiedLoaded] = useState(false);

  const [activeTab, setActiveTab] = useState<
    "bylastmodified" | "bycredential" | "byemployee"
  >(tabParam as any);

  useEffect(() => {
    props.history.replace(`/credentials/${activeTab}`);
    if (activeTab === "byemployee" && !byEmployeeLoaded) {
      setByEmloyeeLoaded(true);
    }
    if (activeTab === "bycredential" && !byCrendetialLoaded) {
      setByCrendetialLoaded(true);
    }
    if (activeTab === "bylastmodified" && !byModifiedLoaded) {
      setByModifiedLoaded(true);
    }
  }, [activeTab]);

  return (
    <>
      <div
        className="flex flex-1 flex-column credentials-records-list"
        style={{ marginBottom: "-60px", position: "relative" }}
      >
        <div className="container-fluid flex-card-container">
          <div className="flex-0">
            <div className="headerControls">
              <div>
                <span className="h4 mb-0 font-size-18 text-uppercase">
                  Credentials
                </span>
              </div>
              <div></div>
            </div>
          </div>
          <div className="row h-full flex-1">
            <div className="col-12 col-lg-12 flex flex-col">
              <div className="card">
                <div className="card-body flex flex-col p-0 pt-2">
                  <CustomTabs
                    onMenuTabClick={(tab) => {
                      setActiveTab(tab as any);
                    }}
                    activeTab={activeTab}
                  >
                    <Tabs
                      id="credentials-list-page"
                      activeKey={activeTab}
                      onSelect={(tab) => {
                        setActiveTab(tab as any);
                      }}
                    >
                      <Tab eventKey="bylastmodified" title="By Last Modified">
                        {byModifiedLoaded && (
                          <CredentialListByLastModified
                            {...props}
                          ></CredentialListByLastModified>
                        )}
                      </Tab>
                      <Tab eventKey="bycredential" title="By Credential">
                        {byCrendetialLoaded && (
                          <CredentialListByCredential
                            {...props}
                          ></CredentialListByCredential>
                        )}
                      </Tab>
                      <Tab eventKey="byemployee" title="By Employee">
                        {byEmployeeLoaded && (
                          <CredentialListByEmployee
                            {...props}
                          ></CredentialListByEmployee>
                        )}
                      </Tab>
                    </Tabs>
                  </CustomTabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CredentialList;
