import React, { useEffect, useState } from "react";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import { RouteComponentProps } from "react-router-dom";
import { FgCheckbox, FgInput, FgUseForm } from "../../Common/FormGroups";
import { useDepartmentId } from "../../../stores/SystemStore";
import { NfirsFetchStatus, useNfirsGetData } from "../../NFIRS/NFIRSHelper";
import commonService from "../../../services/CommonService";
import toastStore from "../../../stores/ToastStore";
import CommonSpinner from "../../Common/CommonSpinner";
import CredentialCategoryTypeahead from "../../Credentials/Typeaheads/CredentialCategoryTypeahead";
import NewCredentialCategoryDialog from "../../Credentials/Dialogs/NewCredentialCategoryDialog";
import DuplicateCredentialTemplateDialog from "./DuplicateCredentialTemplateDialog";
import ConfirmDialog from "../../Common/ConfirmDialog";
import CredentialRequirementsTemp from "./CredentialRequirementsTemp";

function CredentialTemplate(props: RouteComponentProps<any>) {
  const [id, setId] = useState(
    Number(props.match?.params?.id) || (null as any)
  );
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [newCategory, setNewCategory] = useState(false);
  const [model, setModel] =
    useState<ApsModels.ICredCredentialTemplateOutputDto>({
      active: true,
      credentialRequirementsCsvLines: [],
    } as any);
  const [libType, setLibType] = useState(
    ApsModels.CredLibraryTypeEnum.Department
  );

  const navigateBack = (doneUrl?: string) => {
    if (props.history.action !== "POP") {
      commonService.routeGoBack(props, doneUrl);
    } else {
      props.history.push("/templates" + (doneUrl || ""));
    }
  };

  const departmentId = useDepartmentId();
  useEffect(() => {
    if (departmentId.current > 0) {
      setLoading(true);
      credCategories.getData();
      displayNotification.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  const credCategories = useNfirsGetData(
    async () =>
      ApsServices.http.credCredential.getCredentialCategories(libType),
    "Credential Categories"
  );

  const [origLines, setOrigLines] = useState<string[]>([]);
  const [hasCsvError, setHasCsvError] = useState(false);
  const getTemplate = async () => {
    setLoading(true);
    await ApsServices.http.credTemplate
      .getCredentialTemplate(id)
      .then((data) => {
        setModel({ ...data, _category: data.credentialCategoryId } as any);
        setValuesFromModel({ ...data });
        setOrigLines(
          (data.credentialRequirementsCsvLines || []).filter(
            (x) => !commonService.isNullOrWhitespace(x)
          )
        );
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Credential Template", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const displayNotification = useNfirsGetData(
    ApsServices.http.credTemplate.displayNotificationSectionInTemplatesFlag,
    "Display Notification"
  );

  useEffect(() => {
    if (
      credCategories.status === NfirsFetchStatus.Complete &&
      credCategories.data &&
      displayNotification.status === NfirsFetchStatus.Complete
    ) {
      if (id) {
        getTemplate();
      } else {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credCategories.status, displayNotification.status]);

  const submit = async (data: ApsModels.ICredCredentialTemplateInsertDto) => {
    if (id) {
      if (model.mustStartNew) {
        toastStore.showToast(
          "This credential was build before templates. Please start a new credential template.",
          "warning"
        );
        return;
      }
      if (!model.mustStartNew && !model.canItBeUpdated) {
        toastStore.showToast(
          "This credential template has assigned credentials. Please 'Save as New' to create a new credential template.",
          "warning"
        );
        return;
      }
    }

    if (hasCsvError) {
      toastStore.showToast(
        "Please fill in all required fields in Requirement Checklist",
        "warning"
      );
      return;
    }

    setSaving(true);
    if (id) {
      await ApsServices.http.credTemplate
        .updateCredentialTemplate(id, {
          ...model,
          credentialCategoryId: (model as any)._category,
        })
        .then((data) => {
          setModel({ ...data, _category: data.credentialCategoryId } as any);
          toastStore.showToast("Credential Template Saved", "success");
          navigateBack(commonService.doneUrls.submitted);
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Template", err);
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      await ApsServices.http.credTemplate
        .createCredentialTemplate({
          ...model,
          credentialCategoryId: (model as any)._category,
        })
        .then((data) => {
          setModel({ ...data, _category: data.credentialCategoryId } as any);
          toastStore.showToast("Credential Template Saved", "success");
          setId(data.id);
          navigateBack(commonService.doneUrls.created);
        })
        .catch((err) => {
          toastStore.showError("Failed Saving Template", err);
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const {
    registry,
    handleSubmit,
    formState,
    setValue,
    setValuesFromModel,
    formRef,
  } = FgUseForm({
    /*
     * This is where we add fields for validation
     */
    credentialLibrary: {
      displayName: "Credential Library",
      validation: {
        required: false,
      },
    },
    credentialCategoryId: {
      displayName: "Credential Category",
      validation: {
        required: true,
      },
    },
    name: {
      displayName: "Credential Name",
      validation: {
        required: true,
      },
    },
    code: {
      displayName: "Credential Code",
      validation: {
        required: true,
      },
    },
    totalHoursNeeded: {
      displayName: "Credential Hours",
      validation: {
        required: true,
      },
    },
    expiresInYears: {
      displayName: "Credential Period of Validity in Years",
      validation: {
        required: true,
      },
    },
    notifyOtherEmailAddresses: {
      displayName: "Notify Other Email(s)",
      validation: {
        required: false,
        validate: {
          required: (val: string) => {
            if (model.notifyOther) {
              if (commonService.isNullOrEmpty(val)) {
                return false;
              } else {
                return commonService.validateEmailCSV(val);
              }
            }
            return true;
          },
        },
      },
    },
    dateBeforeScheduledDateToNotify: {
      displayName: "Days Before Scheduled Date",
      validation: {
        required: false,
        validate: {
          required: (val: string) => {
            if (model.notifyBeforeScheduledDate) {
              if (commonService.isNullOrEmpty(val)) {
                return false;
              } else {
                return commonService.validateNumbersOnlyCSV(val);
              }
            }
            return true;
          },
        },
      },
    },
    dateAfterScheduledDateToNotify: {
      displayName: "Days After Scheduled Date",
      validation: {
        required: false,
        validate: {
          required: (val: string) => {
            if (model.notifyAfterScheduledDate) {
              if (commonService.isNullOrEmpty(val)) {
                return false;
              } else {
                return commonService.validateNumbersOnlyCSV(val);
              }
            }
            return true;
          },
        },
      },
    },
  });

  const [duplicateTemplate, setDuplicateTemplate] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const hideRequirements = () => {
    return (
      !commonService.userInfo?.isAdmin ||
      !model.credentialCategoryId ||
      !!(credCategories.data || []).find(
        (x) =>
          x.id === model.credentialCategoryId &&
          x.credCategoryTypeEnum === ApsModels.CredCategoryTypeEnum.Ems
      )
    );
  };

  return (
    <>
      {newCategory && commonService.userInfo.isAdmin && (
        <NewCredentialCategoryDialog
          onClose={(saved) => {
            setNewCategory(false);
          }}
          onChange={(data) => {
            const categ = data as ApsModels.ICredCredentialCategoryOutputDto;
            credCategories.setData((list) => {
              return [...(list || []), categ];
            });
            credCategories.getData();
            if (categ) {
              setValue(
                "credentialCategoryId",
                commonService.getNumber(categ.id)
              );
              setModel((p) => {
                return {
                  ...p,
                  _category: commonService.getNumber(categ.id),
                } as any;
              });
            }
            setNewCategory(false);
          }}
        ></NewCredentialCategoryDialog>
      )}
      {duplicateTemplate && (
        <DuplicateCredentialTemplateDialog
          data={{
            name: model.name,
            id: model.id,
          }}
          onClose={(saved) => {
            setDuplicateTemplate(false);
          }}
          onChange={(data) => {
            if (data) {
              props.history.push(`/templates` + commonService.doneUrls.created);
            }
            setDuplicateTemplate(false);
          }}
        ></DuplicateCredentialTemplateDialog>
      )}
      <ConfirmDialog
        show={confirmDelete}
        buttons="yesno"
        title="Confirmation"
        message={`Do you really want to delete this Credential Template?`}
        done={(rtn) => {
          setConfirmDelete(false);
          if (rtn === "yes") {
            setLoading(true);
            ApsServices.http.credTemplate
              .deleteCredentialTemplate(model.id)
              .then((data) => {
                toastStore.showToast("Credential Template Deleted", "success");
                setLoading(false);
                props.history.push(
                  `/templates` + commonService.doneUrls.deleted
                );
              })
              .catch((error) => {
                toastStore.showError(
                  "Failed Deleting Credential Template",
                  error
                );
                setLoading(false);
              });
          }
        }}
      />
      {loading && <CommonSpinner overlay={true}></CommonSpinner>}
      <form
        className={`flex flex-1 flex-col ${loading ? "display-none" : ""}`}
        onSubmit={handleSubmit(submit)}
        ref={formRef}
      >
        <div className="container-fluid">
          <div className="flex-0">
            <div className="headerControls">
              <div className="flex-1">
                <span className="h4 mb-0 font-size-18 text-uppercase">
                  {id ? "EDIT" : "ADD"} CREDENTIAL TEMPLATE
                </span>
              </div>
              <div
                className="flex-0 flex flex-wrap"
                style={{ justifyContent: "right" }}
              >
                {!!id && (
                  <button
                    className="btn btn-outline-danger"
                    type="button"
                    disabled={saving}
                    onClick={(e) => {
                      if (id && !model.mustStartNew && !model.canItBeUpdated) {
                        toastStore.showToast(
                          "Cannot delete credential because it has already been assigned to users.",
                          "warning"
                        );
                        return;
                      }
                      setConfirmDelete(true);
                    }}
                  >
                    Delete
                  </button>
                )}
                <button
                  className="btn btn-secondary"
                  type="button"
                  disabled={saving}
                  onClick={(e) => {
                    navigateBack();
                  }}
                >
                  Cancel and Close
                </button>
                {!!id && !model.mustStartNew && (
                  <button
                    className="btn btn-success"
                    type="button"
                    disabled={saving}
                    onClick={(e) => {
                      setDuplicateTemplate(true);
                    }}
                  >
                    Save as New Template
                  </button>
                )}
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={saving}
                >
                  <span className="px-2">
                    {saving ? "Saving..." : "Submit"}
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div
                className={`card box-card ${
                  displayNotification.data ? "" : "h-100"
                }`}
              >
                <div className="card-body">
                  <h4 className="mb-0 font-size-16">
                    {"1. Credential Details"}
                  </h4>
                  <div className="mt-4"></div>
                  {/* <label>Credential Library</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setLibType(Number(e.target.value) || 0);
                      setModel((p) => {
                        return {
                          ...p,
                          _category: undefined,
                        } as any;
                      });
                      setValue("credentialCategoryId", undefined);
                    }}
                  >
                    {ListCredLibraryTypes.map((i) => (
                      <option key={i.value} value={i.value}>
                        {i.label}
                      </option>
                    ))}
                  </select>
                  <div className="mt-3"></div> */}
                  {id ? (
                    <>
                      <div>
                        <label>Credential Category</label>
                      </div>
                      <div className="mt-1">
                        {credCategories.data
                          ?.filter((x) => x.id === model.credentialCategoryId)
                          ?.map((x) => (
                            <div
                              key={x.id}
                              className="alert alert-sm alert-secondary px-3"
                            >
                              <section>{`${x.name}`}</section>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <>
                      {commonService.userInfo.isAdmin ? (
                        <div className="flex flex-center">
                          <label className="flex-1">Credential Category</label>
                          <div>
                            <span
                              className="text-primary pointer"
                              onClick={(e) => {
                                setNewCategory(true);
                              }}
                            >
                              New Category
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-center">
                          <label className="flex-1">Credential Category</label>
                          <div></div>
                        </div>
                      )}
                      <CredentialCategoryTypeahead
                        libType={libType}
                        onChange={(data, ref) => {
                          if (data && data.length > 0) {
                            ref.current?.clear();
                            const categ = data[0]
                              .model as ApsModels.ICredCredentialCategoryOutputDto;
                            if (categ) {
                              setModel((p) => {
                                return {
                                  ...p,
                                  _category: commonService.getNumber(categ.id),
                                  credentialCategoryId: categ.id,
                                } as any;
                              });
                              setValue("credentialCategoryId", categ.id);
                            }
                          }
                        }}
                      ></CredentialCategoryTypeahead>
                      <div className="currentParticipants mt-2">
                        {credCategories.data
                          ?.filter((x) => x.id === (model as any)._category)
                          ?.map((x) => (
                            <div key={x.id}>
                              <section>{x.name}</section>
                              <section
                                title="Remove"
                                onClick={() => {
                                  setModel((p) => {
                                    return {
                                      ...p,
                                      _category: undefined,
                                      credentialCategoryId: undefined,
                                    } as any;
                                  });
                                  setValue("credentialCategoryId", undefined);
                                }}
                              >
                                <i className="fa fa-times"></i>
                              </section>
                            </div>
                          ))}
                      </div>
                    </>
                  )}

                  <div className="mt-3"></div>
                  <FgInput
                    id={"cred-name"}
                    label="Credential Name"
                    placeHolder="Add credential name"
                    registeredField={registry.name}
                    formState={formState}
                    onChange={(val) => {
                      setModel((p) => {
                        return {
                          ...p,
                          name: val,
                        };
                      });
                    }}
                  />
                  <FgInput
                    id={"cred-code"}
                    label="Credential Code"
                    placeHolder="Add credential code"
                    registeredField={registry.code}
                    formState={formState}
                    onChange={(val) => {
                      setModel((p) => {
                        return {
                          ...p,
                          code: val,
                        };
                      });
                    }}
                  />
                  <FgInput
                    id={"cred-hours"}
                    label="Credential Hours"
                    placeHolder="Add credential hours"
                    type="Number"
                    registeredField={registry.totalHoursNeeded}
                    formState={formState}
                    onChange={(val) => {
                      setModel((p) => {
                        return {
                          ...p,
                          totalHoursNeeded: commonService.getNumber(val),
                        };
                      });
                    }}
                  />

                  <label className="mb-1">
                    Credential Period of Validity in Years
                  </label>
                  <FgInput
                    id={"cred-validity"}
                    label="(enter zero for credentials that do not expire)"
                    placeHolder="Add period of validity"
                    type="Number"
                    registeredField={registry.expiresInYears}
                    formState={formState}
                    onChange={(val) => {
                      setModel((p) => {
                        return {
                          ...p,
                          expiresInYears: commonService.getNumber(val),
                        };
                      });
                    }}
                  />
                  <div>
                    <label>Credential Status</label>
                  </div>
                  <label>Inactive</label>
                  <label className="switch flex-none">
                    <input
                      type="checkbox"
                      checked={model.active}
                      onChange={(e) => {
                        setModel((p) => {
                          return {
                            ...p,
                            active: !p.active,
                          };
                        });
                      }}
                    />
                    <span className="slider round flex-none"></span>
                  </label>
                  <label>Active</label>
                </div>
              </div>
              {/* disabled see APS110-63}
              {/* <div className="card box-card h-60">
                <div className="card-body">
                  <h4 className="mb-0 font-size-16">
                    {"2. Credential Validation"}
                  </h4>
                </div>
              </div> */}

              {displayNotification.data && (
                <div className="card box-card">
                  <div className="card-body">
                    <h4 className="mb-0 font-size-16">
                      {"2. Credential Notification"}
                    </h4>
                    <div className="mt-4"></div>
                    <label>When to Notify</label>

                    <div className="flex">
                      <div className="pt-2 pe-0">
                        <FgCheckbox
                          id="notifyBeforeScheduledDate"
                          label=""
                          data={model.notifyBeforeScheduledDate}
                          onChange={(data) => {
                            setModel((p) => {
                              return {
                                ...p,
                                notifyBeforeScheduledDate: data || false,
                              };
                            });
                          }}
                          disabled={false}
                        />
                      </div>
                      <div
                        className="pe-2"
                        style={{
                          maxWidth: "120px",
                        }}
                      >
                        <FgInput
                          id="dateBeforeScheduledDateToNotify"
                          formGroupClass="mb-1"
                          registeredField={
                            registry.dateBeforeScheduledDateToNotify
                          }
                          formState={formState}
                          disabled={!model.notifyBeforeScheduledDate}
                          onChange={(val) => {
                            setModel((p) => {
                              return {
                                ...p,
                                dateBeforeScheduledDateToNotify: val,
                              };
                            });
                          }}
                        />
                      </div>
                      <div className="flex-1 pt-1">
                        <label>days before scheduled date</label>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="pt-2 pe-0">
                        <FgCheckbox
                          id="notifyAfterScheduledDate"
                          label=""
                          data={model.notifyAfterScheduledDate}
                          onChange={(data) => {
                            setModel((p) => {
                              return {
                                ...p,
                                notifyAfterScheduledDate: data || false,
                              };
                            });
                          }}
                          disabled={false}
                        />
                      </div>
                      <div
                        className="pe-2"
                        style={{
                          maxWidth: "120px",
                        }}
                      >
                        <FgInput
                          id={"dateAfterScheduledDateToNotify"}
                          formGroupClass="mb-1"
                          registeredField={
                            registry.dateAfterScheduledDateToNotify
                          }
                          formState={formState}
                          disabled={!model.notifyAfterScheduledDate}
                          onChange={(val) => {
                            setModel((p) => {
                              return {
                                ...p,
                                dateAfterScheduledDateToNotify: val,
                              };
                            });
                          }}
                        />
                      </div>
                      <div className="flex-1 pt-1">
                        <label>days after scheduled date</label>
                      </div>
                    </div>

                    <FgCheckbox
                      id="cred-notify-on-date"
                      label="On scheduled date"
                      data={model.notifyOnScheduledDate}
                      onChange={(data) => {
                        setModel((p) => {
                          return {
                            ...p,
                            notifyOnScheduledDate: data || false,
                          };
                        });
                      }}
                      disabled={false}
                    />
                    <FgCheckbox
                      id="cred-notify-when-complete"
                      label="Credential is complete"
                      data={model.notifyOnCompletion}
                      onChange={(data) => {
                        setModel((p) => {
                          return {
                            ...p,
                            notifyOnCompletion: data || false,
                          };
                        });
                      }}
                      disabled={false}
                    />
                    <div className="mt-4"></div>
                    <label>Who to Notify</label>
                    <FgCheckbox
                      id={"cred-acc-admins-notify"}
                      label="Account Admin(s)"
                      data={model.notifyAccountAdmins}
                      onChange={(data) => {
                        setModel((p) => {
                          return {
                            ...p,
                            notifyAccountAdmins: data || false,
                          };
                        });
                      }}
                      disabled={false}
                    ></FgCheckbox>
                    <FgCheckbox
                      id={"cred-supervisors-notify"}
                      label="Immediate supervisor(s)"
                      data={model.notifyImmediateSupervisors}
                      onChange={(data) => {
                        setModel((p) => {
                          return {
                            ...p,
                            notifyImmediateSupervisors: data || false,
                          };
                        });
                      }}
                      disabled={false}
                    ></FgCheckbox>
                    <FgCheckbox
                      id={"cred-others-notify"}
                      label="Other"
                      data={model.notifyOther}
                      onChange={(data) => {
                        setModel((p) => {
                          return {
                            ...p,
                            notifyOther: data || false,
                          };
                        });
                      }}
                      disabled={false}
                    ></FgCheckbox>
                    <FgInput
                      id={"cred-others-notify-emails"}
                      label=""
                      placeHolder="Enter email addresses separated by comma"
                      registeredField={registry.notifyOtherEmailAddresses}
                      formState={formState}
                      rows={3}
                      disabled={!model.notifyOther}
                      onChange={(val) => {
                        setModel((p) => {
                          return {
                            ...p,
                            notifyOtherEmailAddresses: val,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-8">
              <div className="card box-card mb-0 h-100">
                <div className="card-body">
                  <h4 className="mb-0 font-size-16">
                    {`${
                      displayNotification.data ? 3 : 2
                    }. Credential Requirements`}
                  </h4>
                  {/* TODO */}
                  <div className="mt-4"></div>
                  <label>Instructions</label>
                  <div className="h-36">
                    <textarea
                      value={model.instructions || ""}
                      onChange={(e) =>
                        setModel({ ...model, instructions: e.target.value })
                      }
                      className="w-100 h-100 p-2"
                    ></textarea>
                  </div>
                  <div className="mt-3"></div>
                  {!commonService.userInfo?.isAdmin && (
                    <>
                      <label>Requirement Checklist</label>
                      <div>
                        Contact{" "}
                        <a href="mailto:support@advancedproblemsolving.org">
                          support@advancedproblemsolving.org
                        </a>{" "}
                        to request setting up your checklist of requirements for
                        this credential.
                      </div>
                    </>
                  )}
                  {!hideRequirements() && (
                    <>
                      <label>Requirement Checklist</label>
                      <div>
                        {!loading && (
                          <CredentialRequirementsTemp
                            csvLines={origLines}
                            onError={(hasError) => {
                              setHasCsvError(hasError);
                            }}
                            onChange={(list) => {
                              console.log(list);
                              setModel((p) => {
                                return {
                                  ...p,
                                  credentialRequirementsCsvLines: [...list],
                                };
                              });
                            }}
                          ></CredentialRequirementsTemp>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default CredentialTemplate;
