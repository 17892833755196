import React, { Component } from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { Login } from './components/Account/Login';
import { Logout } from './components/Account/Logout';
import { AppContext } from './AppContext';
import { AccountDto, AuthenticationService } from './services/AuthenticationService';
import { Unsubscriber } from './common/Observable';
import { Users } from './components/Admin/Users';
import { DepartmentsActions } from './components/Admin/Departments/DepartmentsActions';
import { Departments } from './components/Admin/Departments/Departments';
import { Groups } from './components/Admin/Groups';
import { GroupAddEdit } from './components/Admin/GroupAddEdit';
import GroupAddEditUsers from "./components/Admin/GroupAddEditUsers"
import { Layout } from './components/Layout';
import { DailyOperational } from "./components/DailyOperational/DailyOperational";
import { EditUser } from './components/Admin/EditUser';
import { ForgotPassword } from './components/Account/ForgotPassword';
import { ResetPassword } from './components/Account/ResetPassword';
import { ChangePassword } from './components/Account/ChangePassword';
import { Profile } from './components/Account/Profile';
import { UserConsole } from './components/UserConsole/UserConsole';
import 'ag-grid-enterprise';
import 'tailwindcss/tailwind.css';
import './assets/scss/ag-grid-theme-custom.scss';
import './assets/scss/theme.scss';
import { UserService } from './services/UserService';
import {Subscription} from "rxjs";
import {
    AccessRights,
    AccessRightsType,
    AccessRightsInteractionService
} from "./services/AccessRightsInteractionService";
import Drills from './aps2/components/Drills';
import Drill from './aps2/components/Drills/Drill';
import commonService from './aps2/services/CommonService';
import systemStore from './aps2/stores/SystemStore';
import { DataVisualization } from './aps2/components/DataVisualization';
import CustomIframe from './aps2/components/CustomIframe/CustomIframe';
import { DepartmentService } from './services/DepartmentService';
import { ModulesDto } from './data/Department';
import { TimeoutPopUp } from './components/Shared/timeoutPopUp';
import NFIRSList from './aps2/components/NFIRS/NFIRSList';
import NFIRSEntry from './aps2/components/NFIRS/Entry/NFIRSEntry';
import CourseRecordList from './aps2/components/CourseRecords/CourseRecordList';
import CourseRecord from './aps2/components/CourseRecords/CourseRecord';
import CredentialList, { CredentialsPagePicker } from './aps2/components/Credentials/CredentialList';
import RecordCredential from './aps2/components/Credentials/RecordCredential';
import ViewCredential from './aps2/components/Credentials/ViewCredential';
import CredentialsViewByCredential from './aps2/components/Credentials/CredentialsViewByCredential';
import CredentialsViewByEmployee from './aps2/components/Credentials/CredentialsViewByEmployee';
import Dashboard from './aps2/components/Dashboard/Dashboard';
import TemporaryLinkPage from './aps2/components/TemporaryLink/TemporaryLinkPage';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ISOReports from './aps2/components/ISO/ISOReports';
import ReportWrapper from './aps2/components/ISO/ReportWrapper';
import TemplateList from './aps2/components/Templates/TemplateList';
import CourseTemplate from './aps2/components/Templates/CourseTemplate/CourseTemplate';
import ApsServices from './aps2/services';
import ViewCourseTemplate from './aps2/components/Templates/CourseTemplate/ViewCourseTemplate';
import CredentialTemplate from './aps2/components/Templates/CredentialTemplate/CredentialTemplate';
import ViewCredentialTemplate from './aps2/components/Templates/CredentialTemplate/ViewCredentialTemplate';
import Assignments from './aps2/components/Assignments/Assignments';
import NFIRSDynamicReport from './aps2/components/Reports/NFIRSDynamicReport';
import { BrowserRouter } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = RouteComponentProps & {}

type State = {
    isLoaded: boolean,
    isAuthenticated: boolean,
    authenticate: boolean,
    isSuperUser: boolean,
    isDepartmentAdmin: boolean,
    isAccountAdmin: boolean,
    adminModule: number,
    reload: boolean,
    hasAccessUserConsole: boolean,
    hasAccessDailyOperational: boolean,
    isVisibleUserConsole: boolean,
    isVisibleDailyOperational: boolean,
    isEditableDailyOperational: boolean,
    isAdmin: boolean;
    modules: ModulesDto[];
    customIframeModuleName: string | null;
    departmentId: number;
}

class App extends Component<Props, State> {
    static displayName = App.name;
    private _isAuthenticatedUnsubscriber: Unsubscriber | null = null;
    private _isAdminDepartmentUnsubscriber: Unsubscriber | null = null;
    private _routeChange?: Unsubscriber;
    private _departmentUnsubscriber: Unsubscriber | null = null;

    private readonly subscription: Subscription = this.getDailyOperationalAccessRightsSubscription();

    private getDailyOperationalAccessRightsSubscription(): Subscription {
        return AccessRightsInteractionService.accessRights$.subscribe(accessRights => {
            accessRights.forEach((r: AccessRights) => {
                if (r.type === AccessRightsType.DailyOperational) {
                    this.setState({
                        hasAccessDailyOperational: r.noAccess,
                        isVisibleDailyOperational: r.view,
                        isEditableDailyOperational: r.edit
                    });
                } else {
                    this.setState({
                        hasAccessUserConsole: r.noAccess,
                        isVisibleUserConsole: r.view,
                    });
                }
            });
        });
    }

    state: State = {
        isLoaded: false,
        isAuthenticated: false,
        authenticate: false,
        isDepartmentAdmin: false,
        isAccountAdmin: false,
        isSuperUser: false,
        adminModule: 1,
        reload: false,
        hasAccessUserConsole: false,
        hasAccessDailyOperational: false,
        isVisibleUserConsole: false,
        isVisibleDailyOperational: false,
        isEditableDailyOperational: false,
        isAdmin: false,
        modules: [],
        customIframeModuleName: null,
        departmentId: 0
    }

    setAdminModule = (module: number) => {
        this.setState({ adminModule: module })
    }

    setSuperUserPermission = (value: AccountDto | null) => {
        if (value) {
            this.setState({
                hasAccessUserConsole: value.hasAccessUserConsole,
                hasAccessDailyOperational: value.hasAccessDailyOperational,
                isVisibleDailyOperational: value.isVisibleDailyOperational,
                isVisibleUserConsole: value.isVisibleUserConsole,
                isEditableDailyOperational: value.isEditableDailyOperational,
                isAdmin: value.isAdmin,
                isAccountAdmin: value.isAccountAdmin
            })
        }
    }

    canAccessLogDrills() {
        if(!this.isModuleActive("Log Drills")) {
            return false;
        }
        return commonService.canAccessLogDrills();     
    }

    canAcessPersonnel(){
        if(!this.isModuleActive("Personnel")) {

            return false;
        }
        return commonService.canAccessPersonnel();  
    }

    canAccessTemplates() {
        if(!this.isModuleActive("Templates")) {
            return false;
        }
        return commonService.canAccessTemplates();
    }
    
    canAccessNfirs() {
        if(!this.isModuleActive("NFIRS")) {
            return false;
        }
        return commonService.canAccessNfirs();     
    }

    canAccessCourseRecords() {
        if(!this.isModuleActive("Course Records")) {
            return false;
        }
        return commonService.friendlyClaims.course.canAccess;   
    }

    canAccessCredentials() {
        if(!this.isModuleActive("Credentials")) {
            return false;
        }
        return commonService.friendlyClaims.credentials.canAccess;   
    }

    canAccessDashboard() {
        if (!this.isModuleActive("Dashboard")) {
            return false;
        }
        return commonService.friendlyClaims.dashboard.canAccess;   
    }

    canAccessCredentialsForOtheEmployees() {
        return commonService.friendlyClaims.credentials.canViewAll;   
    }

    canAcessDataVisualization() {
        if (!this.isModuleActive("Data Visualization")) {
            return false;
        }
        return commonService.canAccessDataVisualization();
    }

    canAccessCustomIframe() {
        if (!this.isModuleActive("Custom IFrame")) {
            return false;
        }
        return commonService.canAccessCustomIFrame();
    }

    canAccessDailyOperational() {
        return this.isModuleActive("Daily Operational");
    }

    canAccessLinks() {
        return this.isModuleActive("User Console");
    }

    canAccessReports() {
        return this.isModuleActive("ISO Training");
    }

    canAccessAssignments() {
        if (!this.isModuleActive("Assignments")) {
            return false;
        }
        return commonService.canAccessAssignments();
    }

    validateInTokenClaims() {
        const cur = commonService.friendlyClaims;
        if(cur.hasMissingTokenCliam) {
            systemStore.showSystemError("in-token");
        }     
    }

    componentDidMount = async () => {
        this._isAuthenticatedUnsubscriber = AuthenticationService
            .isAuthenticatedObservable
            .subscribe(value => {
                this.setState({ authenticate: true })
                this.setSuperUserPermission(value)
                this.validateInTokenClaims();
            })
        
        await AuthenticationService.initialize();
        this._isAdminDepartmentUnsubscriber = UserService.currentDepartment.subscribe(x => this.setAdminModule(x));                
        this.setState({ isLoaded: true });

        //SET DEPT 
        this.setState({ departmentId: UserService.currentDepartment.get() });
        this._departmentUnsubscriber = UserService.currentDepartment.subscribe(x => {            
            this.setState({ departmentId: x });
        });

        //This redirects to /Home or /Links if route changes and path is "/"
        this._routeChange = this.props.history.listen((location, action) => {
            //Check if user is logged-in and is path is "/"
            if(this.state.isAuthenticated && location.pathname === "/") {
                if(this.canAccessDashboard()) {
                    this.props.history.push('/home');
                } else if(this.canAccessLinks()) {
                    this.props.history.push('/links');
                }
            }
        });
    }

    componentDidUpdate = async (prevProps: Props, prevState: State) => {
        if (this.state.authenticate) {
            var dept = await DepartmentService.getDepartmentId();
            var modules: ModulesDto[] = [];
            try {
                modules = await DepartmentService.getModulesByDepartmentId(Number(dept));
            } catch (error) {
                console.log("Error Fetching Modules")
            }

            let customIframeModuleName = "";
            if (this.isModuleActive("Custom IFrame") && this.canAccessCustomIframe()) {
                const customIframeSettings = await ApsServices.http.customIframe.get(this.state.adminModule);
                customIframeModuleName = customIframeSettings.iframeModuleName;
            }

            this.setState({ modules: modules, isAuthenticated: true, authenticate: false, customIframeModuleName});
        }
    }
 
    componentWillUnmount = () => {
        this._isAuthenticatedUnsubscriber?.();
        this._isAdminDepartmentUnsubscriber?.();
        this._routeChange?.();
        this._departmentUnsubscriber?.();
        this.subscription.unsubscribe();
    }

    isModuleActive = (moduleName: string) => {        
        if (!this.state.modules?.find(x => x.name === moduleName)?.isSelected) {
            return false
        }
        return true
    }

    render() {

        return (
            <AppContext.Provider value={{ isAuthenticated: this.state.isAuthenticated, module: this.state.adminModule, hasAccessDailyOperational: this.state.hasAccessDailyOperational, hasAccessUserConsole: this.state.hasAccessUserConsole, isVisibleUserConsole: this.state.isVisibleUserConsole, isEditableDailyOperational: this.state.isEditableDailyOperational, isVisibleDailyOperational: this.state.isVisibleDailyOperational, isAdmin: this.state.isAdmin, isAccountAdmin: this.state.isAccountAdmin }}>
                {this.state.isLoaded && !this.state.isAuthenticated && 
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/account/login" component={Login} />
                        <Route exact path="/account/logout" component={Logout} />
                        <Route exact path="/account/forgotpassword" component={ForgotPassword} />
                        <Route exact path="/account/resetpassword" component={ResetPassword} />
                        <Route exact path="/account/changepassword" component={ChangePassword} />
                        <Route exact path="/publiclink/:title?" component={TemporaryLinkPage} />
                        {!this.state.authenticate &&
                            <Redirect to={`/account/login?ReturnUrl=${encodeURIComponent(this.props.location.pathname)}`} />
                        }                        
                    </Switch>
                </BrowserRouter>}

                {this.state.isLoaded && this.state.isAuthenticated && !!this.state.departmentId &&
                    <BrowserRouter basename={`${this.state.departmentId}`} key={this.state.departmentId}>
                        <Switch>
                            <Route exact path="/account/logout" component={Logout} />
                            <Route exact path="/account/forgotpassword" component={ForgotPassword} />
                            <Route exact path="/account/resetpassword" component={ResetPassword} />
                            <Route exact path="/account/changepassword" component={ChangePassword} />
                            <Route exact path="/publiclink/:title?" component={TemporaryLinkPage} />                            
                            <Route>                            
                                <Layout>
                                    <TimeoutPopUp />
                                    {this.canAccessDashboard() ?
                                        <Route exact path="/">
                                            <Redirect to="/home" />
                                        </Route> :
                                        this.canAccessLinks() && this.state.isVisibleUserConsole ?
                                            <Route exact path="/">
                                                <Redirect to="/links" />
                                            </Route> : null
                                    }
                                    {this.canAccessDailyOperational() && (this.state.isVisibleDailyOperational || this.state.isEditableDailyOperational) ? 
                                        <Route exact path="/dailyoperational" component={DailyOperational} /> :
                                        <Route exact path="/dailyoperational">
                                            <Redirect to="/" />
                                        </Route>
                                    }
                                    {this.canAccessLinks() && (this.state.isAdmin || this.state.isVisibleUserConsole) ?
                                        <Route exact path="/Links" component={UserConsole} />
                                        :
                                        <Route exact path="/Links">
                                            <Redirect to="/" />
                                        </Route>}

                                    {this.state.isAdmin && <Route exact path="/admin/users" component={Users} />}
                                    {this.state.isAdmin && <Route exact path="/admin/departments" component={Departments} />}
                                    {this.state.isAdmin && <Route exact path="/admin/department/:id?" component={DepartmentsActions} />}
                                    {commonService.canAccessGroups() && <>
                                        <Route exact path="/admin/groups" component={Groups} />
                                        <Route exact path="/admin/group/:groupId?" component={GroupAddEdit} />
                                        <Route exact path="/admin/group/:groupId?/Users" component={GroupAddEditUsers} />
                                    </>}
                                    {this.state.isAdmin && <Route exact path="/admin/user/:id?" component={EditUser} />}
                                                                                                            
                                    {this.canAccessLogDrills() ? 
                                        <>
                                            <Route exact path="/drills" component={Drills} />
                                            <Route exact path="/drills/:id" component={Drill} /> 
                                        </> 
                                        :
                                        <>
                                            <Route exact path="/drills">
                                                <Redirect to="/" />
                                            </Route>
                                            <Route exact path="/drills/:id">
                                                <Redirect to="/" />
                                            </Route>
                                        </>}

                                    {this.canAccessNfirs() ? 
                                        <> 
                                            <Route exact path="/nfirs" component={NFIRSList} />
                                            <Route exact path="/nfirs/:id" component={NFIRSEntry} />
                                        </>
                                        :
                                        <>
                                            <Route exact path="/nfirs">
                                                <Redirect to="/" />
                                            </Route>
                                            <Route exact path="/nfirs/:id">
                                                <Redirect to="/" />
                                            </Route>
                                        </>}

                                    {this.canAcessPersonnel() ?
                                        <>
                                            <Route exact path="/personnel" component={Users} />
                                            <Route exact path="/personnel/:id" component={EditUser} />
                                        </>
                                        :
                                        <>
                                            <Route exact path="/personnel">
                                                <Redirect to="/" />
                                            </Route>
                                            <Route exact path="/personnel/:id">
                                                <Redirect to="/" />
                                            </Route>
                                        </>}

                                    {this.canAccessTemplates() ?
                                        <>
                                            <Route exact path="/templates" component={TemplateList} />
                                            <Route exact path="/templates/course/:id" component={CourseTemplate} />
                                            <Route exact path="/templates/course/view/:id" component={ViewCourseTemplate} />
                                            <Route exact path="/templates/credential/:id" component={CredentialTemplate} />
                                            <Route exact path="/templates/credential/view/:id" component={ViewCredentialTemplate} />
                                        </>
                                        :
                                        <>
                                            <Route exact path="/templates">
                                                <Redirect to="/" />
                                            </Route>
                                            <Route exact path="/templates/course/:id?">
                                                <Redirect to="/" />
                                            </Route>
                                            <Route exact path="/templates/credential/:id?">
                                                <Redirect to="/" />
                                            </Route>
                                        </>}

                                    {this.canAcessDataVisualization() ?
                                        <>
                                            <Route exact path="/datavisualization" component={DataVisualization} />
                                        </>
                                        :   
                                        <Route exact path="/datavisualization">
                                            <Redirect to="/" />
                                        </Route>}

                                    {this.canAccessCourseRecords() ?
                                        <>
                                            <Route exact path="/courses" component={CourseRecordList} />
                                            <Route exact path="/courses/:id" component={CourseRecord} />
                                        </>
                                        :   
                                        <>
                                            <Route exact path="/courses">
                                                <Redirect to="/" />
                                            </Route>
                                            <Route exact path="/courses/:id">
                                                <Redirect to="/" />
                                            </Route>
                                        </>}

                                    {this.canAccessCredentials() ?
                                        <>                                        
                                            {this.canAccessCredentialsForOtheEmployees() ? <>
                                                <Route exact path="/credentials" component={CredentialsPagePicker} />
                                                <Route exact path="/credentials/credential/:credentialid" component={CredentialsViewByCredential} />
                                                <Route exact path="/credentials/employee/:employeeid" component={CredentialsViewByEmployee} />
                                            </> :
                                            <>
                                                <Route exact path="/credentials" component={CredentialsViewByEmployee} />
                                            </>}
                                            <Route exact path="/credentials/:id" component={CredentialsPagePicker} />
                                            <Route exact path="/credentials/edit/:credentialid" component={RecordCredential}/>                                            
                                            <Route exact path="/credentials/view/:credentialid" component={ViewCredential} />
                                        </>
                                        :   
                                        <>
                                            <Route exact path="/credentials">
                                                <Redirect to="/" />
                                            </Route>
                                            <Route exact path="/credentials/credential/:credentialid">
                                                <Redirect to="/" />
                                            </Route>
                                            <Route exact path="/credentials/employee/:employeeid">
                                                <Redirect to="/" />
                                            </Route>
                                            <Route exact path="/credentials/:id?">
                                                <Redirect to="/" />
                                            </Route>
                                            <Route exact path="/credentials/edit/:credentialid">
                                                <Redirect to="/" />
                                            </Route>
                                            <Route exact path="/credentials/view/:credentialid">
                                                <Redirect to="/" />
                                            </Route>
                                        </>}

                                    {this.canAccessReports() ? <>
                                        <Route exact path="/reports" component={ISOReports} />     
                                        <Route exact path="/reports/3/NFIRS" component={NFIRSDynamicReport} />
                                        <Route exact path="/reports/:id" component={ReportWrapper} />
                                    </> : 
                                        <>
                                            <Route exact path="/reports">
                                                <Redirect to="/" />
                                            </Route>
                                            <Route exact path="/reports/:id">
                                                <Redirect to="/" />
                                            </Route>
                                        </>}

                                    {this.canAccessDashboard() ?
                                        <Route exact path="/home" component={Dashboard} />
                                        : 
                                        <Route exact path="/home">
                                            <Redirect to="/links" />
                                        </Route>}
                                    
                                    {this.canAccessCustomIframe() ? <>
                                        <Route exact path={`/${this.state.customIframeModuleName}`} component={CustomIframe} />
                                    </> : 
                                        <Route exact path={`/${this.state.customIframeModuleName}`}>
                                            <Redirect to="/" />
                                        </Route>}

                                    {this.canAccessAssignments() ?
                                        <>
                                            <Route exact path="/assignments" component={Assignments} />
                                        </>
                                        :
                                        <Route exact path="/assignments">
                                            <Redirect to="/" />
                                        </Route>}
                                                                        
                                    {/* {this.state.isAdmin && <Route exact path="/Admin/User/:id?" component={EditUser} />} */}
                                    <Route exact path="/account/profile" component={Profile} />
                                </Layout>                            
                            </Route>
                        </Switch>
                    </BrowserRouter>
                }
            </AppContext.Provider>
        );
    }
}

const WrappedApp = withRouter(App);
export { WrappedApp as App };
