import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { UserService } from "../../../services/UserService";
import toastStore from "../../stores/ToastStore";
import Pagination, { usePaging } from "../Common/Pagination";
import {
  GridApi,
  GridReadyEvent,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ApsServices from "../../services";
import ApsModels from "../../models";
import { RouteComponentProps } from "react-router-dom";
import { useNfirsGetData } from "../NFIRS/NFIRSHelper";
import AddTemplateDialog from "./AddTemplateDialog";
import { AgGridService } from "../../../services/AgGridService";
import { FilterParameter } from "../../../data/FilterOrderPaginateParameters";

let TEMPLATE_PAGING = {
  // there might be a better solution for this,
  // ag grid server side doesn't seem to see 'paging' variable changes
  // so i have it here instead that can be accessed globally
  page: 1,
  pageSize: 100,
};

let LAST_FILTERS: FilterParameter[] = [];

function TemplateList(props: RouteComponentProps<any>) {
  const [departmentId, setDepartmentId] = useState(0);
  useEffect(() => {
    const disposer = UserService.currentDepartment.subscribe((id) => {
      setDepartmentId(id);
    });

    showCredentials.getData();

    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showCredentials = useNfirsGetData(
    ApsServices.http.credTemplate.displayCredentialTemplatesFlag,
    "Credential Template Flag"
  );

  useEffect(() => {
    LAST_FILTERS = [];
    if (departmentId > 0) {
      gridApi.onFilterChanged();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  const [paging, setPaging] = usePaging(1, 100);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [list, setList] = useState<ApsModels.ICredTemplateGridDto>();
  const [gridApi, setGridApi] = useState<GridApi>(null as any);
  const [gridColumnApi, setGridColumnApi] = useState(null as any);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    toggleColSizes();
  };

  const toggleColSizes = () => {
    if (gridApi && gridColumnApi && list) {
      setTimeout(() => {
        gridApi.sizeColumnsToFit();
        let allColumnIds: any[] = [];
        gridColumnApi.getAllColumns()?.forEach(function (column: any) {
          allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, false);
      }, 500);
    }
  };

  const StatusCellComponent = (rowProps: {
    data: ApsModels.ICredTemplateGridItemDto;
  }) => {
    if (rowProps.data?.active) {
      return <span className="alert-chip alert alert-success">Active</span>;
    }
    return <span className="alert-chip alert alert-danger">Inacative</span>;
  };

  const ViewButtonCellComponent = (rowProps: {
    data: ApsModels.ICredTemplateGridItemDto;
  }) => {
    return (
      <button
        type="button"
        className="btn btn-sm btn-outlined"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          goToTemplate(rowProps.data);
        }}
      >
        <FontAwesomeIcon icon={faEye} />
      </button>
    );
  };

  const goToTemplate = (data: ApsModels.ICredTemplateGridItemDto) => {
    if (data.templateType === ApsModels.CourseOrCredentialEnum.Course) {
      props.history.push(`/templates/course/view/${data.id}`);
    } else {
      props.history.push(`/templates/credential/view/${data.id}`);
    }
  };

  const CategoryCellComponent = (rowProps: {
    data: ApsModels.ICredTemplateGridItemDto;
  }) => {
    return (
      <span
        className="txt-primary pointer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          goToTemplate(rowProps.data);
        }}
      >
        {rowProps.data?.category}
      </span>
    );
  };

  const CodeNumberCellComponent = (rowProps: {
    data: ApsModels.ICredTemplateGridItemDto;
  }) => {
    return (
      <span
        className="txt-primary pointer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          goToTemplate(rowProps.data);
        }}
      >
        {rowProps.data?.codeNumber}
      </span>
    );
  };

  const TemplateNameCellComponent = (rowProps: {
    data: ApsModels.ICredTemplateGridItemDto;
  }) => {
    return (
      <span
        className="txt-primary pointer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          goToTemplate(rowProps.data);
        }}
      >
        {rowProps.data?.templateName}
      </span>
    );
  };

  const colFilterSettings = {
    filter: "agTextColumnFilter",
    filterParams: {
      buttons: ["reset"],
      filterOptions: ["Contains"],
      maxNumConditions: 1,
      closeOnApply: true,
    },
  };

  const [gridState, setGridState] = useState({
      columnDefs: [
        {
        field: "templateType",
        headerName: "Template Type",
        valueFormatter: (param: {
          data: ApsModels.ICredTemplateGridItemDto;
        }) => {
          return param.data?.templateType ===
            ApsModels.CourseOrCredentialEnum.Course
            ? "Course"
            : "Credential";
        },
        filter: "agSetColumnFilter",
        filterParams: { values: ["Course", "Credential"] },
      },
      {
        field: "templateName",
        headerName: "Template Name",
        cellRenderer: "templateNameCellComponent",
        ...colFilterSettings,
      },
      {
        field: "category",
        headerName: "Category",
        cellRenderer: "categoryCellComponent",
        ...colFilterSettings,
      },
      {
        field: "codeNumber",
        headerName: "Code / Number",
        cellRenderer: "codeNumberCellComponent",
        ...colFilterSettings,
      },
      {
        field: "hours",
        headerName: "Hours",
        valueFormatter: (param: {
          data: ApsModels.ICredTemplateGridItemDto;
        }) => {
          return param.data?.hours as any;
        },
        menuTabs: [],
      },
      {
        field: "periodOfValidity",
        headerName: "Period of Validity",
        valueFormatter: (param: {
          data: ApsModels.ICredTemplateGridItemDto;
        }) => {
          return param.data?.periodOfValidity as any;
        },
        menuTabs: [],
      },
      {
        field: "lastModified",
        headerName: "Last Modified",
        valueFormatter: (param: {
          data: ApsModels.ICredTemplateGridItemDto;
        }) => {
          return `${moment(param.data?.lastModified).format(
            "MMM DD, YYYY [at] hh:mm A"
          )}` as any;
        },
        menuTabs: [],
      },
      {
        field: "lastAuthor",
        headerName: "Last Author",
        ...colFilterSettings,
      },
      {
        field: "active",
        headerName: "Status",
        cellRenderer: "statusCellComponent",
        filter: "agSetColumnFilter",
        filterParams: { values: ["Active", "Inactive"] },
      },
      {
        field: "action",
        headerName: "Action",
        cellRenderer: "viewButtonCellComponent",
        width: 100,
        suppressSizeToFit: true,
        sortable: false,
        menuTabs: [],
      },
    ],
    defaultColDef: {
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
      menuTabs: ["filterMenuTab"],
    },
    frameworkComponents: {
      viewButtonCellComponent: ViewButtonCellComponent,
      categoryCellComponent: CategoryCellComponent,
      codeNumberCellComponent: CodeNumberCellComponent,
      templateNameCellComponent: TemplateNameCellComponent,
      statusCellComponent: StatusCellComponent,
    },
    autoGroupColumnDef: { minWidth: 200 },
    rowData: [] as ApsModels.ICredTemplateGridItemDto[],
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Loading</span>',
    overlayNoRowsTemplate:
      '<span class="ag-overlay-loading-center">No Records Found</span>',
  });

  const statuses = useNfirsGetData(
    async () =>
      ApsServices.http.credCommon.credGenericEnumLookUp(
        "CourseTakenStatusEnum"
      ),
    "Statuses"
  );

  useEffect(() => {
    statuses.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setGridState({
      ...gridState,
      rowData: list?.credTemplateGridItemDtos || [],
    });
    toggleColSizes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    TEMPLATE_PAGING = { ...paging };
    if (gridApi) {
      gridApi.onFilterChanged();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  return (
    <>
      {showDialog && (
        <AddTemplateDialog
          onClose={(val) => {
            if (val === "course") {
              props.history.push(`/templates/course/new`);
            }
            if (val === "credential") {
              props.history.push(`/templates/credential/new`);
            }
            setShowDialog(false);
          }}
        ></AddTemplateDialog>
      )}
      <div
        id="template-list"
        className="flex flex-1 flex-column course-records-list"
        style={{ marginBottom: "-60px", position: "relative" }}
      >
        {/* {loading && <CommonSpinner overlay={true}></CommonSpinner>} */}
        <div className="container-fluid flex-card-container">
          <div className="flex-0">
            <div className="headerControls">
              <div>
                <span className="h4 mb-0 font-size-18 text-uppercase">
                  Templates
                </span>
              </div>
              <div></div>
            </div>
          </div>
          <div className="row h-full flex-1">
            <div className="col-12 col-lg-12 flex flex-col">
              <div className="card">
                <div className="card-body flex flex-col">
                  <div
                    className="flex flex-row mb-3"
                    style={{ flexWrap: "wrap", gap: "10px" }}
                  >
                    <div
                      className="flex-1 flex"
                      style={{
                        flexWrap: "wrap",
                        gap: "10px",
                        alignItems: "center",
                        minWidth: "250px",
                      }}
                    >
                      <div className="mr-4"></div>
                    </div>
                    <div>
                      <Button
                        variant="primary"
                        type="button"
                        onClick={(e) => {
                          if (showCredentials.data) {
                            setShowDialog(true);
                          } else {
                            props.history.push(`/Templates/Course`);
                          }
                        }}
                      >
                        Add Template
                      </Button>
                    </div>
                  </div>

                  <div style={{ width: "100%", height: "100%" }}>
                    <div
                      id="myGrid"
                      style={{
                        height: "100%",
                        minHeight: "300px",
                      }}
                      className="ag-theme-alpine flex-1"
                    >
                      <AgGridReact
                        columnDefs={gridState.columnDefs}
                        autoGroupColumnDef={gridState.autoGroupColumnDef}
                        enableRangeSelection={true}
                        animateRows={true}
                        onGridReady={onGridReady}
                        rowSelection={"multiple"}
                        rowMultiSelectWithClick={false}
                        suppressRowDeselection={true}
                        suppressRowClickSelection={true}
                        frameworkComponents={gridState.frameworkComponents}
                        rowModelType="serverSide"
                        paginationPageSize={paging.pageSize}
                        defaultColDef={gridState.defaultColDef}
                        serverSideDatasource={{
                          getRows: async (params: IServerSideGetRowsParams) => {
                            const orderParam = AgGridService.convertRequest(
                              params.request
                            ).order[0];

                            let filters = AgGridService.convertRequest(
                              params.request
                            ).filter;

                            const fCnt = filters.length;

                            if (fCnt > 1) {
                              filters = filters.filter(
                                (x) =>
                                  !LAST_FILTERS.find((y) => y.field === x.field)
                              );
                            }

                            LAST_FILTERS = [...filters];

                            if (fCnt > 1) {
                              const cFiltr = params.api.getFilterModel();
                              let mdl = {} as any;
                              mdl[filters[0].field] = cFiltr[filters[0].field];
                              params.api.setFilterModel(mdl); //This will re-trigger getRows
                              return;
                            }

                            const filterParam = filters.filter(
                              (x) =>
                                ["active", "templateType"].indexOf(x.field) ===
                                -1
                            )[0];

                            const activeFilter = filters.filter(
                              (x) => x.field === "active"
                            )[0];

                            const typeFilter = filters.filter(
                              (x) => x.field === "templateType"
                            )[0];

                            let reqParams: ApsModels.ICredTemplateListInputDto =
                              {
                                page: TEMPLATE_PAGING.page,
                                pageSize: TEMPLATE_PAGING.pageSize,
                                orderBy: ApsModels.OrderByEnum.Ascending,
                                credTemplateSort:
                                  ApsModels.CredTemplateSortFilterEnum
                                    .TemplateName,
                                credTemplateFilter:
                                  ApsModels.CredTemplateSortFilterEnum
                                    .TemplateName,
                                isFilter: false,
                                filter: "",
                                active: true,
                                courseOrCredential: null as any,
                                lastModified: null as any,
                                //ApsModels.CourseOrCredentialEnum.Course,
                              };

                            if (orderParam) {
                              const fieldName = orderParam.field;

                              reqParams.orderBy =
                                orderParam?.direction === "desc"
                                  ? ApsModels.OrderByEnum.Descending
                                  : ApsModels.OrderByEnum.Ascending;

                              if (fieldName === "templateName") {
                                reqParams.credTemplateSort =
                                  ApsModels.CredTemplateSortFilterEnum.TemplateName;
                              } else if (fieldName === "category") {
                                reqParams.credTemplateSort =
                                  ApsModels.CredTemplateSortFilterEnum.Category;
                              } else if (fieldName === "codeNumber") {
                                reqParams.credTemplateSort =
                                  ApsModels.CredTemplateSortFilterEnum.CodeNumber;
                              } else if (fieldName === "templateType") {
                                reqParams.credTemplateSort =
                                  ApsModels.CredTemplateSortFilterEnum.TemplateType;
                              } else if (fieldName === "lastModified") {
                                reqParams.credTemplateSort =
                                  ApsModels.CredTemplateSortFilterEnum.LastModified;
                              } else if (fieldName === "lastAuthor") {
                                reqParams.credTemplateSort =
                                  ApsModels.CredTemplateSortFilterEnum.LastAuthor;
                              } else if (fieldName === "active") {
                                reqParams.credTemplateSort =
                                  ApsModels.CredTemplateSortFilterEnum.Active;
                              } else if (fieldName === "hours") {
                                  reqParams.credTemplateSort =
                                      ApsModels.CredTemplateSortFilterEnum.Hours;
                                } else if (fieldName === "periodOfValidity") {
                                    reqParams.credTemplateSort =
                                        ApsModels.CredTemplateSortFilterEnum.PeriodOfValidity;
                                } 
                            }

                            if (filterParam) {
                              let filterText = "";
                              const fieldName = filterParam.field;

                              if (filterParam && filterParam.value) {
                                filterText = filterParam.value;
                              }

                              if (filterText) {
                                reqParams.isFilter = true;
                                reqParams.filter = filterText;
                              }

                              if (fieldName === "templateName") {
                                reqParams.credTemplateFilter =
                                  ApsModels.CredTemplateSortFilterEnum.TemplateName;
                              } else if (fieldName === "category") {
                                reqParams.credTemplateFilter =
                                  ApsModels.CredTemplateSortFilterEnum.Category;
                              } else if (fieldName === "codeNumber") {
                                reqParams.credTemplateFilter =
                                  ApsModels.CredTemplateSortFilterEnum.CodeNumber;
                              } else if (fieldName === "templateType") {
                                reqParams.credTemplateFilter =
                                  ApsModels.CredTemplateSortFilterEnum.TemplateType;
                              } else if (fieldName === "lastModified") {
                                reqParams.credTemplateFilter =
                                  ApsModels.CredTemplateSortFilterEnum.LastModified;
                              } else if (fieldName === "lastAuthor") {
                                reqParams.credTemplateFilter =
                                  ApsModels.CredTemplateSortFilterEnum.LastAuthor;
                              } else if (fieldName === "active") {
                                reqParams.credTemplateFilter =
                                  ApsModels.CredTemplateSortFilterEnum.Active;
                              }
                            }

                            if (typeFilter && typeFilter.values?.length === 1) {
                              reqParams.isFilter = true;
                              reqParams.credTemplateFilter =
                                ApsModels.CredTemplateSortFilterEnum.TemplateType;
                              reqParams.courseOrCredential =
                                typeFilter.values[0] === "Course"
                                  ? ApsModels.CourseOrCredentialEnum.Course
                                  : ApsModels.CourseOrCredentialEnum.Credential;
                            } else {
                              reqParams.courseOrCredential = null as any;
                            }

                            if (
                              activeFilter &&
                              activeFilter.values?.length === 1
                            ) {
                              reqParams.isFilter = true;
                              reqParams.active =
                                activeFilter.values[0] === "Active";
                              reqParams.credTemplateFilter =
                                ApsModels.CredTemplateSortFilterEnum.Active;
                            } else {
                              reqParams.active = null as any;
                            }

                            setLoading(true);
                            await ApsServices.http.credTemplate
                              .listTemplates(reqParams)
                              .then((data) => {
                                setList(data);
                                if (data.totalRecords === 0) {
                                  gridApi && gridApi.showNoRowsOverlay();
                                } else {
                                  gridApi && gridApi.hideOverlay();
                                }

                                params.successCallback(
                                  data.credTemplateGridItemDtos,
                                  data.credTemplateGridItemDtos.length
                                );
                              })
                              .catch((err) => {
                                toastStore.showError(
                                  "Failed Getting Course Templates",
                                  err
                                );
                                gridApi && gridApi.hideOverlay();
                              })
                              .finally(() => {
                                setLoading(false);
                              });
                          },
                        }}
                        onRowDoubleClicked={(event) => {
                          goToTemplate(event.data);
                        }}
                        overlayLoadingTemplate={
                          gridState.overlayLoadingTemplate
                        }
                        overlayNoRowsTemplate={gridState.overlayNoRowsTemplate}
                      />
                    </div>
                  </div>

                  <Pagination
                    length={list?.totalRecords || 0}
                    page={paging.page}
                    pageSize={paging.pageSize}
                    pageChange={pageChange}
                    showingOfWhatLabel="Records"
                    sizes={[10, 20, 50, 100]}
                  ></Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TemplateList;
