import ApsModels from "../../models";
import fetcher from "./Fetcher";
class GenericLookupService {
  getStates = async (countryId: any) => {
    const url = `/api/GenericLookup/GetStates/${countryId}`;
    return fetcher.get<ApsModels.ILookupStatesOutputDto[]>(url);
  };
}

const genericLookupService = new GenericLookupService();
export default genericLookupService;
