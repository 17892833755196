import React, { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import CheckboxList from "../../aps2/components/Common/CheckboxList";
import CommonSpinner from "../../aps2/components/Common/CommonSpinner";
import { FgSelect } from "../../aps2/components/Common/FormGroups";
import {
  IUserCredSettingsInputDto,
  IUserCredSettingsOutputDto,
  IUserCredSettingsUserOutputDto,
} from "../../aps2/models/_all";
import ApsServices from "../../aps2/services";
import toastStore from "../../aps2/stores/ToastStore";
import {
  NfirsFetchStatus,
  useNfirsGetData,
} from "../../aps2/components/NFIRS/NFIRSHelper";
import commonService from "../../aps2/services/CommonService";
import ConfirmDialog from "../../aps2/components/Common/ConfirmDialog";

type Props = {
  departmentId: string;
  userId: number;
};

const UserCredentialSettings = (props: Props) => {
  const [collapse, setCollapse] = useState(true);
  const [loading, setLoading] = useState(false);
  const [origSelectedAssignees, setOrigSelectedAssignees] = useState<
    IUserCredSettingsUserOutputDto[]
  >([]);
  const [selectedAssignees, setSelectedAssignees] = useState<
    IUserCredSettingsUserOutputDto[]
  >([]);
  const [model, setModel] = useState<IUserCredSettingsOutputDto>({} as any);
  const [origModel, setOrigModel] = useState<IUserCredSettingsOutputDto>(
    {} as any
  );

  const [openEmpDropdown, setOpenEmpDropdown] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);
  const [allActiveUsers, setAllActiveUsers] = useState([] as { id: number, name: string }[]);
  const [isLoading, setIsLoading] = useState(false);  
  const [selectedGroup, setSelectedGroup] = useState<number>();
  const groups = useNfirsGetData(
    ApsServices.http.group.listAllGroups,
    "Groups"
  );

  const getEmployees = useNfirsGetData(
    async () => ApsServices.http.group.getAllUsers(selectedGroup),
    "Employees"
  );

  useEffect(() => {
    setIsLoading(true);
    const tmo = setTimeout(() => {
      setIsLoading(false);
      if (groups.status === NfirsFetchStatus.Complete) {
        getEmployees.getData();
      }
    }, 500);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup, groups.status]);

  useEffect(() => {
    setIsLoading(getEmployees.status !== NfirsFetchStatus.Complete);
    if (
      getEmployees.status === NfirsFetchStatus.Complete &&
      getEmployees.data
    ) {
      setAllActiveUsers((list) => {
        return [
          ...list,
          ...(getEmployees.data || [])
            .filter((x) => !list.find((y) => y.id === x.id))
            .map((i) => {
              return {
                id: i.id,
                name: `${i.lastName}, ${i.lastName}`
              };
            }),
        ];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmployees.status]);
 
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await ApsServices.http.userCredSetting
        .get(props.userId)
        .then((data) => {
          setModel(data);
          setOrigModel(JSON.parse(JSON.stringify(data)));
          const assignees = [...data.assignees].filter((a) => a.isSelected);
          setSelectedAssignees(assignees);
          setOrigSelectedAssignees(JSON.parse(JSON.stringify(assignees)));
        })
        .catch((err) => {
          toastStore.showError(
            "Error getting User Credential Settings",
            err || ""
          );
        });
      setLoading(false);
    };
    init();
    groups.getData();
  }, []);

  const ref = React.createRef<AsyncTypeahead<any>>();

  const hasChanges = () => {
    return (
      JSON.stringify(origModel) !== JSON.stringify(model) ||
      JSON.stringify(origSelectedAssignees) !==
        JSON.stringify(selectedAssignees)
    );
  };

  const reset = () => {
    setModel(origModel);
    setSelectedAssignees(origSelectedAssignees);
  };

  const handleSubmit = async () => {
    const data: IUserCredSettingsInputDto = {
      ...model,
      personnelId: model.personnelId,
      rankId: model.ranks.find((r) => r.isSelected === true)?.id || -1,
      supervisorIds: model.supervisors
        .filter((r) => r.isSelected)
        .map((r) => r.id),
      isSupervisor: model.isSupervisor,
      assigneeIds: [...model.assignees]
        .filter(
          (a) => selectedAssignees.find((x) => x.id === a.id) !== undefined
        )
        .map((a) => a.id),
      isNewDriver: model.isNewDriver,
    };
    await ApsServices.http.userCredSetting
      .save(props.userId, data)
      .then((data) => {
        setModel(data);
        setOrigModel(JSON.parse(JSON.stringify(data)));
        const assignees = [...data.assignees].filter((a) => a.isSelected);
        setSelectedAssignees(assignees);
        setOrigSelectedAssignees(JSON.parse(JSON.stringify(assignees)));
        toastStore.showToast(
          "User Credential Settings saved successfully",
          "success"
        );
      })
      .catch((err) => {
        toastStore.showError(
          "Error saving User Credential Settings",
          err || ""
        );
      });
  };

  const updateSupervisorStatus = (val: string) => {
    const isSupervisor = val === "yes";
    setModel({ ...model, isSupervisor });
    setSelectedAssignees(isSupervisor ? selectedAssignees : []);
  };

  const updateDriverStatus = (val: string) => {
    setModel({ ...model, isNewDriver: val === "yes" });
  };

  return (
    <div className="flex flex-1 flex-col">
      <div className="container-fluid flex-card-container">
        <div className="flex-0">
          <div className="headerControls">
            <div>
              <span className="pointer" onClick={() => setCollapse(!collapse)}>
                <i
                  className={`fa fa-chevron-${
                    collapse ? "up" : "down"
                  } pl-2 pr-2`}
                ></i>
                <h4 className="pt-3 pl-2 m-0 inline-block">
                  Assignment{" "}
                  <span
                    style={{ color: "red" }}
                    className={hasChanges() ? "" : "d-none"}
                  >
                    *
                  </span>
                </h4>
              </span>
            </div>
            <div></div>
          </div>
        </div>
        <ConfirmDialog
          show={deleteAll}
          buttons="yesno"
          title="Confirmation"
          message="Do you reall want to delete all employees selected?"
          done={(rtn) => {
            if (rtn === "yes") {
              //Only Remove Active ones from Assignees
              setSelectedAssignees(prev => {
                return prev.filter(x => !allActiveUsers.find(y => y.id === x.id))
              });
            }
            setDeleteAll(false);
          }}
        />
        {loading ? (
          <CommonSpinner></CommonSpinner>
        ) : (
          <>
            <div
              className={`doc-designer w-100 pb-4 ${
                collapse ? "display-none" : ""
              }`}
            >
              <div className="row">
                <div className="col-xl-4">
                  <div className="card">
                    <div className="card-body">
                      <span>Personnel ID</span>
                      <div className="mt-1"></div>
                      <input
                        type="text"
                        name="personnelId"
                        placeholder="First Name"
                        className={`col-12 border rounded-10 h-8`}
                        value={model.personnelId || ""}
                        onChange={(e) =>
                          setModel({ ...model, personnelId: e.target.value })
                        }
                        required
                      />
                      <div className="mt-3"></div>
                      <span>Your rank</span>
                      <div className="mt-1"></div>
                      <FgSelect
                        id="rank"
                        label=""
                        selectMessage="Select Rank"
                        selectMessageValue="-1"
                        value={
                          model?.ranks?.find((dto) => dto.isSelected == true)
                            ?.id || -1
                        }
                        options={
                          model?.ranks?.map((dto) => {
                            return {
                              label: dto.title,
                              value: dto.id,
                            };
                          }) || []
                        }
                        onChange={(data) => {
                          const idx = model?.ranks.findIndex(
                            (dto) => dto.id === Number(data)
                          );
                          const ranks =
                            model?.ranks.map((dto, i) => {
                              return {
                                ...dto,
                                isSelected: i === idx,
                              };
                            }) || [];
                          setModel({ ...model, ranks });
                        }}
                      />
                      <div className="mt-3"></div>
                      <span>Your current supervisor(s)</span>
                      <div className="mt-1"></div>
                      <CheckboxList
                        id="assignedSupervisor"
                        data={
                          model?.supervisors
                            ?.filter(
                              // filter out any supervisor who are selected assignees
                              (x) =>
                                selectedAssignees.find((a) => a.id === x.id) ===
                                undefined
                            )
                            .map((dto) => {
                              return {
                                label: dto.name,
                                value: dto.isSelected,
                                id: dto.id,
                              };
                            }) || []
                        }
                        onChange={(data) => {
                          const supervisors = [...model.supervisors];
                          data.forEach((_, i) => {
                            supervisors[i].isSelected = data[i].value;
                          });
                          setModel({
                            ...model,
                            supervisors,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="card">
                    <div className="card-body">
                      <div>
                        <span>
                          Do you currently supervise anyone? (To view dashboard)
                        </span>
                      </div>
                      <div className="mt-1"></div>
                      <div
                        className="form-check form-check-inline"
                        style={{ width: "120px" }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="supervisorOptions"
                          id="supervisorYes"
                          value="yes"
                          checked={model.isSupervisor}
                          onChange={(e) =>
                            updateSupervisorStatus(e.target.value)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="supervisorYes"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="supervisorOptions"
                          id="supervisorNo"
                          value="no"
                          checked={!model.isSupervisor}
                          onChange={(e) =>
                            updateSupervisorStatus(e.target.value)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="supervisorNo"
                        >
                          No
                        </label>
                      </div>
                      <div className={`${!model.isSupervisor ? "d-none" : ""}`}>
                        <div className="mt-3"></div>
                        <span>
                          Select team members you currently supervise
                          (autocomplete)
                        </span>
                        <div className="mt-1"></div>

                        <FgSelect
                          id="_rank"
                          label=""
                          noPlaceholder={true}
                          groups={[
                            { id: 0, label: "By Employee" },
                            { id: -1, label: "By Group" },
                          ]}
                          options={[
                            {
                              label: "Employees",
                              value: 0,
                              groupId: 0,
                            },
                            ...(groups.data || []).map((cat) => {
                              return {
                                label: cat.name,
                                value: cat.groupId,
                                groupId: -1,
                              };
                            }),
                          ]}
                          disabledOptionValues={[-1]}
                          onChange={(data) => {
                            setSelectedGroup(commonService.getNumber(data));
                          }}
                        />

                        <div className="text-primary mt-1 px-1 flex">
                          <div className="flex-1">
                            <span
                              className="pointer"
                              onClick={(e) => {
                                const emps = (getEmployees.data || [])
                                  .filter(
                                    (x) =>
                                      !model.supervisors.find((y) => x.id === y.id)?.isSelected &&
                                      !selectedAssignees.find((i) => i.id === x.id) &&
                                      x.id !== props.userId
                                  )
                                  .map((i) => {
                                    return {
                                      id: i.id,
                                      name: `${i.lastName}, ${i.firstName}`,
                                    };
                                  });
                                setSelectedAssignees((list) => {
                                  return [...list, ...emps.map(x => {
                                    return {
                                      id: x.id, name: x.name, isSelected: true
                                    }
                                  })];
                                });
                                if (emps.length > 0) {
                                  toastStore.showToast(
                                    emps.length === 1
                                      ? "1 Employee Added"
                                      : `${emps.length} Employees Added`,
                                    "success"
                                  );
                                } else {
                                  toastStore.showToast(
                                    "No Employee(s) Added",
                                    "warning"
                                  );
                                }
                              }}
                            >
                              {selectedGroup
                                ? `Add All ${
                                    (groups?.data || []).find(
                                      (x) => x.groupId === selectedGroup
                                    )?.name || ""
                                  }`
                                : "Add All Employees"}
                            </span>
                          </div>
                          <div>
                            {selectedAssignees.filter(x => allActiveUsers.find(y => y.id === x.id)).length > 0 && (
                              <span
                                className="text-danger pointer"
                                onClick={(e) => {
                                  setDeleteAll(true);
                                }}
                              >
                                Delete All
                              </span>
                            )}
                          </div>
                        </div>

                        <AsyncTypeahead
                          id="basic-typeahead-single"
                          labelKey={(e: IUserCredSettingsUserOutputDto) =>
                            e.name
                          }
                          onSearch={(q: string) => {}}
                          open={openEmpDropdown}
                          onFocus={(e) => setOpenEmpDropdown(true)}
                          onBlur={(e) => setOpenEmpDropdown(false)}
                          onChange={(data) => {
                            if (data && data.length > 0) {
                              const candidate = data[0];
                              const notInAssignees =
                                selectedAssignees.find(
                                  (x) => x.id === candidate.id
                                ) === undefined;
                              const notSelectedSupervisor =
                                !model.supervisors.find(
                                  (x) => x.id === data[0].id
                                )?.isSelected;
                              if (notInAssignees && notSelectedSupervisor) {
                                const newAssignees = [...selectedAssignees];
                                newAssignees.push({ ...candidate });
                                setSelectedAssignees(newAssignees);
                              }
                              (ref.current as any)?.clear();
                              ref.current?.blur();
                            }
                          }}
                          searchText={"Searching..."}
                          isLoading={isLoading || getEmployees.status === NfirsFetchStatus.InProgress}
                          options={model.assignees?.filter(
                            (x) =>
                              !model.supervisors.find((y) => x.id === y.id)
                                ?.isSelected &&
                              !!getEmployees?.data?.find((y) => y.id === x.id) &&
                              x.id !== props.userId
                          )}
                          placeholder={
                            model.isSupervisor
                              ? "Search Employee"
                              : "Must be a supervisor to search"
                          }
                          minLength={1}
                          delay={500}
                          useCache={false}
                          ref={ref}
                          disabled={!model.isSupervisor}
                        />
                        <div className="currentParticipants mt-3 mb-3">
                          {selectedAssignees
                            ?.filter(
                              (x) => !!allActiveUsers.find((y) => y.id === x.id)
                            )
                            ?.map((p, i) => {
                              return (
                                <div key={i}>
                                  <section>{`${p.name}`}</section>
                                  <section
                                    title="Remove"
                                    onClick={() => {
                                      const assignees = [...selectedAssignees];
                                      assignees.splice(i, 1);
                                      setSelectedAssignees(assignees);
                                    }}
                                  >
                                    <i className="fa fa-times"></i>
                                  </section>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="card">
                    <div className="card-body">
                      <div>
                        <span>Are you a new driver?</span>
                      </div>
                      <div className="mt-1"></div>
                      <div
                        className="form-check form-check-inline"
                        style={{ width: "120px" }}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="driverOptions"
                          id="driverYes"
                          value="yes"
                          checked={model.isNewDriver}
                          onChange={(e) => updateDriverStatus(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor="driverYes">
                          Yes
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="driverOptions"
                          id="driverNo"
                          value="no"
                          checked={!model.isNewDriver}
                          onChange={(e) => updateDriverStatus(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor="driverNo">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-0">
                <div className="headerControls">
                  <div></div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-danger"
                      disabled={!hasChanges()}
                      onClick={() => reset()}
                    >
                      Reset
                    </button>
                    <button onClick={handleSubmit} className="btn btn-primary">
                      <span className="font-size-12">Save Section</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserCredentialSettings;
