import { FilterOrderPaginateParameters } from '../data/FilterOrderPaginateParameters';
import { PaginatedResult } from '../data/PaginatedResult';
import { GroupSummaryDto } from '../data/Group';
import { GroupDto } from '../data/Group';
import fetchHelper from '../aps2/services/http/FetchHelper';
import ApsServices from '../aps2/services';

export type groupService = {
    userId: number, 
    firstName: string, 
    lastName: string
}
class GroupService {
    getAllForFilter = async (): Promise<string[]> => {
        const response = await fetch('/Api/Group/AllForFilter');
        const value = await response.json() as string[];
        return value;
    }

    getSummary = async (params: FilterOrderPaginateParameters): Promise<PaginatedResult<GroupSummaryDto>> => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                ...ApsServices.http.fetcher.getHeaders() as any
            },
            body: JSON.stringify(params),
        };

        const response = await fetch('/Api/Group/Summary', options);
        const value = await response.json() as PaginatedResult<GroupSummaryDto>;
        return value;
    }

    getGroup = async (groupId: number): Promise<GroupDto> => {
        const options: RequestInit = {
            method: 'GET',
            headers: {
                ...ApsServices.http.fetcher.getHeaders() as any
            },
        };
        const response = await fetch(`/Api/Group/Group/${groupId}`, options);
        const value = await response.json() as GroupDto;
        return value;
    }

    getAllGroups = async (): Promise<GroupDto[]> => {
        const options: RequestInit = {
            headers: {
                ...ApsServices.http.fetcher.getHeaders() as any
            },
        };
        return await fetchHelper.fetchUseExisting<GroupDto[]>('/Api/Group/Groups', options);
    }

    save = async (groupDto: GroupDto): Promise<Number> => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                ...ApsServices.http.fetcher.getHeaders() as any
            },
            body: JSON.stringify(groupDto),
        };

        const response = await fetch('/Api/Group/Save/', options);
        return response.status;
    }
}

const groupService = new GroupService();
export { groupService as GroupService };
