import { IUserDepartmentRelationDto } from '../aps2/models/_all';
import fetchHelper from '../aps2/services/http/FetchHelper';
import { Observable } from '../common/Observable';
import { FilterOrderPaginateParameters } from '../data/FilterOrderPaginateParameters';
import { PaginatedResult } from '../data/PaginatedResult';
import { UserSummaryDto, UserDto, UserProfileDto, RolesDto, UserRolesPermissionDto, ModulePermissionDto } from '../data/User';

class UserService {
    public readonly currentDepartment = new Observable<number>(1);
    public readonly currentDepartmentName = new Observable<string | undefined>("");

    getHeaders = () => {
        return {
            'Content-Type': 'application/json',
            'x-departmentid': this.currentDepartment as any,
        }
    }

    getSummary = async (params: FilterOrderPaginateParameters): Promise<PaginatedResult<UserSummaryDto>> => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        };

        const response = await fetch('/Api/User/Summary', options);
        const value = await response.json() as PaginatedResult<UserSummaryDto>;
        return value;
    }

    getGroupUsersSummary = async (params: FilterOrderPaginateParameters, groupId: number): Promise<PaginatedResult<UserSummaryDto>> => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                ...this.getHeaders()
            },
            body: JSON.stringify({params}),
        };

        const response = await fetch(`/Api/User/GroupUsersSummary/${groupId}`, options);
        if (response.status == 404) {
            throw new Error(await response.json() as string);
        }
        const value = await response.json() as PaginatedResult<UserSummaryDto>;
        return value;
    }

    getUser = async (userId: number): Promise<UserDto> => {
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const response = await fetch(`/Api/User/GetUserById/${userId}`, options);
        const value = await response.json() as UserDto;
        return value;
    }

    getUserProfile = async (): Promise<UserProfileDto> => {
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const response = await fetch(`/Api/User/GetUserProfile`, options);
        const value = await response.json() as UserProfileDto;
        return value;
    }

    saveUser = async (user: UserDto): Promise<any> => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user),
        };

        const response = await fetch("/Api/User/SaveUser", options);

        if (response.ok) {
            //window.location.replace("/Admin/Users");
            return response.json();
        } else {
            throw new Error(await response.text() as string)
        }
    }

    updateUserProfile = async (userProfile: UserProfileDto): Promise<void> => {
        const options: RequestInit = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userProfile),
            };
            const response = await fetch("/Api/User/UpdateUserProfile", options);
            if (response.ok) {
            } else {
                throw new Error(await response.json() as string)
            }       
    }

    sendPasswordUpdateConfirmation = async (userId: number): Promise<void> => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const response = await fetch(`/Api/User/sendPasswordUpdateConfirmation/${userId}`, options);
    }

    getRoles = async (): Promise<RolesDto[]> => {
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const response = await fetch('/Api/User/GetRoles', options);
        const value = await response.json() as RolesDto[];
        return value;
    }

    getRolesByUser = async (userId: number): Promise<RolesDto[]> => {
        const response = await fetch(`/Api/User/GetRolesByUserId/${userId}`);
        const value = await response.json() as RolesDto[];
        return value;
    }

    getModulesPermissionByUser = async (userId: number): Promise<UserRolesPermissionDto[]> => {
        const response = await fetch(`/Api/User/${userId}/ModulesPermission`);
        const value = await response.json() as UserRolesPermissionDto[];
        return value;
    }

    saveModulePermission = async (modulePermission: ModulePermissionDto): Promise<void> => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(modulePermission),
        };
        await fetch(`/Api/User/SaveModulePermission`, options);
    }

    getUsersInGroupByDepartmentId = async (groupId: number, departmentId: number): Promise<UserDto[]> => {
        const response = await fetch(`/Api/User/${groupId}/UsersInGroup/${departmentId}`);
        return await response.json() as UserDto[];
    }

    getUsersInMultipleGroupsByDepartmentId = async (groupIds: number[], departmentId: number): Promise<UserDto[]> => {
        const options = groupIds.map(id => `groupIds=${id}`).join('&');
        const response = await fetch(`/Api/User/UsersInGroups?${options}&departmentId=${departmentId}`);
        return await response.json() as UserDto[];
    }

    getGroups = async (userId: number): Promise<number[]> => {
        const response = await fetch(`/Api/User/${userId}/Groups`);
        return await response.json() as number[];
    }

    saveGroups = async (userId: number, groupIds: number[]): Promise<void> => {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(groupIds),
        };

        await fetch(`/Api/User/${userId}/Groups`, options);
    }

    getUserDeptRelations = async (userId: number): Promise<IUserDepartmentRelationDto[]> => {
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };

        const response = await fetch(`/Api/User/${userId}/DeptRelations`, options);
        return await response.json() as IUserDepartmentRelationDto[];
    }

    getCurrUserDeptRelations = async (): Promise<IUserDepartmentRelationDto[]> => {
        const options: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };

        const response = await fetch(`/Api/User/DeptRelations`, options);
        return await response.json() as IUserDepartmentRelationDto[];
    }
}

const userService = new UserService();
export { userService as UserService };
