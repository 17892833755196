import React from "react";
import ApsModels from "../../models";

/** Props is structured to cater for AG-Grid Cell Renderer Component */
function CredentialStatusCellLabel(props: {
  data: {
    status: ApsModels.CredStatusEnum;
  };
  labelOnly?: boolean;
}) {
  const status = props.data.status;
  let className = "alert-secondary";
  let label = "Unknown";
  if (status === ApsModels.CredStatusEnum.Active) {
    className = "alert-success";
    label = "Active";
  } else if (status === ApsModels.CredStatusEnum.InProgress) {
    className = "alert-warning";
    label = "In Progress";
  } else if (status === ApsModels.CredStatusEnum.Expired) {
    className = "alert-danger";
    label = "Expired";
  } else if (status === ApsModels.CredStatusEnum.Inactive) {
    className = "alert-dark";
    label = "Inactive";
  }

  if (props.labelOnly) {
    return <span>{label === "Unknown" ? "" : label}</span>;
  }

  return (
    <span
      style={{
        padding: "0 5px",
      }}
      className={`alert ${className}`}
    >
      <small>{label}</small>
    </span>
  );
}

export default CredentialStatusCellLabel;
