import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import FileSaver from "file-saver";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { GroupService } from "../../../services/GroupService";
import ApsModels from "../../models";
import ApsServices from "../../services";
import commonService from "../../services/CommonService";
import toastStore from "../../stores/ToastStore";
import Pagination, { usePaging } from "../Common/Pagination";
import { useNfirsGetData } from "../NFIRS/NFIRSHelper";
import CredentialImportDialog from "./CredentialImportDialog";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import CredentialStatusCellLabel from "./CredentialStatusCellLabel";
import CredentialExpirationDateCell from "./CredentialExpirationDateCell";
import { useDepartmentId } from "../../stores/SystemStore";
import { GetCredStatusEnumStatusLabel } from "./CredentialList";

function CredentialListByEmployee(props: RouteComponentProps<any>) {
  const id = props.match?.params?.id;
  const departmentId = useDepartmentId();

  const statuses = useNfirsGetData(
    ApsServices.http.credCommon.credentialStatuses,
    "Statuses"
  );

  useEffect(() => {
    statuses.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [paging, setPaging] = usePaging(1, 100);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const [filters, setFilters] =
    useState<ApsModels.ICredCredentialByEmployeeSearchInputDto>({
      userId: null as any,
      credStatusEnum: null as any,
    });

  const [list, setList] =
    useState<ApsModels.ICredCredentialByEmployeeGridOutputDto>();
  const [gridApi, setGridApi] = useState<GridApi>(null as any);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>(null as any);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    toggleColSizes();
  };

  const toggleColSizes = () => {
    if (gridApi && gridColumnApi && list) {
      setTimeout(() => {
        let allColumnIds: any[] = [];
        gridColumnApi.getAllColumns()?.forEach(function (column: any) {
          allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, false);
        gridApi.sizeColumnsToFit();
      }, 500);
    }
  };

  const ViewButtonCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialByEmployeeGridItemOutputDto;
  }) => {
    return (
      <button
        type="button"
        className="btn btn-sm btn-outlined"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.history.push(`/credentials/employee/${rowProps.data.userId}`);
        }}
      >
        <FontAwesomeIcon icon={faEye} />
      </button>
    );
  };

  const NameCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialByEmployeeGridItemOutputDto;
  }) => {
    return (
      <span
        className="txt-primary pointer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.history.push(`/credentials/employee/${rowProps.data.userId}`);
        }}
      >
        {rowProps.data.employeeName}
      </span>
    );
  };

  const ExpirationCellComponent = (rowProps: {
    data: ApsModels.ICredCredentialByEmployeeGridItemOutputDto;
  }) => {
    if (!rowProps.data.dateOfExpiration) {
      return <span></span>;
    }
    return (
      <CredentialExpirationDateCell
        date={rowProps.data.dateOfExpiration}
      ></CredentialExpirationDateCell>
    );
  };

  const [gridState, setGridState] = useState({
    columnDefs: [
      {
        headerCheckboxSelection: true,
        checkboxSelection: (params: any) => {
          return true;
        },
        field: "_selected",
        headerName: "",
        width: 10,
        resizable: false,
      },
      {
        field: "employeeName",
        headerName: "Employee Name",
        cellRenderer: "nameCellComponent",
        minWidth: 250,
        suppressSizeToFit: false,
      },
      {
        field: "userId",
        headerName: "ID",
      },
      {
        field: "statusName",
        headerName: "Earliest Status",
        cellRenderer: "statusCellComponent",
        width: 100,
        suppressSizeToFit: true,
      },
      {
        field: "numActiveCredentials",
        headerName: "Active Credentials",
      },
      {
        field: "numExpiredCredentials",
        headerName: "Expired Credentials",
      },
      {
        field: "expirationDateString",
        headerName: "Earliest Expiration Date",
        cellRenderer: "expirationCellComponent",
        width: 100,
        suppressSizeToFit: true,
      },
      {
        field: "lastModifiedString",
        headerName: "Last Modified",
        valueFormatter: (param: {
          data: ApsModels.ICredCredentialByEmployeeGridItemOutputDto;
        }) => {
          return param.data.lastModified
            ? `${moment(param.data.lastModified).format("MMM DD, YYYY")} ${
                param.data.lastModifiedBy
                  ? `by ${param.data.lastModifiedBy}`
                  : ""
              }`
            : "";
        },
      },
      {
        field: "action",
        headerName: "Action",
        cellRenderer: "viewButtonCellComponent",
        width: 100,
        suppressSizeToFit: true,
      },
    ],
    defaultColDef: {
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
      suppressSizeToFit: true,
    },
    frameworkComponents: {
      viewButtonCellComponent: ViewButtonCellComponent,
      statusCellComponent: CredentialStatusCellLabel,
      expirationCellComponent: ExpirationCellComponent,
      nameCellComponent: NameCellComponent,
    },
    autoGroupColumnDef: { minWidth: 200 },
    rowData: [] as ApsModels.ICredCredentialByEmployeeGridItemOutputDto[],
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Loading</span>',
    overlayNoRowsTemplate:
      '<span class="ag-overlay-loading-center">No Records Found</span>',
  });

  const [loading, setLoading] = useState(false);
  const [member, setMember] = useState<{
    name: string;
    id: number;
  }>();

  const getList = async () => {
    gridApi && gridApi.showLoadingOverlay();

    setLoading(true);
    await ApsServices.http.credCredential
      .listCredentialsByEmployee(paging.page, paging.pageSize, filters)
      .then((data) => {
        setList(data as any);
        if (data.totalRecords === 0) {
          gridApi && gridApi.showNoRowsOverlay();
        } else {
          gridApi && gridApi.hideOverlay();
        }
        setLoading(false);
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Credentials", err);
        gridApi && gridApi.hideOverlay();
        setLoading(false);

        if (commonService.getErrorStatusCode(err) === 404) {
          props.history.push("/credentials");
        }
      });
  };

  const [rowsSelectedCount, setRowSelectedCount] = useState(0);

  useEffect(() => {
    setGridState({
      ...gridState,
      rowData: list?.credCredentialByEmployeeGridItemOutputDtos || [],
    });
    toggleColSizes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (gridApi) {
      if (departmentId.current > 0) {
        if (departmentId.changed && departmentId.previous > 0) {
          props.history.push("/credentials");
          return; //exit
        }
        getList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi, filters, paging, departmentId]);

  const getSelectedRows = () => {
    if (!gridApi) {
      return [];
    }

    const filteredRows: any[] = [];
    gridApi.forEachNodeAfterFilter((node) => {
      filteredRows.push(node);
    });
    const isAnyFilterPresent = gridApi.isAnyFilterPresent();

    return gridApi.getSelectedRows().filter((row) => {
      return (
        !isAnyFilterPresent ||
        !!filteredRows.find((r: any) => r.data.userId === row.userId)
      );
    });
  };

  const groups = useNfirsGetData(GroupService.getAllGroups, "");
  const [claims] = useState(commonService.friendlyClaims);
  const [users, setUsers] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const ref = React.createRef<AsyncTypeahead<any>>();

  const handleSearch = async (query: string) => {
    if ((query || "").trim() === "") {
      setUsers([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    await ApsServices.http.credCommon
      .credentialsTypeAheadUser({
        search: query,
        isAnd: false,
        recordCount: 10,
        shiftId: null as any,
        rankId: null as any,
      })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: `${i.firstName} ${i.lastName}`,
          firstName: i.firstName,
          lastName: i.lastName,
        }));

        setUsers(options);
        setIsLoading(false);
      })
      .catch((err) => {
        setUsers([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (claims.credentials.canViewAll) {
      groups.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showImport, setShowImport] = useState(false);
  const [exporting, setExporting] = useState(false);
  const startExport = async () => {
    setExporting(true);
    await ApsServices.http.credCredential
      .extractCredentialsByEmployee({
        ids: getSelectedRows().map((r) => r.userId),
      })
      .then((data) => {
        const file = commonService.b64toBlob(data.fileContents, "text/plain");
        FileSaver.saveAs(file, data.fileDownloadName);
      })
      .catch((error) => {
        toastStore.showError("Failed Exporting Credentials", error);
      })
      .finally(() => {
        setExporting(false);
      });
  };

  return (
    <>
      {showImport && (
        <CredentialImportDialog
          onClose={(e) => {
            setShowImport(false);
          }}
        ></CredentialImportDialog>
      )}
      <div className="flex flex-col h-100 p-4">
        <div
          className="flex flex-row mb-3"
          style={{ flexWrap: "wrap", gap: "10px" }}
        >
          <div
            className="flex-1 flex"
            style={{
              flexWrap: "wrap",
              gap: "10px",
              alignItems: "center",
              minWidth: "250px",
            }}
          >
            <div className="mr-4">
              {!claims.credentials.canAddAll && (
                <button
                  className="btn btn-outline-secondary"
                  onClick={(e) => {
                    startExport();
                  }}
                  disabled={rowsSelectedCount === 0 || exporting}
                >
                  {exporting
                    ? `Exporting...`
                    : `Export to CSV (${rowsSelectedCount})`}
                </button>
              )}
              {claims.credentials.canAddAll && (
                <Dropdown drop="down">
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    id="dropdownTeamsFilter"
                    disabled={loading}
                  >
                    {exporting ? "Exporting..." : "Export or Import"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={(e) => {
                        startExport();
                      }}
                      disabled={rowsSelectedCount === 0 || exporting}
                    >
                      Export to CSV ({rowsSelectedCount})
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {
                        setShowImport(true);
                      }}
                    >
                      Import CSV
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>

            {claims.credentials.canViewAll && (
              <>
                <div>
                  <strong>Filter</strong>
                </div>
                <Dropdown drop="down">
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    id="dropdownStatusesFilter"
                    disabled={loading}
                  >
                    {statuses.data?.find(
                      (s) => s.value === filters.credStatusEnum
                    )?.label || "All Statuses"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={(e) => {
                        setFilters((f) => {
                          return {
                            ...f,
                            credStatusEnum: null as any,
                          };
                        });
                      }}
                    >
                      All Statuses
                    </Dropdown.Item>
                    {statuses.data?.map((s) => (
                      <Dropdown.Item
                        key={s.value}
                        onClick={(e) => {
                          setFilters((f) => {
                            return {
                              ...f,
                              credStatusEnum: s.value,
                            };
                          });
                        }}
                      >
                        {s.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <div style={{ minWidth: "200px" }}>
                  <AsyncTypeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    onSearch={handleSearch}
                    onChange={(data) => {
                      if (data && data.length > 0) {
                        setMember({
                          id: data[0].id,
                          name: data[0].name,
                        });
                        setFilters((f) => {
                          return {
                            ...f,
                            userId: data[0].id,
                          };
                        });
                        setUsers(data);
                        (ref.current as any)?.clear();
                      }
                    }}
                    searchText={"Searching..."}
                    isLoading={isLoading}
                    options={users}
                    placeholder="Search Member"
                    minLength={1}
                    delay={500}
                    useCache={false}
                    ref={ref}
                  />
                </div>
                {!!member && (
                  <div className="alert alert-secondary m-0 p-2">
                    {member?.name}{" "}
                    <i
                      className="fa fa-times ml-2 pointer"
                      onClick={() => {
                        setMember(undefined);
                        setFilters((f) => {
                          return {
                            ...f,
                            userId: null as any,
                          };
                        });
                      }}
                    ></i>
                  </div>
                )}
                {/* 
                <Dropdown drop="down">
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    id="dropdownGroupsFilter"
                    disabled={loading}
                  >
                    {groups?.data?.find((g) => g.groupId === filters.groupId)
                      ?.name || "All Groups"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={(e) => {
                        setFilters({
                          groupId: null as any,
                        });
                      }}
                    >
                      All Groups
                    </Dropdown.Item>
                    {groups?.data?.map((grp) => (
                      <Dropdown.Item
                        key={grp.groupId}
                        onClick={(e) => {
                          setFilters({
                            groupId: grp.groupId,
                          });
                        }}
                      >
                        {grp.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown> */}
              </>
            )}
          </div>
          <div>
            {!id && (
              <Button
                variant="primary"
                type="button"
                onClick={(e) => {
                  //setShowAddCred(true);
                  props.history.push(`/credentials/new`);
                }}
              >
                Add Credential
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: "100%", height: "100%" }}>
          <div
            id="myGrid"
            style={{
              height: "100%",
              minHeight: "300px",
            }}
            className="ag-theme-alpine flex-1"
          >
            <AgGridReact
              columnDefs={gridState.columnDefs}
              defaultColDef={gridState.defaultColDef}
              autoGroupColumnDef={gridState.autoGroupColumnDef}
              enableRangeSelection={true}
              animateRows={true}
              onGridReady={onGridReady}
              rowSelection={"multiple"}
              rowMultiSelectWithClick={false}
              suppressRowDeselection={true}
              suppressRowClickSelection={true}
              frameworkComponents={gridState.frameworkComponents}
              rowData={(gridState.rowData || []).map(
                (row: ApsModels.ICredCredentialByEmployeeGridItemOutputDto) => {
                  return {
                    ...row,
                    statusName: GetCredStatusEnumStatusLabel(row.status),
                    expirationDateString: row.dateOfExpiration
                      ? moment(row.dateOfExpiration).format("YYYY-MM-DD")
                      : "Not Applicable",
                    lastModifiedString: row.lastModified
                      ? moment(row.lastModified).format("YYYY-MM-DD")
                      : "",
                  };
                }
              )}
              onRowDoubleClicked={(event: {
                data: ApsModels.ICredCredentialByEmployeeGridItemOutputDto;
              }) => {
                props.history.push(
                  `/credentials/employee/${event.data.userId}`
                );
              }}
              overlayLoadingTemplate={gridState.overlayLoadingTemplate}
              overlayNoRowsTemplate={gridState.overlayNoRowsTemplate}
              onRowSelected={() => {
                setRowSelectedCount(getSelectedRows().length);
              }}
              onRowDataChanged={() => {
                setRowSelectedCount(getSelectedRows().length);
              }}
              onFilterChanged={() => {
                setRowSelectedCount(getSelectedRows().length);
              }}
            />
          </div>
        </div>

        {
          <Pagination
            length={list?.totalRecords || 0}
            page={paging.page}
            pageSize={paging.pageSize}
            pageChange={pageChange}
            showingOfWhatLabel="Records"
            sizes={[10, 20, 50, 100]}
          ></Pagination>
        }
      </div>
    </>
  );
}

export default CredentialListByEmployee;
